function OMSActionButtonUrlDisabled(props) {
  return (
    <div className="omsactionbutton-disabled">
      <span className={"fa " + props.ui_icon + " fa-5x"}></span>
      <br />
      <label>{props.ui_header}</label>
      <p>{props.ui_description}</p>
    </div>
  );
}

export default OMSActionButtonUrlDisabled;
