import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { DataTableComp, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import { withRouter } from 'react-router-dom';
import { ToPriceString } from "../../../app/common/common-functions/functions";

class OfficesDealGrid extends React.Component {

  loadReportsFromServer = () => {
    this.setState({ loading: true, data: [] });
    apiAgent.Admin.officeDealGridInfoAjax()
      .then((response) => {
        console.log(response);
        this.setState({
          data: response,
          hasTableShown: false,
          loading: false,
        });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  componentDidMount() {
    this.loadReportsFromServer();
  }

  state = {
    data: [],
    loading: false,
    hasTableShown: false,
  };

  
  render() {
    var columns = [
      // { data: "officeID", title: "Office ID" },
      { data: "officeName", title: "Office Name" },
      { data: "dealCount", title: "Deal Count" },
      { data: "dealValue", title: "Deal Value" },
    ];

    var columnDefs = [
      {
        render: function (data, type, row) {
          return ToPriceString(data);
        },
        targets: 2,
      },
    ];

    return (
      <div className="col-md-12">
        <div className="panel panel-default panel-table oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6">
                <div className="form-inline form-group">
                  <div className="element">
                    <div className="truncate">
                      <label>
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="table-responsive">
              <DataTableComp
                id="commissionslipTable"
                columns={columns}
                columnDefs={columnDefs}
                data={this.state.data}
                order={[[1, "asc"]]}
              />
            </div>
            <DataTableLoading loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OfficesDealGrid);
