import React, { useEffect, useState } from "react";
import apiAgent from "../../app/api/apiAgent";
import { SettingsModel } from "../../app/models/SettingsModel";
import { useStore } from "../../app/stores/store";
import OMSActionButtonUrl from "../OMSButton/OMSActionButtonUrl";
import OMSActionButtonUrlDisabled from "../OMSButton/OMSActionButtonUrlDisabled";
//import "../../assets/styles/components/_settings.scss";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";
import ConfirmationModalPopup from "../../app/common/common-components/ModalPopups/ConfirmationModalPopup";

function MySettingsPage() {

  const defaultConfirmationModalProps = () => {
    return {
      showModal: false,
      isConfirmed: (result : boolean) => { },
      title: "",
      body: "",
    };
  };

  const [setting, setSetting] = useState<SettingsModel>();
  const [isLoading, setIsLoading] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isExpiredLoading, setIsExpiredLoading] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps()
  );

  const { userStore } = useStore();
  const enabledModules: number[] = userStore!.userSession!.enabledModules!;
  useEffect(() => {
    setIsLoading(true);
    //console.log("inside Settings useEffect");
    apiAgent.Setting.getSettings()
      .then((response: SettingsModel) => {
        let oSetting: SettingsModel = response;
        setSetting(oSetting);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const reportClick = () => {
    setIsReportLoading(true)
    apiAgent.Admin.syncReport()
    .then((response) => {
      const csvContent = response;
      const blob = new Blob([csvContent], { type: "text/csv" });
      
      const defaultFileName = "syncReport.csv";
      
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = defaultFileName;
      
      // Trigger the download
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsReportLoading(false)
    })
    .catch((error) => {
      console.error("Failed to download file:", error);
      setIsReportLoading(false)
      });
  };

  const realtyExpiredClick = () => {
    console.log("realtyExpiredClick");
    var md = {
      showModal: true,
      isConfirmed: (result : boolean) => {
        console.log("result",result);

        if (result) {
          console.log("showModal");
          setConfirmationModalProps(defaultConfirmationModalProps());
          setIsExpiredLoading(true);
          apiAgent.Admin.perkYouRealtyExpiredAjax()
          .then((response) => {
            const csvContent = response;
            const blob = new Blob([csvContent], { type: "text/csv" });
            const defaultFileName = "perkYouRealtyExpired.csv";
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = defaultFileName;
      
            // Trigger the download
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setIsExpiredLoading(false)
          })
          .catch((error) => {
            console.error("Failed to download file:", error);
            setIsExpiredLoading(false)
          });
        } else {
          console.log("hideModal");
          setConfirmationModalProps(defaultConfirmationModalProps());
          setIsExpiredLoading(false);
        }
      },
      title: " You Realty expired listings",
      body:
        "Are you sure you want to run You Realty expired listings.",
    };
    setConfirmationModalProps(md);
  };

  if (isLoading) {
    return <AjaxLoader />;
  } else if (!isLoading && setting) {
    return (
      <div className="row is-flex">
        <div className="col-md-2 col-sm-4 col-xs-6 text-center">
          <OMSActionButtonUrl url="/Settings/Users" ui_icon="fa-user-o" ui_header="Users" ui_description="Add, rename and manage users" />
        </div>
        {setting!.settings?.indexOf("offices") !== -1 ? (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Settings/Offices" ui_icon="fa-building-o" ui_header="Offices" ui_description="Configure offices and settings" />
          </div>
        ) : (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrlDisabled ui_icon="fa-building-o" ui_header="Offices" ui_description="Configure offices and settings" />
          </div>
        )}
        {setting!.settings?.indexOf("groups") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Settings/Groups" ui_icon="fa-cloud" ui_header="Groups" ui_description="Configure groups and settings" />
          </div>
        )}
        {setting!.settings?.indexOf("countries") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Settings/Countries" ui_icon="fa-map-marker" ui_header="Countries" ui_description="Modify country settings" />
          </div>
        )}
        {setting!.settings?.indexOf("customize") != -1 ? (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Settings/Customize" ui_icon="fa-cog" ui_header="Customize" ui_description="Change the look and feel" />
          </div>
        ) : (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrlDisabled ui_icon="fa-cog" ui_header="Customize" ui_description="Change the look and feel" />
          </div>
        )}
        <div className="col-md-2 col-sm-4 col-xs-6 text-center">
          <OMSActionButtonUrl url="/Settings/AuditTrail" ui_icon="fa-clock-o" ui_header="Audit Trail" ui_description="View the system audit trail" />
        </div>
        {setting!.settings?.indexOf("integrations") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Settings/Integrations" ui_icon="fa-plug" ui_header="Integrations" ui_description="Portal and 3rd party integrations" />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Import/Index" ui_icon="fa-upload" ui_header="Import" ui_description="Import listing data" />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/BulkSync" ui_icon="fa-refresh" ui_header="Bulk Sync" ui_description="Bulk sync branch with portals" />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/ClearCache" ui_icon="fa-eraser" ui_header="Clear Cache" ui_description="Clear redis cache" />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/P24Match" ui_icon="fa-tag" ui_header="P24 Ref Mapping" ui_description="Map base ref with existing p24 ref" />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/P24AreaMap" ui_icon="fa-map-marker" ui_header="P24 Area Mapping" ui_description="Map base area with p24 area" />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/AreaTransfer" ui_icon="fa-exchange" ui_header="Area Transfer" ui_description="Transfer area of listings" />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl
              url="/Admin/UserTransfer"
              ui_icon="fa-users"
              ui_header="User Transfer"
              ui_description="Transfer user of listings(with in office)"
            />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/Reports" ui_icon="fa-file-text-o" ui_header="Reports" ui_description="Configure reports, brochures etc." />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/CommissionSlip" ui_icon="fa-bullseye" ui_header="Commission Slip" ui_description="Configure Commission Slips." />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/MapAreaTool" ui_icon="fa-map-marker" ui_header="Map Area" ui_description="Configure map area for flextl sites." />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/Credit" ui_icon="fa-credit-card" ui_header="Agent Msg Credit" ui_description="Add agent credit for messages." />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/SyncIssues" ui_icon="fa-bug" ui_header="Sync Issues" ui_description="Search all properties with sync issues" />
          </div>
        )}
        {/* {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Import/OMSToBase" ui_icon="fa-upload" ui_header="OMS To Base" ui_description="OMS to Base office transfer." />
          </div>
        )} */}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl
              url="/Admin/FlexPropertySyncD"
              ui_icon="fa-cogs"
              ui_header="Flex Property Removal"
              ui_description="Remove property from flexProperty and Cloud Search for Flex/MPSA/MPNA"
            />
          </div>
        )}

        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl
              url="/Admin/FlexAgentSyncD"
              ui_icon="fa-cogs"
              ui_header="Flex Agent Removal"
              ui_description="Remove property from flexAgent and Cloud Search for Flex/MPSA/MPNA"
            />
          </div>
        )}

        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center" onClick={reportClick}>
              <OMSActionButtonUrl progress={isReportLoading}  ui_icon="fa-file-text-o" ui_header="Monthly Report" ui_description="Get monthly sync report for biling" />
          </div>
        )}
        {setting!.settings?.indexOf("imports") != -1 && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center">
            <OMSActionButtonUrl url="/Admin/OfficeDealGrid" ui_icon="fa-bullseye" ui_header="Office Deal Usage" ui_description="Get office wise deal usage." />
          </div>
        )}
         {setting!.settings?.indexOf("imports") != -1 && userStore!.userSession?.userId == "7bdd0fcb14824b5cb9a8257a38a2cdc4" && (
          <div className="col-md-2 col-sm-4 col-xs-6 text-center" onClick={realtyExpiredClick}>
            <OMSActionButtonUrl url="/settings" ui_icon="fa-download" ui_header="You Realty expired listing" ui_description="Expire the listings on You Realty rentals older than 30 days." />
          </div>
        )}
         <ConfirmationModalPopup {...confirmationModalProps} />
      </div>
    );
  } else {
    return <></>;
  }
}

export default MySettingsPage;
