import React, { useState } from "react";
import { Field, Formik } from "formik";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { BrochureModel } from "../../../app/models/BrochureModel";
import { ConfirmationModalProps } from "../../../app/models/ModalControlProps/ConfirmationModalProps";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { useHistory } from 'react-router-dom';

interface Props {
  data?: BrochureModel;
  setConfirmationModalProps: (confirmationModalProps: ConfirmationModalProps) => void;
}

function BrochureBox(props: Props) {
  const [brochureID, setBrochureID] = useState(props.data!.brochureID);
  const [groupID, setGroupID] = useState(props.data!.groupID);
  const [officeID, setOfficeID] = useState(props.data!.officeID);
  const [isGeneric, setIsGeneric] = useState(props.data!.isGeneric);
  const [brochureName, setBrochureName] = useState(props.data!.brochureName);
  const [brochureS3Location, setBrochureS3Location] = useState(props.data!.brochureS3Location);
  const [brochurePreviewUrl, setBrochurePreviewUrl] = useState(props.data!.brochurePreviewUrl);
  const [format, setFormat] = useState(props.data!.format);
  const [width, setWidth] = useState(props.data!.width);
  const [height, setHeight] = useState(props.data!.height);
  const [exportType, setExportType] = useState(props.data!.exportType);
  const [fileType, setFileType] = useState(props.data!.fileType);
  const [isSnapshot, setIsSnapshot] = useState(props.data!.isSnapshot);
  const [buttonStatus, setButtonStatus] = useState({
    disabled: false,
    processing_save: false,
    processing_delete: false,
  });

  let history = useHistory();

  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>({
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  });

  const openCloseDeleteModal = (isOpen: boolean) => {
    let varConfirmationModalProps = confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const showPopupAndDeleteOnConfirmation = (title: string, body: string, callback: Function) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed: boolean) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const handleStatusChanged = (e: any) => {
    setIsGeneric(!isGeneric);
  };

  const handleIsSnapshotChanged = (e: any) => {
    setIsSnapshot(!isSnapshot);
  };

  const handleGroupIdChange = (e: any) => {
    setGroupID(e.newValue);
  };

  const handleOfficeIdChange = (e: any) => {
    setOfficeID(e.newValue);
  };

  const onCancelClick = (e: any) => {
    history.push("/Admin/Reports");
  };

  const executeDeleteClick = (e: any) => {
    setButtonStatus({
      disabled: true,
      processing_save: false,
      processing_delete: true,
    });

    apiAgent.Admin.deleteBrochureAjax(brochureID)
      .then((response) => {
        history.push("/Admin/Reports");
      })
      .catch((error) => {
        toast.info(error.message);
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  const onDeleteClick = (e: any) => {
    /* bootbox.confirm({
            title: "Delete report?",
            message: "Do you want to delete brochure now? This cannot be undone.",
            buttons: {
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancel'
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> Confirm'
                }
            },
            callback: function (result: any) {
                if (result) {
                    executeDeleteClick(result);
                }
            }
        }); */
    let title = "Delete brochure?";
    let body = "Do you want to delete brochure now? This cannot be undone.";
    let callback = executeDeleteClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  const doValidate = (values: BrochureModel) => {
    let { brochureName, brochureS3Location, format, width, height, isGeneric } = values;
    var failed = false;

    if (!brochureName.trim()) {
      toast.info("Please enter report/brochure name");
      failed = true;
    }

    if (!brochureS3Location.trim()) {
      toast.info("Please enter report/brochure s3 location");
      failed = true;
    }

    if (!format.trim()) {
      toast.info("Please enter report/brochure format");
      failed = true;
    }

    if (!width.trim()) {
      toast.info("Please enter report/brochure width");
      failed = true;
    }

    if (!height.trim()) {
      toast.info("Please enter report/brochure height");
      failed = true;
    }

    if (isGeneric) {
      if (officeID == "" && groupID == "") {
        toast.info("Office or group selection required for non generic brochure/report");
        failed = true;
      }
    }

    return !failed;
  };

  const handleSubmit = (values: BrochureModel) => {
    if (!doValidate(values)) return;

    setButtonStatus({
      disabled: true,
      processing_save: true,
      processing_delete: false,
    });

    values.officeID = officeID;
    values.groupID = groupID;
    values.isGeneric = isGeneric;
    values.isSnapshot = isSnapshot;

    apiAgent.Admin.saveBrochureAjax(values)
      .then((response) => {
        history.push("/Admin/Reports");
      })
      .catch((error) => {
        toast.info(error.message);
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  return (
    <Formik
      initialValues={{
        brochureID: props.data!.brochureID,
        groupID: props.data!.groupID,
        officeID: props.data!.officeID,
        isGeneric: props.data!.isGeneric,
        brochureName: props.data!.brochureName,
        brochureS3Location: props.data!.brochureS3Location,
        width: props.data!.width,
        height: props.data!.height,
        format: props.data!.format,
        exportType: props.data!.exportType,
        fileType: props.data!.fileType,
        brochurePreviewUrl: props.data!.brochurePreviewUrl,
        isSnapshot: props.data!.isSnapshot,
        officeDD: props.data!.officeDD,
        groupDD: props.data!.groupDD,
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, errors, values }) => (
        <form className="SaveReportForm" onSubmit={handleSubmit}>
          <ConfirmationModalPopup {...confirmationModalProps} />
          <div className="col-sm-12">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    <label>{brochureID != "" ? brochureName : "CREATE"}</label>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="BrochureName">
                    Brochure/report name
                  </label>
                  <div className="controls">
                    <Field type="text" name="brochureName" className="form-control required-control" placeholder="Brochure Name" />
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="BrochureS3Location">
                    Brochure/report s3 location(relative) <small style={{ fontWeight: "normal", fontSize: "70%" }}>ex. template/base/7photo.html</small>
                  </label>
                  <div className="controls">
                    <Field type="text" name="brochureS3Location" className="form-control required-control" placeholder="brochure S3 Location" />
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="BrochurePreviewUrl">
                    Brochure Preview Image URL
                  </label>
                  <div className="controls">
                    <Field type="text" name="brochurePreviewUrl" className="form-control required-control" placeholder="Brochure Preview Url" />
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="Format">
                    Format <small style={{ fontWeight: "normal", fontSize: "70%" }}>ex. a4, a5, a3 etc</small>
                  </label>
                  <div className="controls">
                    <Field type="text" name="format" className="form-control required-control" placeholder="Format" />
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="Width">
                    Width <small style={{ fontWeight: "normal", fontSize: "70%" }}>ex. 2480px</small>
                  </label>
                  <div className="controls">
                    <Field type="text" name="width" className="form-control required-control" placeholder="Width" />
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="Height">
                    Height <small style={{ fontWeight: "normal", fontSize: "70%" }}>ex. 3508px</small>
                  </label>
                  <div className="controls">
                    <Field type="text" name="height" className="form-control required-control" placeholder="Height" />
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="ExportType">
                    Export type
                  </label>
                  <div className="controls">
                    <Field as="select" name="exportType" className="form-control">
                      <option value="PDF">PDF</option>
                      <option value="PNG">PNG</option>
                    </Field>
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="FileType">
                    File type
                  </label>
                  <div className="controls">
                    <Field as="select" name="fileType" className="form-control">
                      <option value="0">HTML</option>
                      <option value="1">TELERIK REPORT</option>
                    </Field>
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="IsSnapshot">Is this Brochure using Snapshot API?</label>
                  <div className="controls">
                    <label className="switch">
                      <Field type="checkbox" name="isSnapshot" checked={isSnapshot} onChange={handleIsSnapshotChanged} />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="IsGeneric">IsGeneric (available for all)</label>
                  <div className="controls">
                    <label className="switch">
                      <Field type="checkbox" name="isGeneric" checked={isGeneric} onChange={handleStatusChanged} />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="form-group col-md-12" style={{ display: isGeneric ? "none" : "block" }}>
                  <label htmlFor="GroupID">Group</label>
                  <div className="controls">
                    <OMSDropdown
                      id="GroupID"
                      name="groupID"
                      options={props.data!.groupDD}
                      valueField="value"
                      labelField="text"
                      value={groupID}
                      onChange={handleGroupIdChange}
                    />
                  </div>
                </div>
                <div
                  className="form-group col-md-12"
                  style={{
                    display: isGeneric || groupID != "" ? "none" : "block",
                  }}
                >
                  <label htmlFor="OfficeID">Office</label>
                  <div className="controls">
                    <OMSDropdown
                      id="OfficeID"
                      name="officeID"
                      options={props.data!.officeDD}
                      valueField="value"
                      labelField="text"
                      value={officeID}
                      onChange={handleOfficeIdChange}
                    />
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pull-left">
                      {brochureID != "" ? (
                        <div className="dropup">
                          <button className="btn btn-outline dropdown-toggle" type="button" data-toggle="dropdown">
                            More..
                            <span className="caret" />
                          </button>
                          <ul className="dropdown-menu" style={{ width: "auto" }}>
                            <li>
                              {" "}
                              <OMSButtonStyleL
                                type="button"
                                ui_icon="fa-trash-o"
                                ui_type="delete"
                                ui_text="Delete brochure"
                                ui_processing_text="Deleting.."
                                processing={buttonStatus.processing_delete}
                                disabled={buttonStatus.disabled}
                                onClick={onDeleteClick}
                              />
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                    <div className="pull-right">
                      <OMSButtonStyleL
                        style={{ marginRight: 10 }}
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={buttonStatus.disabled}
                        onClick={onCancelClick}
                      />
                      <OMSButtonStyleL
                        type="submit"
                        ui_icon="fa-check"
                        ui_type="save"
                        ui_text="Save"
                        ui_processing_text="Saving.."
                        processing={buttonStatus.processing_save}
                        disabled={buttonStatus.disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default BrochureBox;
