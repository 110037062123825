import { useEffect, useState } from "react";
import DragContext from "./DragContext";
import apiAgent from "../../../app/api/apiAgent";
import PiplelineDashboardFilterModel from "../../../app/models/PiplelineDashboardFilterModel";

const DragState = (props) => {
  const [pipelineData, setPipelineData] = useState([]);
  const [isDataLoading, setisDataLoading] = useState(false);
  const defaultPipelineModel = new PiplelineDashboardFilterModel();
  const [filterData, setFilterData] = useState(defaultPipelineModel);

  useEffect(() => {

    console.log("useEffect >> filterData");
    console.log(filterData);

    if (filterData.WorkspacePipelineId !== "" && filterData.WorkspacePipelineId && filterData.WorkspacePipelineId !== null) {
      setisDataLoading(true)
      apiAgent.Deal.pipelinesInfoAjax(
        {
          "draw": 1,
          "columns": [

          ],
          "order": [

          ],
          "start": 0,
          "length": 10,
          "search": {
            "value": "",
            "regex": false
          }
        },
        filterData.AgentId,
        filterData.PipelineId,
        filterData.WorkspaceAgentId,
        filterData.WorkspacePipelineId,
        filterData.PropertyTypes,
        filterData.Areas,
        filterData.RegistrationStartFrom,
        filterData.RegistrationEndTo,
        filterData.NeedUpdate,
      ).then((res) => {
        setPipelineData(res.data);
        setisDataLoading(false)
      }).finally(() => {
        setisDataLoading(false)
      });
    }
  }, [filterData]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const draggedDeal = pipelineData.find((stage) => stage.stageId === source.droppableId).deals.find((deal) => deal.dealID === draggableId);

    const updatedData = [...pipelineData];
    const sourceIndex = updatedData.findIndex((stage) => stage.stageId === source.droppableId);
    const destinationIndex = updatedData.findIndex((stage) => stage.stageId === destination.droppableId);

    const sourceDealIndex = updatedData[sourceIndex].deals.findIndex((deal) => deal.dealID === draggableId);
    updatedData[sourceIndex].deals.splice(sourceDealIndex, 1);

    updatedData[destinationIndex].deals.splice(destination.index, 0, draggedDeal);

    setPipelineData(updatedData);
    apiAgent.Deal.dealStageChangeAjax(result.draggableId, destination.droppableId);
  };
  return (
    <DragContext.Provider
      value={{
        onDragEnd,
        pipelineData,
        isDataLoading,
        setFilterData
      }}
    >
      {props.children}
    </DragContext.Provider>
  );
};

export default DragState;
