import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import apiAgent from "../../../app/api/apiAgent";
import DealContactsStepView from "../dealComponents/stepComponents/DealContactsStepView";
import DealDocumentStepView from "../dealComponents/stepComponents/DealDocumentStepView";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { toast } from "react-toastify";
import DealOverviewStepView from "../dealComponents/stepComponents/DealOverviewStepView";
import "react-vertical-timeline-component/style.min.css";
import DealFinanceStepView from "../dealComponents/stepComponents/DealFinanceStepView";
import DealTaskStepView from "../dealComponents/stepComponents/DealTaskStepView";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { AuditTrailBox } from "../../contact/AuditTrailBox";
import { useStore } from "../../../app/stores/store";

function DealBox(props) {
  const refFinanceComponent = useRef();
  const refTaskComponent = useRef();
  const refOverviewComponent = useRef();

  const [isMobileWidth, setIsMobileWidth] = useState(window.innerWidth <= 460);
  const [accessType, setAccessType] = useState(null);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [newDealData, setNewDealData] = useState();
  const [dealModelData, setDealModelData] = useState({
    dealname: "",
    dealDate: "",
    dealTemplete: "",
    dealTempleteId: "",
    pipeline: "",
    pipelineId: "",
    mandateStatus: "0",
  });
  const [deductionTypesDD, setDeductionTypesDD] = useState([
    {
      text: "",
      value: "",
    },
  ]);
  const [splitTypesDD, setSplitTypesDD] = useState([
    {
      text: "",
      value: "",
    },
  ]);
  const [dealTEmpleteDD, setdealTEmpleteDD] = useState([]);
  const [dealPipelineDD, setDealPipelineDD] = useState([]);
  const [documentTaskDD, setdocumentTaskDD] = useState([]);
  const [contactDD, setContactDD] = useState([]);
  const [step, setStep] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [buttonStatus, setButtonStatus] = useState({
    disabled: false,
    processing_save: false,
    processing_delete: false,
    processing_history: false,
  });
  const [confirmationModalProps, setConfirmationModalProps] = useState({
    showModal: false,
    isConfirmed: (result) => {},
    title: "",
    body: "",
  });
  const [taskData, setTaskData] = useState([]);
  const [isShowAuditTrail, setIsShowAuditTrail] = useState(false);
  const [allAssignedTags, setallAssignedTags] = useState([]);
  const [taskIDParam, settaskIDParam] = useState("");

  const {
    userStore: { userSession },
  } = useStore();

  const history = useHistory();

  const onCancelClick = () => {
    history.push("/deal");
  };
  const onHandleDealNote = (note) => {
    props.data.dealNote = note;
  };

  // Input Value Handle
  const inputHandler = async (e, inpName) => {
    if (inpName === "dealName") {
      setDealModelData({ ...dealModelData, dealname: e.target.value });
    } else if (inpName === "dealDate") {
      setDealModelData({ ...dealModelData, dealDate: e.target.value });
    } else if (inpName === "dealTemplete") {
      setDealModelData({
        ...dealModelData,
        dealTemplete: e.newText,
        dealTempleteId: e.newValue,
      });
    } else if (inpName === "dealPipeline") {
      let templete = props.data.templatesDD.filter((curElem) => {
        return curElem.pipelineId === e.newValue;
      });
      templete.unshift({
        pipeline: "",
        template: "",
        pipelineId: "",
        templateId: "",
      });
      setDealModelData({
        pipeline: e.newText,
        pipelineId: e.newValue,
        dealTemplete: dealModelData.dealTempleteId,
        dealDate: dealModelData.dealDate,
        dealname: dealModelData.dealname,
      });
      setdealTEmpleteDD(templete);
    } else if (inpName === "mandateStatus") {
      setDealModelData({ ...dealModelData, mandateStatus: e.target.value });
    }
  };
  const onFileComponentChange = (files) => {
    setDocuments(files);
  };
  const onContactComponentChange = (contacts) => {
    setContacts(contacts);
    let newContcatDD = contacts
      ?.filter((curContact) => {
        return curContact.type == 1;
      })
      ?.map((curContact) => {
        return { value: curContact.id, text: curContact.displayName };
      });
    newContcatDD.unshift({ value: "", text: "" });
    setContactDD(newContcatDD);
  };

  // Task Data Change Handle
  const onTaskDataChange = (newTaskData, newDocumentModel) => {
    setTaskData(newTaskData);
    if (newDocumentModel !== undefined && newDocumentModel !== null) {
      var mDocuments = [...documents];
      mDocuments.push(newDocumentModel);
      setDocuments(mDocuments);
    }
  };

  // model Handlers
  const onDelaNameModelOpen = () => {
    setDealModelData({ ...dealModelData, dealname: newDealData.dealName });
    window.$("#deal_name_model").modal("show");
  };

  // Edit Handlers
  const onDealNameEditSave = (e) => {
    window.$("#deal_name_model").modal("hide");
    setNewDealData({ ...newDealData, dealName: dealModelData.dealname });
  };

  // Other Functions
  const doChangeStepClick = (step) => {
    setStep(step);
    removeTaskParam();
  };
  const removeTaskParam = () => {
    const params = new URLSearchParams(window.location.search);

    // Remove the 'taskId' parameter
    if (params.has("taskId")) {
      params.delete("taskId");

      // Update the URL without reloading the page
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.pushState({}, "", newUrl);
      settaskIDParam("");
      //props.removeTaskId();
    }
  };

  const handleStep = (action) => {
    if (action === "next") {
      if (step === 0 && !props.dealID && !newDealData.dealId) {
        const params = new URLSearchParams(window.location.search);
        if (!params.has("isModify")) {
          history.push(
            window.location.pathname + window.location.search + "&isModify=true"
          );
          onNextCallHandle();
        } else {
          setStep(step + 1);
        }
      }
      if (step < 4 && step >= 0) {
        if (step != 0) {
          setStep(step + 1);
        }
      }
    } else if (action === "back") {
      if (step > 0) {
        setStep(step - 1);
      }
    }
  };
  const dateFormater = (inputDateStr) => {
    const parts = inputDateStr.split("-");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    const inputDate = new Date(year, month - 1, day);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dayOfMonth = inputDate.getDate();
    const abbreviatedMonth = monthNames[inputDate.getMonth()];
    const fullYear = inputDate.getFullYear();
    const formattedDateStr = `${dayOfMonth} ${abbreviatedMonth} ${fullYear}`;
    return formattedDateStr;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileWidth(window.innerWidth <= 460);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let taskId = "";

    if (!props.dealID) {
      setAccessType(1);
    } else if (userSession.role) {
      if (
        userSession.role == "EntegralSuperUser" ||
        userSession.role == "OfficeAdministrator" ||
        userSession.role == "GroupAdministrator"
      ) {
        setAccessType(1);
        if (userSession.role == "EntegralSuperUser") {
          setSuperAdmin(true);
        }
      } else {
        setAccessType(0);
      }
    } else {
      setAccessType(null);
    }

    if (params.has("taskId")) {
      const id = params.get("taskId");
      taskId = id !== null ? id : "";
      settaskIDParam(taskId);
    }

    if (taskId != "") {
      setStep(1);
    }
  }, []);
  useEffect(() => {
    setNewDealData(props.data);
    //setDealNote(props.data.dealNote);
    let tagData = [];
    if (
      props.data.officeContactTags.length &&
      props.data.officeContactTags.length > 0
    ) {
      tagData = [...tagData, ...props.data.officeContactTags];
    }
    if (props.data.agentTypesDD.length && props.data.agentTypesDD.length > 0) {
      tagData = [...tagData, ...props.data.agentTypesDD];
    }
    setallAssignedTags(tagData);
  }, [props.data]);
  useEffect(() => {
    setTaskData(newDealData?.stages);
    if (newDealData) {
      let formatedDate = `${newDealData.startDate?.split("-")[2]}-${
        newDealData.startDate?.split("-")[1]
      }-${newDealData.startDate?.split("-")[0]}`;
      let pipeline;
      let pipelineId;
      if (!newDealData.pipeline || newDealData.pipeline === "") {
        pipeline = newDealData?.pipelinesDD[0].text;
        pipelineId = newDealData?.pipelinesDD[0].value;
      } else {
        pipeline = newDealData?.pipeline;
        pipelineId = newDealData?.pipelineId;
      }
      let templeteDD = newDealData?.templatesDD?.filter((curEle) => {
        return curEle.pipelineId == pipelineId;
      });
      templeteDD.unshift({
        pipeline: "",
        template: "",
        pipelineId: "",
        templateId: "",
      });
      setDealModelData({
        dealDate: formatedDate,
        dealname: newDealData.dealName,
        dealTempleteId: newDealData.templateId,
        pipeline: pipeline,
        pipelineId: pipelineId,
      });
      setDealPipelineDD(newDealData.pipelinesDD);
      setdealTEmpleteDD(templeteDD);
      if (newDealData.stages.length > 0) {
        let tasks = [
          {
            taskId: "",
            taskName: "",
            status: 0,
            assignedRoles: [],
            comments: "",
            description: "",
          },
        ];
        let stages = newDealData.stages.map((curElem) => {
          curElem.stageTasks.map((curTask) => {
            tasks.push(curTask);
          });
        });
        setdocumentTaskDD(tasks);
      }
      if (newDealData.documents.length > 0) {
        setDocuments(newDealData.documents);
      }
      if (newDealData.contacts.length > 0) {
        setContacts(newDealData.contacts);
      }
      setContactDD(newDealData.contactDD);

      if (newDealData.deductionTypesDD.length > 0) {
        let newDDdata = newDealData.deductionTypesDD?.map((curElem) => {
          return {
            text: curElem.deduction_name,
            value: curElem.deduction_name,
            deductionName: curElem.deduction_name,
            deductionPercentage: curElem.deduction_percentage,
            deductionAmount: curElem.deduction_amount,
            isVatExcluded: curElem.is_vat_excluded,
          };
        });
        setDeductionTypesDD(newDDdata);
      }
      if (newDealData.splitTypesDD.length > 0) {
        let newDDdata = newDealData.splitTypesDD?.map((curElem) => {
          return {
            text: curElem.split_name,
            value: curElem.split_name,
            splitPercentage: curElem.split_percentage,
            splitAmount: curElem.split_amount,
            splitTaxed: curElem.split_taxed,
            splitName: curElem.split_name,
          };
        });
        setSplitTypesDD(newDDdata);
      }
    }
  }, [newDealData]);

  // Submit Handles
  const onNextCallHandle = () => {
    if (
      dealModelData.dealname == "" ||
      dealModelData.dealname == undefined ||
      dealModelData.dealname == null
    ) {
      toast.info("Enter a valid Deal Name");
    } else {
      setButtonStatus({
        disabled: true,
        processing_save: true,
        processing_delete: false,
        processing_history: false,
      });
      let pipeline = "";
      let pipelineId = "";

      if (newDealData.dealID) {
        pipeline = newDealData.pipeline;
        pipelineId = newDealData.pipelineId;
      } else {
        pipelineId = dealModelData.pipelineId;
        pipeline = dealModelData.pipeline;
      }

      let financeModel = refFinanceComponent.current.getData();

      let model = {
        dealID: newDealData.dealID,
        officeID: newDealData.officeID,
        userID: newDealData.userID,
        propertyID: newDealData.propertyID,
        dealName: dealModelData.dealname,
        template: dealModelData.dealTemplete,
        templateId: dealModelData.dealTempleteId,
        startDate: dealModelData.dealDate,
        mandateStatus: dealModelData.mandateStatus,
        pipeline: pipeline,
        pipelineId: pipelineId,
        dealStage: newDealData.dealStage,
        dealStageId: newDealData.dealStageId,
        stages: [],
        documents: [],
        contacts: [],
        dealRef: newDealData.dealRef,
        templatesDD: newDealData.templatesDD,
        pipelinesDD: newDealData.pipelinesDD,
        assignedRolesDD: newDealData.assignedRolesDD,
        agentTypesDD: newDealData.agentTypesDD,
        contactSourceTypes: newDealData.contactSourceTypes,
        officeContactTags: newDealData.officeContactTags,
        contacts: [],
        dialingCodeDD: newDealData.dialingCodeDD,
        dialingCode: newDealData.dialingCode,
        syncId: newDealData.syncId,
        finance: financeModel,
        splitTypesDD: newDealData.splitTypesDD,
        deductionTypesDD: newDealData.deductionTypesDD,
      };
      apiAgent.Deal.saveDealAjax(model).then((res) => {
        setNewDealData(res);
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
          processing_history: false,
        });
        setStep(step + 1);
      });
    }
  };
  const onSubmitHandle = (isDealClose) => {
    setButtonStatus({
      disabled: true,
      processing_save: true,
      processing_delete: false,
      processing_history: false,
    });
    let pipeline = "";
    let pipelineId = "";
    let docData = [];
    if (newDealData.dealID) {
      pipeline = newDealData.pipeline;
      pipelineId = newDealData.pipelineId;

      docData = documents?.map((doc) => {
        return {
          fileID: doc.fileID,
          fileName: doc.fileName,
          description: doc.description,
          fileicon: doc.fileicon,
          size: doc.size,
          displaySize: doc.displaySize,
          url: "",
          uploadedTime: doc.uploadedTime,
          taskID: doc.taskId,
          taskName: doc.taskName,
          comment: doc.comment,
        };
      });
    } else {
      pipeline = dealModelData.pipeline;

      docData = [];
    }

    let financeModel = refFinanceComponent.current.getData();

    let stageModel = [...taskData];
    let updatedStageModel = stageModel?.map((curStage, curStageIDX) => {
      let updatedTask = curStage.stageTasks?.map((curTask, curTaskIDX) => {
        return { ...curTask, taskIndex: curTaskIDX };
      });
      return { ...curStage, stageTasks: updatedTask };
    });

    let model = {
      dealID: newDealData.dealID,
      officeID: newDealData.officeID,
      userID: newDealData.userID,
      propertyID: newDealData.propertyID,
      dealName: dealModelData.dealname,
      template: newDealData.template,
      templateId: newDealData.templateId,
      startDate: dealModelData.dealDate,
      pipeline: pipeline,
      pipelineId: pipelineId,
      dealStage: newDealData.dealStage,
      dealStageId: newDealData.dealStageId,
      stages: updatedStageModel,
      documents: docData,
      dealRef: newDealData.dealRef,
      templatesDD: newDealData.templatesDD,
      pipelinesDD: newDealData.pipelinesDD,
      assignedRolesDD: newDealData.assignedRolesDD,
      agentTypesDD: newDealData.agentTypesDD,
      contactSourceTypes: newDealData.contactSourceTypes,
      officeContactTags: newDealData.officeContactTags,
      contacts: contacts,
      dialingCodeDD: newDealData.dialingCodeDD,
      dialingCode: newDealData.dialingCode,
      syncId: newDealData.syncId,
      finance: financeModel,
      splitTypesDD: newDealData.splitTypesDD,
      deductionTypesDD: newDealData.deductionTypesDD,
      dealNote: props.data.dealNote,
    };
    apiAgent.Deal.saveDealAjax(model).then((res) => {
      setNewDealData(res);
      if (isDealClose == "DealGrid") {
        setDashboardState("Deals");
        history.push(`/deal`);
      }
      if (isDealClose == "EditDeal") {
        if (newDealData.dealID) {
          const anchorTag = document.createElement("a");
          anchorTag.href = `/deal/saveDeal?ID=${newDealData.dealID}`;
          anchorTag.style.display = "none";
          document.body.appendChild(anchorTag);
          anchorTag.click();
        }
      }
      setButtonStatus({
        disabled: false,
        processing_save: false,
        processing_delete: false,
        processing_history: false,
      });
    });
  };

  //TO Delete The Deal
  const deleteDealCallBack = (result) => {
    setConfirmationModalProps({
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    });
    if (result == true) {
      setButtonStatus({
        disabled: true,
        processing_save: false,
        processing_delete: true,
        processing_history: false,
      });

      apiAgent.Deal.deletedealAjax(newDealData.dealID, newDealData.userID)
        .then((res) => {
          setDashboardState("Deals");
          history.push(`/deal`);
          setButtonStatus({
            disabled: false,
            processing_save: false,
            processing_delete: false,
            processing_history: false,
          });
        })
        .catch((err) => {
          setButtonStatus({
            disabled: false,
            processing_save: false,
            processing_delete: false,
            processing_history: false,
          });
        });
    } else {
      setButtonStatus({
        disabled: false,
        processing_save: false,
        processing_delete: false,
        processing_history: false,
      });
    }
  };
  const deleteDealHandle = () => {
    setConfirmationModalProps({
      showModal: true,
      isConfirmed: (result) => {
        deleteDealCallBack(result);
      },
      title: "Delete Deal",
      body: "Do you want to delete Deal now? This cannot be undone.",
    });
  };

  const handleAuditTrailClose = () => {
    setIsShowAuditTrail(false);
  };
  const handleAuditTrailShow = () => {
    setIsShowAuditTrail(true);
  };

  const toBackAtProperty = () => {
    history.push(`/Property/SaveProperty?ID=${props.data.propertyID}`);
  };

  const setDashboardState = (state) => {
    localStorage.setItem("view", state);
  };

  return (
    <>
      <ConfirmationModalPopup {...confirmationModalProps} />
      <div className="col-sm-12">
        <div
          className={
            newDealData?.dealID !== ""
              ? "panel panel-default oms-box color2 color2-border-right"
              : "panel panel-default oms-box"
          }
        >
          <div className="wrap">
            <div className="left">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    {newDealData?.dealID ? (
                      <div className="d-flex flex-wrap align-items-center">
                        <h5>{`#${newDealData?.dealRef} :  ${newDealData?.dealName}`}</h5>
                        {accessType == 1 && (
                          <i
                            onClick={() => onDelaNameModelOpen()}
                            className="fa fa-edit mx-2"
                          ></i>
                        )}
                      </div>
                    ) : (
                      <h5>{`New Deal`}</h5>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="panel-body"
                style={{ backgroundColor: "white", minHeight: 600 }}
              >
                <div className="col-md-12">
                  <div
                    id="overview"
                    style={{ display: step == 0 ? "" : "none" }}
                  >
                    <DealOverviewStepView
                      ref={refOverviewComponent}
                      newDealData={newDealData}
                      data={newDealData?.stages}
                      contacts={contacts}
                      dealModelData={dealModelData}
                      dealPipelineDD={dealPipelineDD}
                      dealTEmpleteDD={dealTEmpleteDD}
                      inputHandler={inputHandler}
                      onDataChange={onTaskDataChange}
                      assignedRolesDD={newDealData?.assignedRolesDD}
                      syncId={newDealData?.syncId}
                      goToProperty={toBackAtProperty}
                      accessType={accessType}
                      userSession={userSession}
                      currency={newDealData?.currency}
                      dealNote={props.data.dealNote}
                    />
                  </div>
                  <div id="Tasks" style={{ display: step == 1 ? "" : "none" }}>
                    <DealTaskStepView
                      ref={refTaskComponent}
                      UserID={newDealData?.userID}
                      dealID={newDealData?.dealID}
                      dealName={newDealData?.dealName}
                      data={newDealData?.stages}
                      contacts={contacts}
                      assignedRolesDD={newDealData?.assignedRolesDD}
                      onDataChange={onTaskDataChange}
                      syncId={newDealData?.syncId}
                      accessType={accessType}
                      superAdmin={superAdmin}
                      userSession={userSession}
                      allTags={allAssignedTags}
                      taskId={taskIDParam}
                      dealNote={newDealData?.dealNote}
                      onChangeDealNote={onHandleDealNote}
                      actions={props?.data?.actions}
                    />
                  </div>
                  <div
                    id="Contacts"
                    style={{ display: step == 2 ? "" : "none" }}
                  >
                    <div className="row">
                      <DealContactsStepView
                        id="dealContactsStep"
                        super_access={true}
                        OfficeID={newDealData?.officeID}
                        DealID={newDealData?.dealID}
                        UserID={newDealData?.userID}
                        DialingCode={newDealData?.dialingCode}
                        DialingCodeDD={newDealData?.dialingCodeDD}
                        OfficeContactTags={newDealData?.officeContactTags}
                        UserContactTags={[]}
                        AgentTypes={newDealData?.agentTypesDD}
                        ContactSourceTypes={newDealData?.contactSourceTypes}
                        Contacts={contacts}
                        onChange={onContactComponentChange}
                        accessType={accessType}
                        userSession={userSession}
                      />
                    </div>
                  </div>
                  <div
                    id="Documents"
                    style={{ display: step == 3 ? "" : "none" }}
                  >
                    <div className="row">
                      <DealDocumentStepView
                        save_access={true}
                        data={documents}
                        onChange={onFileComponentChange}
                        officeSyncID={props.officeSyncID}
                        propertyID={props.dealID}
                        taskDD={documentTaskDD}
                        syncId={newDealData?.syncId}
                        accessType={accessType}
                        userSession={userSession}
                        taskData={taskData}
                        actions={props?.data?.actions}
                      />
                    </div>
                  </div>
                  <div
                    id="Finance"
                    style={{ display: step == 4 ? "" : "none" }}
                  >
                    <DealFinanceStepView
                      ref={refFinanceComponent}
                      id="dealFinanceStep"
                      currency={newDealData?.currency}
                      super_access={true}
                      OfficeID={newDealData?.officeID}
                      DealID={newDealData?.dealID}
                      UserID={newDealData?.userID}
                      Finance={newDealData?.finance}
                      deductionTypesDD={deductionTypesDD}
                      splitTypesDD={splitTypesDD}
                      contacts={contacts}
                      accessType={accessType}
                      userSession={userSession}
                    />
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6">
                      {props.dealID !== "" ? (
                        <div
                          className={
                            isMobileWidth ? "btn-pull-center" : "pull-left"
                          }
                        >
                          <div className="dropup">
                            <button
                              className="btn btn-outline dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                            >
                              More..
                              <span className="caret" />
                            </button>
                            <ul className="dropdown-menu">
                              {accessType == 1 && (
                                <li key="delete">
                                  <OMSButtonStyleL
                                    style={{ marginRight: 10 }}
                                    type="button"
                                    ui_icon="fa-trash-o"
                                    ui_type="cancel"
                                    ui_text="Delete deal"
                                    ui_processing_text="Deleting.."
                                    processing={buttonStatus.processing_delete}
                                    disabled={buttonStatus.disabled}
                                    onClick={() => deleteDealHandle()}
                                  />
                                </li>
                              )}
                              {accessType == 1 && (
                                <li key="history">
                                  <OMSButtonStyleL
                                    style={{ marginRight: 10 }}
                                    type="button"
                                    ui_icon="fa-history"
                                    ui_type="cancel"
                                    ui_text="History"
                                    ui_processing_text="opening history...."
                                    processing={buttonStatus.processing_delete}
                                    disabled={buttonStatus.disabled}
                                    onClick={() => handleAuditTrailShow()}
                                  />
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <label style={{ padding: "6px 12px" }}>
                          Step {step + 1} of 5{" "}
                        </label>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="btn-right">
                        {props.dealID !== "" ? (
                          <>
                            <OMSButtonStyleL
                              style={{ marginRight: 10 }}
                              type="button"
                              ui_icon="fa-times"
                              ui_class="btnCtrl"
                              ui_type="cancel"
                              ui_text="Cancel"
                              ui_processing_text=""
                              processing={false}
                              disabled={buttonStatus.disabled}
                              onClick={onCancelClick}
                            />
                            {props?.data?.actions?.indexOf("save") != -1 && (
                              <>
                                <OMSButtonStyleL
                                  style={{ marginRight: 5 }}
                                  type="button"
                                  ui_icon="fa-check"
                                  ui_type="save"
                                  ui_text="Save"
                                  ui_processing_text="Saving.."
                                  processing={buttonStatus.processing_save}
                                  disabled={buttonStatus.disabled}
                                  onClick={() => onSubmitHandle(null)}
                                />
                                <OMSButtonStyleL
                                  style={{ marginRight: 0 }}
                                  type="button"
                                  ui_icon="fa-check"
                                  ui_type="save"
                                  ui_text="Save & close"
                                  ui_processing_text="Saving.."
                                  processing={buttonStatus.processing_save}
                                  disabled={buttonStatus.disabled}
                                  onClick={() => onSubmitHandle("DealGrid")}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <div>
                            {step <= 0 ? null : (
                              <>
                                {step === 0 ? null : (
                                  <button
                                    onClick={() => handleStep("back")}
                                    className="btn btn-success-oms mx-2"
                                  >
                                    <i className="fa fa-arrow-left"></i>{" "}
                                    Previous
                                  </button>
                                )}
                              </>
                            )}
                            {step >= 4 ? null : (
                              <OMSButtonStyleL
                                style={{ marginRight: 10 }}
                                type="button"
                                ui_icon="fa-arrow-right"
                                ui_type="save"
                                ui_text="Next"
                                ui_processing_text="Saving.."
                                processing={buttonStatus.processing_save}
                                disabled={buttonStatus.disabled}
                                onClick={() => handleStep("next")}
                              />
                            )}
                            {step === 4 ? (
                              <OMSButtonStyleL
                                style={{ marginRight: 10 }}
                                type="button"
                                ui_icon="fa-check"
                                ui_type="save"
                                ui_text="Save"
                                ui_processing_text="Saving.."
                                processing={buttonStatus.processing_save}
                                disabled={buttonStatus.disabled}
                                onClick={() => onSubmitHandle("EditDeal")}
                              />
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {props.dealID == "" ? null : (
              <div className="right color2 hidden-xs hidden-sm">
                <div className="row">
                  <div className="col-md-12">
                    <a
                      className={
                        step == 0
                          ? "btn text-center property-menu-selected"
                          : "btn text-center property-menu"
                      }
                      onClick={() => doChangeStepClick(0)}
                    >
                      <span>
                        <i className="fa fa-tachometer fa-2x"></i>
                        <br />
                        Overview
                      </span>
                    </a>
                    <a
                      className={
                        step == 1
                          ? "btn text-center property-menu-selected"
                          : "btn text-center property-menu"
                      }
                      onClick={() => doChangeStepClick(1)}
                    >
                      <span>
                        <i className="fa fa-clock-o fa-2x"></i>
                        <br />
                        Tasks
                      </span>
                    </a>
                    <a
                      className={
                        step == 2
                          ? "btn text-center property-menu-selected"
                          : "btn text-center property-menu"
                      }
                      onClick={() => doChangeStepClick(2)}
                    >
                      <span>
                        <i className="fa fa-address-book fa-2x"></i>
                        <br />
                        Contacts
                      </span>
                    </a>
                    <a
                      className={
                        step == 3
                          ? "btn text-center property-menu-selected"
                          : "btn text-center property-menu"
                      }
                      onClick={() => doChangeStepClick(3)}
                    >
                      <span>
                        <i className="fa fa-file-text-o fa-2x"></i>
                        <br />
                        Documents
                      </span>
                    </a>
                    <a
                      className={
                        step == 4
                          ? "btn text-center property-menu-selected"
                          : "btn text-center property-menu"
                      }
                      onClick={() => doChangeStepClick(4)}
                    >
                      <span>
                        <i className="fa fa-pie-chart fa-2x"></i>
                        <br />
                        Finance
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {props.dealID == "" ? null : (
              <div className="rightS color2 hidden-md hidden-lg">
                <div className="row">
                  <div className="col-md-12">
                    <a
                      className={
                        step == 0
                          ? "btn text-center property-menu-selected"
                          : "btn text-center property-menu"
                      }
                      onClick={() => doChangeStepClick(0)}
                    >
                      <i className="fa fa-tachometer fa-2x"></i>
                    </a>
                    <a
                      className={
                        step == 1
                          ? "btn text-center property-menu-selected"
                          : "btn text-center property-menu"
                      }
                      onClick={() => doChangeStepClick(1)}
                    >
                      <i className="fa fa-clock-o fa-2x"></i>
                    </a>
                    <a
                      className={
                        step == 2
                          ? "btn text-center property-menu-selected"
                          : "btn text-center property-menu"
                      }
                      onClick={() => doChangeStepClick(2)}
                    >
                      <i className="fa fa-address-book fa-2x"></i>
                    </a>
                    <a
                      className={
                        step == 3
                          ? "btn text-center property-menu-selected"
                          : "btn text-center property-menu"
                      }
                      onClick={() => doChangeStepClick(3)}
                    >
                      <i className="fa fa-file-text-o fa-2x"></i>
                    </a>
                    <a
                      className={
                        step == 4
                          ? "btn text-center property-menu-selected"
                          : "btn text-center property-menu"
                      }
                      onClick={() => doChangeStepClick(4)}
                    >
                      <i className="fa fa-pie-chart fa-2x"></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deal_name_model"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deal_name_model"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h3 className="modal-title" id="">
                Update Deal Name
              </h3>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={dealModelData.dealname}
                      onChange={(e) => inputHandler(e, "dealName")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  <i className="fa fa-times"></i> Cancel
                </button>
                <button
                  className="btn btn-success-oms"
                  onClick={() => onDealNameEditSave()}
                >
                  <i className="fa fa-check"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowAuditTrail === true && (
        <AuditTrailBox
          id="auditDeal"
          objectID={newDealData?.dealID}
          officeID={newDealData?.officeID}
          onClose={handleAuditTrailClose}
        ></AuditTrailBox>
      )}
    </>
  );
}

export default DealBox;
