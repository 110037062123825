import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";

import { store } from "../stores/store";
import { history } from "../..";
import { ResetFormValues, ResetModel } from "../models/ResetModel";
import { SettingsModel } from "../models/SettingsModel";
import { LoginModel, TwoFASetupModel } from "../models/LoginModel";
import { UserListCountModel } from "../models/UserListCountModel";
import { UserModel } from "../models/UserModel";
import { UserIMAPModel } from "../models/UserIMAPModel";
import { LoginResponse, User, UserFormValues, UserSession } from "../models/User";
import { FavouriteModel } from "../models/FavouriteModel";
import { ContactModel } from "../models/ContactModel";
import { AuditTrailViewModel } from "../models/AuditTrailViewModel";
import { MessageGridModel } from "../models/MessageGridModel";
import { DiaryGridModel } from "../models/DiaryGridModel";
import { RequirementModel } from "../models/RequirementModel";
import { RequirementMatchModel } from "../models/RequirementMatchModel";
import { ReferralModel } from "../models/ReferralModel";
import DiaryModel from "../models/DiaryModel";
import { SendSMSModel } from "../models/SendSMSModel";
import { ContactComponentModel } from "../models/ContactComponentModel";
import { LeadModel } from "../models/LeadModel";
import { FSFolderTreeModel } from "../models/FSFolderTreeModel";
import { SaveFSFolderModel } from "../models/SaveFSFolderModel";
import { ContactsResponseModel } from "../models/ContactsResponseModel";
import { ReportGridModel } from "../models/ReportGridModel";
import { HomeResponseModel } from "../models/HomeResponseModel";
import { OfficeResponseModel } from "../models/OfficeResponseModel";
import { OfficeModel } from "../models/OfficeModel";
import { GroupModel } from "../models/GroupModel";
import { CountryModel } from "../models/CountryModel";
import { IntegrationsResponseModel } from "../models/IntegrationsResponseModel";
import { ImportResponseModel } from "../models/ImportResponseModel";
import { BulkSyncResponseModel } from "../models/BulkSyncResponseModel";
import { ClearCacheResponseModel } from "../models/ClearCacheResponseModel";
import { P24AreaMapResponseModel } from "../models/P24AreaMapResponseModel";
import { AreaTransferResponseModel } from "../models/AreaTransferResponseModel";
import { UserTransferResponseModel } from "../models/UserTransferResponseModel";
import { BrochureModel } from "../models/BrochureModel";
import { CreditResponseModel } from "../models/CreditResponseModel";
import { SyncIssuesResponseModel } from "../models/SyncIssuesResponseModel";
import { OMSToBaseResponseModel } from "../models/OMSToBaseResponseModel";
import { UsersResponseModel } from "../models/UsersResponseModel";
import { CustomizeModel } from "../models/CustomizeModel";
import { AuditTrailResponseModel } from "../models/AuditTrailResponseModel";
import { ReportModel } from "../models/ReportModel";
import { ProvinceModel } from "../models/ProvinceModel";
import { TownModel } from "../models/TownModel";
import { SuburbModel } from "../models/SuburbModel";
import { AdminRequestModel } from "../models/AdminRequestModel";
import { stringify } from "querystring";
import { LineAndCharacter } from "typescript";
import { PropertyResponseModel } from "../models/PropertyResponseModel";
import { LibraryResponseModel } from "../models/LibraryResponseModel";
import { NoticeboardModel } from "../models/NoticeboardModel";
import { ContentModel } from "../models/ContentModel";
import { FavouriteGridModel, PaggedGridModel } from "../models/PaggedGridModel";
import { PropertyModel } from "../models/PropertyModel";
import { PropertyRequestModel } from "../models/PropertyRequestModel";
import { property } from "lodash";
import { SaveAreaModel } from "../models/SaveAreaModel";
import { SearchResultModel } from "../models/SearchResultModel";
import { HeaderModel } from "../models/HeaderModel";
import { request } from "http";
import { IntegrationModel } from "../models/IntegrationModel";
import { ImportPropertiesResponseModel } from "../models/ImportPropertiesResponseModel";
import { ImportPropertyModel } from "../models/ImportPropertyModel";
import DealModel, { split, stageTask } from "../models/DealModel";
import { MasterDataModel } from "../models/MasterDataModel";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.defaults.baseURL = process.env.REACT_APP_ROOT_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  //console.log(" inside api agent interceptor: token=", token);

  if (token && axios.getUri(config).includes(axios.defaults.baseURL!)) {
    config.headers!.Authorization = `Bearer ${token}`;
    config.withCredentials = true;
  }
  //if (token) config.headers!.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    //if (process.env.NODE_ENV === "development") await sleep(1000);

    // const pagination = response.headers["pagination"];
    // if (pagination) {
    //   response.data = new PaginatedResult(
    //     response.data,
    //     JSON.parse(pagination)
    //   );
    //   return response as AxiosResponse<PaginatedResult<any>>;
    // }
    return response;
  },
  (error: AxiosError<any, any>) => {
    const { data, status, config, headers } = error.response!;
    switch (status) {
      case 400:
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          //console.log("400 error");
          history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          toast.error(data);
        }
        break;
      case 401:
        if (status === 401 && headers["www-authenticate"]?.startsWith('Bearer error="invalid_token"')) {
          store.userStore.logout();
          toast.error("Session expired - please login again");
        }
        break;
      case 403:
        history.push("/home/unauthorized");
        break;
      case 404:
        //console.log("404 error");
        history.push("/not-found");
        break;
      case 500:
        //store.commonStore.setServerError(data);
        //history.push("/server-error");
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  getFile: <T>(url: string) => axios.get<T>(url, { responseType: "blob" }),
  postJson: <T>(url: string, body: {}) => axios.post<T>(url, body, { headers: { "Content-Type": "application/json" } }).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  postForm: <T>(url: string, formData: FormData) => {
    //console.log("inside postForm, formData=", formData);
    //let formData = new FormData();
    //formData.append("File", file);
    return axios
      .post<T>(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        transformRequest: () => formData,
      })
      .then(responseBody);
  },
};

const Account = {
  current: () => requests.get<UserSession>("/auth/current"),
  login: (user: UserFormValues) => requests.post<LoginResponse>("/auth/login", user),
  loginAs: (userID: string) => requests.post<UserSession>(`/auth/LogInAs?userID=${userID}`, {}),
  logoutAs: () => requests.post<UserSession>(`/auth/LogOutAs`, {}),
  register: (user: UserFormValues) => requests.post<User>("/account/register", user),
  forgotPassword: (emailAddress: string) => {
    return requests.post<User>("/auth/forgotPassword", { emailAddress });
  },
  refreshToken: () => requests.post<User>("/account/refreshToken", {}),
  verifyEmail: (token: string, username: string) => requests.post<void>(`/account/verifyEmail?token=${token}&username=${username}`, {}),
  resendEmailConfirm: (username: string) => requests.get(`/account/resendEmailConfirmationLink?username=${username}`),
  getUserByResetToken: (token: string) => requests.get<ResetModel>(`/auth/getUserByResetToken?token=${token}`),
  resetPasswordAjax: (model: ResetFormValues) => requests.post<any>("/auth/resetPasswordAjax", model),
  resetTwoFAAjax: (token: string) => requests.post<any>(`/auth/resetTwoFAAjax?token=${token}`, {}),
  getLoginModel: () => requests.get<LoginModel>(`/auth/login`),
  verify2FACode: (verificationCode: string, emailAddress: string, strIsRemember: string) =>
    requests.post<any>(`/auth/verify2FACode?verificationCode=${verificationCode}&emailAddress=${emailAddress}&strIsRemember=${strIsRemember}`, {}),
  lostAccessToPhone: (emailAddress: string) => requests.post<any>(`/auth/lostAccessToPhone?emailAddress=${emailAddress}`, {}),
};

const twoFactorAuth = {
  get2FASetupInfo: (userEmail: string) => requests.get<TwoFASetupModel>(`/twoFactorAuth/get2FASetupInfo`),
  verify2FACodeInitialSetup: (verificationCode: string, base32Secret: string) =>
    requests.post<any>(`/twoFactorAuth/verify2FACodeInitialSetup?verificationCode=${verificationCode}&base32Secret=${base32Secret}`, {}),
  verify2FACode: (verificationCode: string, emailAddress: string) =>
    requests.post<any>(`/twoFactorAuth/verify2FACode?verificationCode=${verificationCode}&emailAddress=${emailAddress}`, {}),
};

const Home = {
  setFavouriteAjax: (model: FavouriteModel) => requests.post<any>("/home/setFavouriteAjax", model),
  resetFavouriteAjax: (model: FavouriteModel) => requests.post<any>("/home/resetFavouriteAjax", model),
  setSnoozAjax: (objectID: string, typeID: number) => requests.post<any>(`/home/setSnoozAjax?ObjectID=${objectID}&TypeID=${typeID}`, {}),
  getHomePage: () => requests.get<HomeResponseModel>("/home/index"),
  //getFavouritesAjax: (page?: number) => requests.get<PaggedGridModel<FavouriteGridModel>>(`/home/favouritesAjax?Page=${page}`),
  getFavouritesAjax: (page?: number) => requests.get(`/home/favouritesAjax?Page=${page}`),
  searchAjax: (search: string, searchType?: number, page?: number) => requests.get<any>(`/home/searchAjax?Search=${search}&SearchType=${searchType}`),
  getPropertyIDFromWebRefAjax: (webRef?: string) => requests.get<any>(`/home/getPropertyIDFromWebRefAjax?webRef=${webRef}`),
  // getHeaderDetails: () => requests.get<HeaderModel>("/home/getHeaderDetails"),
};

const Contact = {
  getContacts: () => requests.get<ContactsResponseModel>("/contacts"),
  // getAuditTrailData: (requestParams: any, officeId: string, userId: string, objectId: string, moduleId: number) =>
  //   //requests.post<any>(`settings/auditTrailAjax?model=${requestParams}&OfficeID=${officeId}&UserID=${userId}&ObjectID=${objectId}&ModuleID=${moduleId}`, {}),
  //   //requests.post<any>(`settings/auditTrailAjax, {}),
  //   requests.post("settings/auditTrailAjax", { requestParams, officeId, userId, objectId, moduleId }),
  getContactsData: (search: string, tags: string, sources: string, referralStatus: string, view: number, contactViewType: number) => {
    //console.log("inside apiAgent, sources: ", sources);
    return requests.post<any>(
      `contacts/GetContactsData?search=${search}&tags=${tags}&sources=${sources}&referralStatus=${referralStatus}&view=${view}&contactViewType=${contactViewType}`,
      //`contacts/GetContactsData?request=${requestParams}&search=${search}&tags=${tags}&sources=${sources}&referralStatus=${referralStatus}&view=${view}&contactViewType=${contactViewType}`,
      {}
    );
  },
  contactInboxAjax: (leads: string, view: number, leadType: number, leadActionType: number) => {
    return requests.post<any>(`inbox/contactInboxAjax?Leads=${leads}&View=${view}&LeadType=${leadType}&LeadActionType=${leadActionType}`, {});
  },
  getContactsInbox: (leads: string, view: number, leadType: number, leadActionType: number) =>
    requests.post<any>(`inbox/ContactInboxAjax?leads=${leads}&view=${view}&leadType=${leadType}&leadActionType=${leadActionType}`, {}),
  exportContactAjax: () => requests.get<any>(`contacts/ExportContactAjax`),
  placeCreditOrderEFTAjax: (userID: string, packageID: string) =>
    requests.post<void>(`/contacts/placeCreditOrderEFTAjax?UserID=${userID}&PackageID=${packageID}`, {}),
  placeCreditOrderCreditCardAjax: (userID: string, packageID: string) =>
    requests.post<void>(`/contacts/placeCreditOrderCreditCardAjax?UserID=${userID}&PackageID=${packageID}`, {}),
  saveContact: (id: string, cName: string, cEmail: string, cNo: string) =>
    //requests.get<ContactModel>(`/contacts/saveContact?ID=${id}&cName=${cName}&cEmail=${cEmail}&cNo=${cNo}`),
    requests.get<string>(`/contacts/saveContact?ID=${id}&cName=${cName}&cEmail=${cEmail}&cNo=${cNo}`),
  saveContactAjax: (model: ContactModel) => {
    //console.log("inside api agent saveContactAjax", model);
    return requests.post<any>(`/contacts/saveContactAjax?UserID=${model.userID}`, model);
  },
  deleteContactAjax: (contactID: string, userID: string) => requests.post<void>(`/contacts/deleteContactAjax?ContactID=${contactID}&UserID=${userID}`, {}),
  requirementsAjax: (contactID: string) => requests.get<RequirementModel>(`/contacts/requirementsAjax?contactID=${contactID}`),
  saveRequirement: (model: RequirementModel, userID: string) => requests.post<any>(`/contacts/saveRequirement?UserID=${userID}`, model),
  requirementsMatchAjax: (contactID: string) => requests.get<RequirementMatchModel>(`/contacts/requirementsMatchAjax?contactID=${contactID}`),
  referralAjax: (contactID: string) => requests.get<ReferralModel>(`/contacts/referralAjax?contactID=${contactID}`),
  saveReferral: (model: ReferralModel, userID: string) => requests.post<any>(`/contacts/saveReferral?UserID=${userID}`, model),
  getContactMsgAllAjax: (contactID: string, type: number, parentMessageID: string) =>
    requests.get<any>(`/contacts/getContactMsgAllAjax?contactID=${contactID}&ParentMessageID=${parentMessageID}&Type=${type}`),
  sendSMSAjax: (model: SendSMSModel) => requests.post<any>("/contacts/sendSMSAjax", model),
  /* contactMsgMarkReadAjax: (contactID: string, type: number, parentMessageID: string) =>
    requests.post<any>("/contacts/contactMsgMarkReadAjax", {
      contactID,
      type,
      parentMessageID,
    }), */
  contactMsgMarkReadAjax: (contactID: string, type: number, parentMessageID: string) =>
    requests.post<any>(`/contacts/contactMsgMarkReadAjax?ContactID=${contactID}&Type=${type}&ParentMessageID=${parentMessageID}`, {}),
  saveContactComponentAjax: (model: ContactComponentModel) => {
    //console.log("inside api agent saveContactComponentAjax", model);
    return requests.post<any>(`/contacts/saveContactComponentAjax?UserID=${model.userID}`, model);
  },
  searchAgentAjax: async (officeid: string, query: string) => requests.get<any>(`/contacts/searchAgentAjax?OfficeID=${officeid}&Query=${query}`),
  getContactMsgGridAjax: (contactID: string) => requests.get<MessageGridModel>(`/contacts/getContactMsgGridAjax?contactID=${contactID}`),
  requirementsClientMatchAjax: (
    propertyID: string,
    priceRange: boolean,
    areas: boolean,
    buildsize: boolean,
    landsize: boolean,
    beds: boolean,
    baths: boolean,
    garages: boolean,
    parkingBays: boolean
  ) =>
    requests.get<any>(
      `/contacts/requirementsClientMatchAjax?PropertyID=${propertyID}&PriceRange=${priceRange}&Areas=${areas}&Buildsize=${buildsize}&Landsize=${landsize}&Beds=${beds}&Baths=${baths}&Garages=${garages}&ParkingBays=${parkingBays}`
    ),
};

const Setting = {
  getSettings: () => requests.get<SettingsModel>("/settings"),
  getUsersListingsCount: (userID: string) => requests.get<UserListCountModel>(`/settings/getUsersListingsCount?UserID=${userID}`),
  saveUserAjax: (model: any) => requests.post<any>(`/settings/saveUserAjaxAsync?UserID=${model.userID}`, model),
  getUserProfileByToken: (userId: string) => requests.get<UserModel>(`/settings/saveUser?userId=${userId}`),
  /* clearAuthCodeAjax: (userID: string) =>
    requests.post<void>("/settings/clearAuthCodeAjax", {
      userID,
    }), */
  clearAuthCodeAjax: (userID: string) => requests.post<void>(`/settings/clearAuthCodeAjax?UserID=${userID}`, {}),
  checkIMAPSettingAjax: (model: UserIMAPModel) => requests.post<any>("/settings/checkIMAPSettingAjax", model),
  transferUserAjax: (fromAgentID: string, toAgentID: string) =>
    requests.post<void>(`/settings/transferUserAjax?FromAgentID=${fromAgentID}&ToAgentID=${toAgentID}`, {}),
  deleteUserAjax: (userID: string, isDisabled: boolean, deleteAgentAction: number, transferAgentID: string) =>
    requests.post<void>(
      `/settings/deleteUserAjax?UserID=${userID}&IsDisabled=${isDisabled}&DeleteAgentAction=${deleteAgentAction}&TransferAgentID=${transferAgentID}`,
      {}
    ),
  countriesRateAjax: () => requests.get<any>("/settings/countriesRateAjax"),
  getUsers: (officeID?: string) => requests.get<UsersResponseModel>(`/settings/users?OfficeID=${officeID}`),
  getUsersData: (officeID: string, view: number, requestParams: any) =>
    requests.post(`settings/usersAjax?officeID=${officeID}&view=${view}`, { requestParams, officeID, view }),
  getOffices: () => requests.get<OfficeResponseModel>("/settings/offices"),
  saveOffice: (officeId?: string) => requests.get<OfficeModel>(`/settings/saveOffice?ID=${officeId}`),
  deleteOfficeAjax: (id: string) => requests.post<void>(`/settings/deleteOfficeAjax?ID=${id}`, {}),
  saveOfficeAjax: (model: OfficeModel) => {
    //console.log("inside api agent saveOfficeAjax", model);
    return requests.post<any>(`/settings/saveOfficeAjax?ID=${model.officeID}`, model);
  },
  getOfficesData: (view: number) => requests.post<any>(`settings/officesAjax?view=${view}`, {}),
  saveGroup: (groupId?: string) => requests.get<GroupModel>(`/settings/saveGroup?ID=${groupId}`),
  deleteGroupAjax: (id: string) => requests.post<void>(`/settings/deleteGroupAjax?ID=${id}`, {}),
  saveGroupAjax: (model: GroupModel) => requests.post<any>(`/settings/saveGroupAjax?GroupID=${model.groupID}`, model),
  getGroupsData: (view: number) => requests.post<any>(`settings/groupsAjax?view=${view}`, {}),
  getCountryData: () => requests.post<any>(`settings/countriesAjax`, {}),
  saveCountry: (countryName?: string) => requests.get<CountryModel>(`/settings/saveCountry?name=${countryName}`),
  getAuditTrailData: (requestParams: any, officeId: string, userId: string, objectId: string, moduleId: number) =>
    //requests.post<any>(`settings/auditTrailAjax?model=${requestParams}&OfficeID=${officeId}&UserID=${userId}&ObjectID=${objectId}&ModuleID=${moduleId}`, {}),
    //requests.post<any>(`settings/auditTrailAjax, {}),
    requests.post("settings/auditTrailAjax", { requestParams, officeId, userId, objectId, moduleId }),
  /* saveAuthCodeAjax: (userID: string, authCode: string, entryExists?: boolean) =>
    requests.post<void>("/settings/saveAuthCodeAjax", {
      userID,
      authCode,
      entryExists,
    }), */
  saveAuthCodeAjax: (userID: string, authCode: string, entryExists?: boolean) =>
    requests.post<void>(`/settings/saveAuthCodeAjax?UserID=${userID}&AuthCode=${authCode}&EntryExists=${entryExists}`, {}),
  auditTrailByIdAjax: (officeID: string, objectID: string) =>
    requests.get<AuditTrailViewModel>(`/settings/auditTrailByIdAjax?OfficeID=${officeID}&ObjectID=${objectID}`),
  getUsersDDAjax: (officeID: string) => requests.get<any>(`/settings/getUsersDDAjax?OfficeID=${officeID}`),
  deleteCountryAjax: (name: string) => requests.post<void>(`/settings/deleteCountryAjax?Name=${name}`, {}),
  saveCountryAjax: (model: CountryModel) => requests.post<any>("/settings/saveCountryAjax", model),
  getIntegrations: (officeId?: string) => requests.get<IntegrationsResponseModel>(`/settings/integrations?OfficeID=${officeId}`),
  integrationsAjax: (officeID: string, country: string) => requests.get<any>(`/settings/integrationsAjax?OfficeID=${officeID}&Country=${country}`),
  officeModuleAjax: (officeID: string) => requests.get<any>(`/settings/officeModuleAjax?OfficeID=${officeID}`),
  saveOfficeModuleRequestActivateAjax: (model: any) => requests.post(`/settings/saveOfficeModuleRequestActivateAjax?OfficeID=${model.officeID}`, model),
  saveOfficeModuleRequestDeactivateAjax: (model: any) => requests.post(`/settings/saveOfficeModuleRequestDeactivateAjax?OfficeID=${model.officeID}`, model),
  saveModuleActivateAjax: (model: any) => requests.post(`/settings/saveModuleActivateAjax?OfficeID=${model.officeID}`, model),
  saveModuleDeactivateAjax: (model: any) => requests.post(`/settings/saveModuleDeactivateAjax?OfficeID=${model.officeID}`, model),
  officeKeys2Ajax: (officeID: string, firsttime?: boolean) => requests.get<any>(`/settings/officeKeys2Ajax?OfficeID=${officeID}&firsttime=${firsttime}`),
  deleteOfficeKeyAjax: (officeID: string, apiKey: string) => requests.post(`/settings/deleteOfficeKeyAjax?OfficeID=${officeID}&ApiKey=${apiKey}`, {}),
  saveOfficeKeyAjax: (officeID: string, applicationName: string, applicationPrimaryUserID: string, isRestrict?: boolean) =>
    requests.post(
      `/settings/saveOfficeKeyAjax?OfficeID=${officeID}&ApplicationName=${applicationName}&ApplicationPrimaryUserID=${applicationPrimaryUserID}&IsRestrict=${isRestrict}`,
      {}
    ),
  updateSnapshotAjax: (officeID: string, enable?: boolean) => requests.post(`/settings/updateSnapshotAjax?OfficeID=${officeID}&Enable=${enable}`, {}),
  provincesDDAjax: (country: string) => requests.get<any>(`/settings/provincesDDAjax?Country=${country}`),
  townsDDAjax: (provinceID: string) => requests.get<any>(`/settings/townsDDAjax?ProvinceID=${provinceID}`),
  suburbsDDAjax: (townID: string) => requests.get<any>(`/settings/suburbsDDAjax?TownID=${townID}`),
  getCustomize: () => requests.get<CustomizeModel>("/settings/customize"),
  customizeAjax: (model: CustomizeModel) => requests.post<any>("/settings/customizeAjax", model),
  customizeAjax2: (model: CustomizeModel) => requests.post<any>("/settings/customizeAjax2", model),
  getAuditTrail: (objectID?: string, moduleID?: number) =>
    requests.get<AuditTrailResponseModel>(`/settings/auditTrail?ModuleID=${moduleID}&ObjectID=${objectID}`),
  auditTrailResultAjax: (dataRef: string, moduleID?: string, objectID?: string) =>
    requests.get<any>(`/settings/auditTrailResultAjax?DataRef=${dataRef}&ModuleID=${moduleID}&ObjectID=${objectID}`),
  selectProvinceAjax: (provinceID: string) => requests.get<any>(`/settings/selectProvinceAjax?ProvinceID=${provinceID}`),
  deleteProvinceAjax: (provinceID: string, country: string) =>
    requests.post<void>(`/settings/deleteProvinceAjax?provinceID=${provinceID}&country=${country}`, {}),
  selectTownAjax: (townID: string) => requests.get<any>(`/settings/selectTownAjax?TownID=${townID}`),
  deleteTownAjax: (provinceID: string, townID: string) => requests.post<void>(`/settings/deleteTownAjax?ProvinceID=${provinceID}&TownID=${townID}`, {}),

  selectSuburbAjax: (suburbID: string) => requests.get<any>(`/settings/selectSuburbAjax?SuburbID=${suburbID}`),
  deleteSuburbAjax: (suburbID: string, townID: string) => requests.post<void>(`/settings/deleteSuburbAjax?SuburbID=${suburbID}&TownID=${townID}`, {}),

  saveProvinceAjax: (model: any) => {
    let promise = requests.post<any>("/settings/saveProvinceAjax", model);
    return promise;
  },
  saveTownAjax: (model: any) => {
    let promise = requests.post<any>("/settings/saveTownAjax", model);
    return promise;
  },
  saveSuburbAjax: (model: any) => {
    //console.log("Inside API Agent:saveSuburbAjax", model);
    let promise = requests.post<any>("/settings/saveSuburbAjax", model);
    return promise;
  },
  saveAreaAjax: (model: SaveAreaModel) => requests.post<any>("/settings/saveAreaAjax", model),
  //RestoreUserAjax:(userID:any) => requests.post<any>("/settings/RestoreUserAjax?userID="+userID, userID),
  RestoreUserAjax: (userID: any) => requests.post<any>(`/settings/RestoreUserAjax?UserID=${userID}`, {}),
  saveIntegration: (officeId?: string, portalID?: number) =>
    requests.get<IntegrationModel>(`/settings/saveIntegration?OfficeID=${officeId}&PortalID=${portalID}`),
  saveIntegrationActivateAjax: (model: any) => requests.post<any>(`/settings/saveIntegrationActivateAjax?OfficeID=${model.officeID}`, model),
  saveIntegrationRequestActivateAjax: (model: any) => requests.post<any>(`/settings/saveIntegrationRequestActivateAjax?OfficeID=${model.officeID}`, model),
  saveIntegrationRequestDeactivateAjax: (model: any) => requests.post<any>(`/settings/saveIntegrationRequestDeactivateAjax?OfficeID=${model.officeID}`, model),
  saveIntegrationAjax: (model: any) => requests.post<any>(`/settings/saveIntegrationAjax?OfficeID=${model.officeID}`, model),
  saveIntegrationDeactivateAjax: (model: any) => requests.post<any>(`/settings/saveIntegrationDeactivateAjax?OfficeID=${model.officeID}`, model),
};

const ReportViewer = {
  viewReport: (
    ID: string,
    SaleType: string,
    strStatus: string,
    Mandate: string,
    PropertyType: string,
    Agent: string,
    PriceFrom: string,
    PriceTo: string,
    MandateStartFrom: string,
    MandateStartTo: string,
    MandateEndFrom: string,
    MandateEndTo: string,
    DealID?: string,
    UserID?: string,
    PipelineId?: string,
    RegistrationStartDate?: string,
    RegistrationEndDate?: string
  ) =>
    requests.get<any>(
      `/reportViewer/viewReport?ID=${ID}&SaleType=${SaleType}&strStatus=${strStatus}
    &Mandate=${Mandate}&PropertyType=${PropertyType}&Agent=${Agent}&PriceFrom=${PriceFrom}
    &PriceTo=${PriceTo}&MandateStartFrom=${MandateStartFrom}&MandateStartTo=${MandateStartTo}
    &MandateEndFrom=${MandateEndFrom}&MandateEndTo=${MandateEndTo}&DealID=${DealID}&UserID=${UserID}&PipelineId=${PipelineId}&RegistrationStartDate=${RegistrationStartDate}&RegistrationEndDate=${RegistrationEndDate}`
    ),
  viewCommissionSlip: (DealID?: string, UserID?: string) => requests.get<any>(`/reportViewer/viewCommissionSlip?DealID=${DealID}&UserID=${UserID}`),
  reportsForControlAjax: (category: string) => requests.get<ReportGridModel>(`/reportViewer/reportsForControlAjax?Category=${category}`),
};

const Admin = {
  saveBrochure: (brochureId?: string) => requests.get<BrochureModel>(`/admin/saveBrochure?ID=${brochureId}`),
  deleteBrochureAjax: (brochureId: string) => requests.post<void>(`/admin/deleteBrochureAjax?ID=${brochureId}`, {}),
  saveBrochureAjax: (model: BrochureModel) => requests.post<any>("/admin/saveBrochureAjax", model),
  saveReport: (reportId?: string) => requests.get<ReportModel>(`/admin/saveReport?ID=${reportId}`),
  saveCommissionSlip: (slipID?: string) => requests.get<ReportModel>(`/admin/saveCommissionSlip?ID=${slipID}`),
  deleteReportAjax: (reportId: string) => requests.post<void>(`/admin/deleteReportAjax?ID=${reportId}`, {}),
  deleteCommissionSlipAjax: (slipID: string) => requests.post<void>(`/admin/deleteCommissionSlipAjax?ID=${slipID}`, {}),
  saveReportAjax: (model: FormData) => requests.postForm<any>("/admin/saveReportAjax", model),
  saveCommissionSlipAjax: (model: FormData) => requests.postForm<any>("/admin/saveCommissionSlipAjax", model),
  getBulkSync: () => requests.get<BulkSyncResponseModel>("/admin/bulkSync"),
  bulkSyncAjax: (officeID: string, portalID: number, taskID: number) =>
    requests.post(`/admin/bulkSyncAjax?OfficeID=${officeID}&PortalID=${portalID}&TaskID=${taskID}`, {}),
  getClearCache: () => requests.get<ClearCacheResponseModel>("/admin/clearCache"),
  clearCacheAjax: (officeID: string, cacheID: string) => requests.post<void>(`/admin/clearCacheAjax?OfficeID=${officeID}&CacheID=${cacheID}`, {}),
  getP24Match: () => requests.get<any>("/admin/p24Match"),
  p24MatchAjax: (baseRef: string, p24Ref: string) => requests.post<void>(`/admin/p24MatchAjax?BaseRef=${baseRef}&P24Ref=${p24Ref}`, {}),
  getP24AreaMap: () => requests.get<P24AreaMapResponseModel>("/admin/p24AreaMap"),
  p24AreaAjax: (country: string) => requests.get<any>(`/admin/p24AreaAjax?Country=${country}`),
  deleteP24AreaMapAjax: (sourceId: string, keyArea: string) => requests.post<void>(`/admin/deleteP24AreaMapAjax?sourceId=${sourceId}&keyArea=${keyArea}`, {}),
  p24AreaMapAjax: (baseProvinceID: string, baseTownID: string, baseSuburbID: string, p24Province: string, p24Town: string, p24Suburb: string) =>
    requests.post<void>(
      `/admin/p24AreaMapAjax?BaseProvinceID=${baseProvinceID}&BaseTownID=${baseTownID}
    &BaseSuburbID=${baseSuburbID}&P24Province=${p24Province}&P24Town=${p24Town}&P24Suburb=${p24Suburb}`,
      {}
    ),
  getAreaTransfer: () => requests.get<AreaTransferResponseModel>("/admin/areaTransfer"),
  areaTransferAjax: (fromProvinceID: string, fromTownID: string, fromSuburbID: string, toProvinceID: string, toTownID: string, toSuburbID: string) =>
    requests.post<void>(
      `/admin/areaTransferAjax?FromProvinceID=${fromProvinceID}&FromTownID=${fromTownID}&FromSuburbID=${fromSuburbID}&ToProvinceID=${toProvinceID}&ToTownID=${toTownID}&ToSuburbID=${toSuburbID}`,
      {}
    ),
  getUserTransfer: () => requests.get<UserTransferResponseModel>("/admin/userTransfer"),
  getUsersDDAjax: (officeID: string) => requests.get<any>(`/admin/getUsersDDAjax?OfficeID=${officeID}`),
  userTransferAjax: (fromUserID: string, toUserID: string) => requests.post<void>(`/admin/userTransferAjax?FromUserID=${fromUserID}&ToUserID=${toUserID}`, {}),
  reportsAjax: (view?: number) => requests.get<any>(`/admin/reportsAjax?View=${view}`),
  commissionSlipAjax: () => requests.get<any>(`/admin/commissionSlipAjax`),
  mapAreaToolAjax: () => requests.get<any>("/admin/mapAreaToolAjax"),
  saveMapAreaAjax: (suburbID: number, lat: number, lng: number) => requests.post<void>(`/Admin/SaveMapAreaAjax?SuburbID=${suburbID}&Lat=${lat}&Lng=${lng}`, {}),
  getCredit: () => requests.get<CreditResponseModel>("/admin/credit"),
  creditAjax: (model: AdminRequestModel) => requests.post<any>("/admin/creditAjax", model),
  getSyncIssues: () => requests.get<SyncIssuesResponseModel>("/admin/syncIssues"),
  syncIssuesAjax: (requestParams: any, officeID: string, status: number) => requests.post<any>(`/admin/syncIssuesAjax`, { requestParams, officeID, status }),

  FlexPropertySyncDAjax: (webRef: string, sourceId: string) =>
    requests.post<any>(`/admin/FlexPropertySyncDAjax?WebRef=${webRef}&SourceId=${sourceId}`, { webRef, sourceId }),
  FlexAgentSyncDAjax: (webRef: string, sourceId: string) =>
    requests.post<any>(`/admin/FlexAgentSyncDAjax?WebRef=${webRef}&SourceId=${sourceId}`, { webRef, sourceId }),
  syncReport: () => requests.get<any>(`admin/syncReport`),
  officeDealGridInfoAjax: () => requests.get<any>(`/admin/officeDealGridInfoAjax`),
  perkYouRealtyExpiredAjax: () => requests.get<any>("/admin/perkYouRealtyExpiredAjax"),
};

const Property = {
  getProperty: () => requests.get<PropertyResponseModel>("/Property"),
  getHeaderColumnIndexAjax: (officeID: string) => requests.get<any>(`/Property/GetHeaderColumnIndexAjax?OfficeID=${officeID}`),
  getAgentsDDAjax: (officeID: string) => requests.get<any>(`/Property/GetAgentsDDAjax?OfficeID=${officeID}`),
  // getPropertyData: (
  //   view: number,
  //   saleType: string,
  //   status: string,
  //   mandate: string,
  //   propertyType: string,
  //   agent: string,
  //   priceFrom: string,
  //   priceTo: string,
  //   officeID: string,
  //   needUpdate: boolean,
  //   syndication_Active: boolean,
  //   syndication_Error: boolean,
  //   syndication_Pending: boolean,
  //   mandateStartFrom: string,
  //   mandateStartTo: string,
  //   mandateEndFrom: string,
  //   mandateEndTo: string,
  //   complex: string,
  //   street: string,
  //   areas: string[]
  // ) =>
  //   requests.post<any>(
  //     `Property/IndexDynamicAjax?View=${view}&SaleType=${saleType}&Status=${status}&Mandate=${mandate}&PropertyType=${propertyType}&Agent=${agent}&PriceFrom=${priceFrom}&PriceTo=${priceTo}&OfficeID=${officeID}&NeedUpdate=${needUpdate}&Syndication_Active=${syndication_Active}&Syndication_Error=${syndication_Error}&Syndication_Pending=${syndication_Pending}&MandateStartFrom=${mandateStartFrom}&MandateStartTo=${mandateStartTo}&MandateEndFrom=${mandateEndFrom}&MandateEndTo=${mandateEndTo}&Complex=${complex}&Street=${street}&Areas=${areas}`,
  //     {}
  //   ),
  saveProperty: (propertyId: string) => requests.get<PropertyModel>(`/Property/SaveProperty?ID=${propertyId}`),
  getPropertyGridData: (
    requestParams: any,
    view: number,
    saleType: string,
    status: string,
    mandate: string,
    propertyType: string,
    agent: string,
    priceFrom: string,
    priceTo: string,
    officeID: string,
    needUpdate: boolean,
    syndication_Active: boolean,
    syndication_Error: boolean,
    syndication_Pending: boolean,
    mandateStartFrom: string,
    mandateStartTo: string,
    mandateEndFrom: string,
    mandateEndTo: string,
    complex: string,
    street: string,
    areas: string[],
    ownListings: boolean,
    externalListings: boolean
  ) => {
    return requests.post("Property/IndexDynamicAjax", {
      requestParams,
      view,
      saleType,
      status,
      mandate,
      propertyType,
      agent,
      priceFrom,
      priceTo,
      officeID,
      needUpdate,
      syndication_Active,
      syndication_Error,
      syndication_Pending,
      mandateStartFrom,
      mandateStartTo,
      mandateEndFrom,
      mandateEndTo,
      complex,
      street,
      areas,
      ownListings,
      externalListings,
    });
  },

  getIndexAjax: (
    view: number,
    page: number,
    search: string,
    saleType: string,
    status: string,
    mandate: string,
    propertyType: string,
    agent: string,
    priceFrom: string,
    priceTo: string,
    officeID: string,
    needUpdate: boolean,
    syndication_Active: boolean,
    syndication_Error: boolean,
    syndication_Pending: boolean,
    mandateStartFrom: string,
    mandateStartTo: string,
    mandateEndFrom: string,
    mandateEndTo: string,
    complex: string,
    street: string,
    areas: string[],
    ownListings: boolean,
    externalListings: boolean
  ) => {
    return requests.post("Property/IndexAjax", {
      view,
      page,
      search,
      saleType,
      status,
      mandate,
      propertyType,
      agent,
      priceFrom,
      priceTo,
      officeID,
      needUpdate,
      syndication_Active,
      syndication_Error,
      syndication_Pending,
      mandateStartFrom,
      mandateStartTo,
      mandateEndFrom,
      mandateEndTo,
      complex,
      street,
      areas,
      ownListings,
      externalListings,
    });
  },
  deletePropertyAjax: (propertyID: string, officeSyncID: string) =>
    requests.post<void>(`/Property/DeletePropertyAjax?ID=${propertyID}&officeSyncID=${officeSyncID}`, {}),

  savePropertyAsync2: (model: FormData) => {
    //console.log("inside api agent savePropertyAsync2", model);
    return requests.postForm<any>("/Property/SavePropertyAsync2", model);
  },
  SavePropertyPhotoSpeedUpAjax: (formData: any) => {
    return requests.post<any>("/Property/SavePropertyPhotoSpeedUpAjax", formData);
  },

  listingViewReportAjax: (id: string, from: string, to: string) => requests.get<string>(`/Property/ListingViewReportAjax?ID=${id}&From=${from}&To=${to}`),

  generateBrochureDynamicAjax: (propertyID: string, brochureID: string, agentID: string, showAddress?: boolean) =>
    requests.post<void>(`/Property/GenerateBrochureDynamicAjax?ID=${propertyID}&BrochureID=${brochureID}&ShowAddress=${showAddress}&AgentID=${agentID}`, {}),
  generateBrochureCRAjax: (propertyID: string, brochureID: string, agentID: string, showAddress?: boolean) =>
    requests.getFile<any>(`/Property/GenerateBrochureCRAjax?ID=${propertyID}&BrochureID=${brochureID}&ShowAddress=${showAddress}&AgentID=${agentID}`),

  runVirtualAssistantAjax: (vAOption: Number, description: String) =>
    requests.post(`/Property/RunVirtualAssistantAjax`, {
      vaOption: vAOption,
      description: description,
    }),
  GetOpenAIRequestLeftAjax: () => requests.get<any>(`/Property/GetOpenAIRequestLeftAjax`),
  //RestorePropertyAjax:(id:any) => requests.post<any>("/Property/RestorePropertyAjax?ID="+id, id),
  RestorePropertyAjax: (id: any) => requests.post<any>(`/Property/RestorePropertyAjax?ID=${id}`, {}),
  DownloadMandateFile: (fileName: string, officeSyncID: string) =>
    requests.getFile<any>("/Property/DownloadMandateFile?fileName=" + fileName + "&officeSyncID=" + officeSyncID),
  GetSyndicationSummaryForOffice: (officeID: string) => requests.get<any>(`/Property/GetSyndicationSummaryForOffice?officeID=${officeID}`),

  FetchPropertyImage: (propertyID: string) => requests.get<any>(`/Property/FetchPropertyImage?ID=${propertyID}`),
};

const Library = {
  //getLibrary: () => requests.get<LibraryResponseModel>("/library"),
  testFileUpload: (file: any) => requests.postForm<any>("/library/testFileUpload", file),
  getLibrary: (libraryName?: string) => requests.get<LibraryResponseModel>(`/library?m=${libraryName}`),
  getNoticeboardAjax: () => requests.get<NoticeboardModel>("library/getNoticeboardAjax"),
  SaveNoticeboardAjax: (model: any) => requests.post<any>("/library/saveNoticeboardAjax", model),
  fsFilesAjax: (parentID: string, search: string, view?: number) =>
    requests.get<any>(`/library/fsFilesAjax?View=${view}&ParentID=${parentID}&Search=${search}`),
  fsDeleteFileAjax: (data: FormData) => requests.postForm<any>("/library/fsDeleteFileAjax", data),
  //getUsersData: (requestParams: any, officeID: string, view: number) => requests.post("settings/usersAjax", { requestParams, officeID, view }),
  getContentsData: (requestParams: any, category: string) => requests.post("library/contentsAjax", { requestParams, category }),
  saveContent: (contentID: string, officeID: string) => requests.get<ContentModel>(`/library/saveContent?ContentID=${contentID}&OfficeID=${officeID}`),
  fsUploadFileAjax: (formData: FormData) => requests.postForm<any>("/library/fsUploadFileAjax", formData),
  //fsDownloadFileAjax: (fileID: string) => requests.get<any>(`/library/fsDownloadFileAjax?FileID=${fileID}`),
  fsDownloadFileAjax: (fileID: string) => requests.getFile<any>(`/library/fsDownloadFileAjax?FileID=${fileID}`),
  fsFoldersAjax: (view?: number) => requests.get<FSFolderTreeModel>(`/library/fsFoldersAjax?View=${view}`),
  fsDeleteFolderAjax: (model: SaveFSFolderModel) => requests.post<any>(`/library/fsDeleteFolderAjax?Type=${model.type}`, model),
  fsSaveFolderAjax: (model: SaveFSFolderModel) => requests.post<any>(`/library/fsSaveFolderAjax?Type=${model.type}`, model),
  deleteContentAjax: (contentID: string, officeID: string) => requests.post<void>(`/library/DeleteContentAjax?ContentID=${contentID}&OfficeID=${officeID}`, {}),
  saveContentAjax: (model: ContentModel) => {
    //console.log("inside api agent: saveContentAjax", model);
    return requests.post<any>(`/library/saveContentAjax?OfficeID=${model.officeID}`, model);
  },
  uploadLibraryImageAjax: (formData: any) => {
    return requests.post<any>("/library/uploadLibraryImageAjax", formData);
  },
};

const Diary = {
  getPinnedNotesAjax: (id: string, userId: string, SDNType?: number, month?: number, year?: number) =>
    requests.get<DiaryGridModel>(`/diary/getPinnedNotesAjax?ID=${id}&SDNType=${SDNType}`),

  updateDiaryAjax: (model: DiaryModel) => {
    //console.log("inside api agent: updateDiaryAjax, model: ", model);
    return requests.post<any>("/diary/updateDiaryAjax", model);
  },
  searchContactsOrPropertiesAjax: (search: string, SDNType?: number) =>
    requests.get<any>(`/diary/searchContactsOrPropertiesAjax?SDNType=${SDNType}&Search=${search}`),

  saveDiaryAjax: (data: any) => {
    return requests.post<any>("/diary/saveDiaryAjax", data);
  },
  deleteDiaryAjax: (id: string, userID: string) => requests.post<any>(`/diary/deleteDiaryAjax?ID=${id}&UserID=${userID}`, {}),
  deleteRecurringEventsAjax:(recurringEventID:string)=>requests.post<any>(`/diary/deleteRecurringEventsAjax?RecurringEventID=${recurringEventID}`,{}),
  diariesAjax: (id: string, userID: string, SDNType?: number, month?: number, year?: number, officeId?: string) =>
    requests.get<DiaryGridModel>(`/diary/diariesAjax?ID=${id}&SDNType=${SDNType}&Month=${month}&Year=${year}&userID=${userID}&officeId=${officeId}`),
  timelineDiariesAjax: (id: string, SDNType?: number) => requests.get<DiaryGridModel>(`/diary/timelineDiariesAjax?ID=${id}&SDNType=${SDNType}`),
};

const Inbox = {
  indexAjax: (userID: string, page?: number, view?: number) => requests.get<any>(`/inbox/indexAjax?View=${view}&Page=${page}`),
  selectLeadAjax: (leadID: string) => requests.get<LeadModel>(`/inbox/selectLeadAjax?leadID=${leadID}`),
  markInboxAjax: (itemID: string, userID: string, isRead: boolean) =>
    requests.post<void>(`inbox/markInboxAjax?ItemID=${itemID}&userID=${userID}&IsRead=${isRead}`, {}),
  deleteInboxAjax: (itemID: string, userID: string, type: number) =>
    requests.post<void>(`inbox/deleteInboxAjax?ItemID=${itemID}&userID=${userID}&Type=${type}`, {}),
  reassignAjax: (itemID: string, userID: string, userIDToAssign: string, note: string) =>
    requests.post<void>(`inbox/reassignAjax?ItemID=${itemID}&userID=${userID}&UserIDToAssign=${userIDToAssign}&Note=${note}`, {}),
};

const Import = {
  getImport: () => requests.get<ImportResponseModel>("/import"),
  getOMSToBase: () => requests.get<OMSToBaseResponseModel>("/import/OMSToBase"),
  OMSToBaseFileAjax: (files: any) =>
    requests.post<void>("/import/OMSToBaseFileAjax", {
      files,
    }),
  OMSToBaseFileAjax2: (importID: string, typeID?: number) =>
    requests.post<void>("/import/OMSToBaseFileAjax2", {
      importID,
      typeID,
    }),
  getImportData: () => requests.post<any>(`import/indexAjax`, {}),
  getImportProperties: (importID?: string) => requests.get<ImportPropertiesResponseModel>(`/import/properties?ImportID=${importID}`),
  getImportPropertiesData: (id: string, status?: number) => requests.post(`import/propertiesAjax?ID=${id}&Status=${status}`, {}),
  processImportTaskAjax: (importID: string, officeId: string, taskID?: number) =>
    requests.post(`import/processImportTaskAjax?ID=${importID}&OfficeID=${officeId}&TaskID=${taskID}`, {}),
  deleteImportAjax: (id: string) => requests.post<void>(`/import/deleteImportAjax?ID=${id}`, {}),
  saveProperty: (importID: string, propertyID: string) =>
    requests.get<ImportPropertyModel>(`/import/saveProperty?ImportID=${importID}&PropertyID=${propertyID}`),
  savePropertyAjax: (model: FormData) => {
    //console.log("inside api agent saveImportPropertyAjax", model);
    return requests.postForm<any>("/import/savePropertyAjax", model);
  },
  //savePropertyAjax: (model: any) => requests.post<any>("/import/savePropertyAjax", model),
  deletePropertyAjax: (id: string, importId: string) => requests.post<void>(`/import/deletePropertyAjax?ID=${id}&ImportID=${importId}`, {}),
  importFileAjax: (formData: FormData) => requests.postForm<any>("/import/importFileAjax", formData),
};

const S3Helper = {
  getPresignedUrls: (fileNames: string[], officeSyncId: string) =>
    requests.post<any>(`/s3Helper/GetPresignedUrls?fileNames=${fileNames}&officeSyncId=${officeSyncId}`, {}),
  deleteFilesFromS3: (fileNames: string, propertyID: string, officeSyncId: string) =>
    requests.post<any>(`/s3Helper/DeleteFilesFromS3?fileNames=${fileNames}&propertyID=${propertyID}&officeSyncId=${officeSyncId}`, {}),
  deleteListingMandateFilesFromS3: (fileNames: string, propertyID: string, officeSyncId: string) =>
    requests.post<any>(`/s3Helper/DeleteListingMandateFilesFromS3?fileNames=${fileNames}&propertyID=${propertyID}&officeSyncId=${officeSyncId}`, {}),
};

const Deal = {
  pipelines: () => requests.get<string[]>(`/api/Deal/pipelines`),
  filterData: () => requests.get<any>(`/api/Deal/deals`),
  loopFilterInfoAjax: () => requests.get<any>(`/api/Deal/loopFilterInfoAjax`),
  pipelinesInfoAjax: (
    requestParams: any,
    agentId: string,
    pipelineId: string,
    workspaceAgentId: string,
    workspacePipelineId: string,
    propertyTypes: any,
    areas: any,
    registrationStartFrom: string,
    registrationEndTo: string,
    needUpdate: boolean
  ) => {
    return requests.post("/api/Deal/pipelinesInfoAjax", {
      requestParams,
      agentId,
      pipelineId,
      workspaceAgentId,
      workspacePipelineId,
      propertyTypes,
      areas,
      registrationStartFrom,
      registrationEndTo,
      needUpdate,
    });
  },
  saveDeal: (dealId: string, propertyId: string) => requests.get<any>(`/api/Deal/saveDeal?ID=${dealId}&PropertyID=${propertyId}`),
  dealsbypropertyid: (PropertyID: string) => requests.get<any>(`/api/Deal/dealsbypropertyid?PropertyID=${PropertyID}`),
  dealStageChangeAjax: (dealId: string, stageId: string) => requests.post<any>(`/api/Deal/dealStageChangeAjax?dealId=${dealId}&stageId=${stageId}`, {}),
  saveDealAjax: (model: DealModel) => requests.post<any>(`/api/Deal/saveDealAjax`, model),
  saveTaskAjax: (model: stageTask) => requests.post<any>(`/api/Deal/saveTaskAjax?dealId=${model.dealId}`, model),
  deletedealAjax: (dealId: string, UserID: string) => requests.post<any>(`/api/Deal/deletedealAjax?dealId=${dealId}&UserID=${UserID}`, {}),
  deletePipelineAjax: (pipelineID: string) => requests.post<any>(`/api/Deal/deletePipelineAjax?pipelineId=${pipelineID}`, {}),
  deleteStageAjax: (pipelineID: string, stageID: string) => requests.post<any>(`/api/Deal/deleteStageAjax?stageId=${stageID}&pipelineId=${pipelineID}`, {}),
  saveDealDocumentAjax: (SyncID: string, file: any) => requests.post<any>(`/api/Deal/saveDealDocumentAjax/${SyncID}`, file),
  downloadDealDocumentAjax: (f: string, s: string) => requests.getFile<any>(`api/Deal/downloadDealDocumentAjax?f=${f}&s=${s}`),
  dealDashboardInfoAjax: (WorkspaceAgentId: string, WorkspacePipelineId?: string) =>
    requests.get<any>(`/api/Deal/dealDashboardInfoAjax?WorkspaceAgentId=${WorkspaceAgentId}&WorkspacePipelineId=${WorkspacePipelineId}`),
  dealDynamicAjax: (
    requestParams: any,
    agentId: string,
    pipelineId: string,
    workspaceAgentId: string,
    workspacePipelineId: string,
    propertyTypes: any,
    areas: any,
    registrationStartFrom: string,
    registrationEndTo: string,
    needUpdate: boolean
  ) => {
    return requests.post("/api/Deal/dealDynamicAjax", {
      requestParams,
      agentId,
      pipelineId,
      workspaceAgentId,
      workspacePipelineId,
      propertyTypes,
      areas,
      registrationStartFrom,
      registrationEndTo,
      needUpdate,
    });
  },
  taskDynamicAjax: (
    requestParams: any,
    agentId: string,
    pipelineId: string,
    workspaceAgentId: string,
    workspacePipelineId: string,
    propertyTypes: any,
    areas: any,
    registrationStartFrom: string,
    registrationEndTo: string,
    needUpdate: boolean
  ) => {
    return requests.post("/api/Deal/taskDynamicAjax", {
      requestParams,
      agentId,
      pipelineId,
      workspaceAgentId,
      workspacePipelineId,
      propertyTypes,
      areas,
      registrationStartFrom,
      registrationEndTo,
      needUpdate,
    });
  },
  saveCommissionSplitAjax: (model: split) => requests.post<any>(`/api/Deal/saveCommissionSplitAjax`, model),
  searchPropertiesAjax: (search: string) => requests.get<any>(`/api/Deal/searchPropertiesAjax?Search=${search}`),
  getDefaultPipelinesAjax: () => requests.get<any>(`/api/Deal/getDefaultPipelinesAjax`),
  performImportPipelinesAjax: (pipelineIds: string) => requests.post<any>(`/api/Deal/performImportPipelinesAjax?pipelineIds=${pipelineIds}`, {}),
  saveDealNoteAjax: (dealId: string, note: string, isDelete: boolean = false) => {
    return requests.post<any>(`/api/Deal/saveDealNoteAjax?dealId=${dealId}&note=${note}&isDelete=${isDelete}`, {});
  },
};

const MasterData = {
  getMasterData: () => requests.get<MasterDataModel>(`/masterData/getMasterData`),
};

const PropertyReports = {
  fuzzySearch: (search: string) => requests.get<any>(`/PropertyReports/fuzzySearch?searchQuery=${search}`),
  generateFullPropertyReport: (propertyId: string, streetAddress: string, propertyType: string) =>
    requests.post<any>("/PropertyReports/generateFullPropertyReport", {
      propertyId,
      streetAddress,
      propertyType,
    }),
  getExistingReportsForUser: () => requests.get<any>(`/PropertyReports/getExistingReportsForUser`),
  downloadReport(reportId: string) {
    return requests.getFile<any>(`/PropertyReports/downloadReport?reportId=${reportId}`);
  },
  getUserCreditBalance() {
    return requests.get<any>(`/PropertyReports/getUserCreditBalance`);
  },
  generateOwnerDetails: (propertyId: string, streetAddress: string, propertyType: string) => requests.post<any>("/PropertyReports/generateOwnerDetails", { 
      propertyId,
      streetAddress,
      propertyType,
  }),


};

const HistoricDeals = {
  getHistoricDeals: () => requests.get<any>(`historicDeals/getHistoriclDeals`),
  

};

const apiAgent = {
  Account,
  twoFactorAuth,
  Contact,
  Setting,
  ReportViewer,
  Admin,
  Property,
  Library,
  Home,
  Diary,
  Deal,
  Inbox,
  Import,
  S3Helper,
  MasterData,
  PropertyReports,
  HistoricDeals,
};

export default apiAgent;
