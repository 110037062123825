import React from "react";
import { Redirect, Switch, useHistory } from "react-router";
import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { OMSImageUpload } from "../../app/common/common-components/OMSImageUpload";
import { OMSTabBox } from "../../app/common/common-components/OMSTabBox";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { UserModel } from "../../app/models/UserModel";

// import '../../assets/styles/styles.scss';
// import '../../assets/styles/custom.scss';
// import '../../assets/styles/shared.scss';
import { OMSFavourite } from "../../app/common/common-components/OMSFavourite";
import { OMSNumericBox } from "../../app/common/common-components/OMSComponent";
import { OMSSocialMediaBox } from "../../app/common/common-components/OMSSocialBox";
import { UserIntegrationBox } from "./UserIntegrationBox";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { UserListCountModel } from "../../app/models/UserListCountModel";
//import Personal from "./Personal";
//import Profile from "./Profile";
//import Integrations from "./Integrations";
//import PortalAccess from "./PortalAccess";
import { Link } from "react-router-dom";
import { OMSPurchaseCredit } from "../../app/common/common-components/OMSPurchaseCredit";
import bootbox from "bootbox";
import auth2 from "react-gapi-auth2";
import DatePicker from "react-datepicker";
//import "../../assets/styles/react-datepicker/react-datepicker-custom.css";

import { DatePickerFormikField } from "../../app/common/common-components/DatePickerFormikField";
//import { ConfirmationModalProps } from "../../app/models/ModalControlProps/ConfirmationModalProps";
import ConfirmationModalPopup from "../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { OMSMultiselectDropdown } from "../../app/common/common-components/OMSMultiselectDropdown";
import $ from "jquery";
import moment from "moment";
import { StoreContext } from "../../app/stores/store";
import { withRouter } from "react-router-dom";
import TwoFAComponent from "./TwoFAComponent";

class UserBox extends React.Component {
  state = {
    ActiveTabID: "account",
    UserID: this.props.data.userID,
    Title: this.props.data.title,
    FirstName: this.props.data.firstName,
    LastName: this.props.data.lastName,
    BirthDate: this.getFormattedBirthDate(),
    Gender: this.props.data.gender,
    EmailAddress: this.props.data.emailAddress,
    Password: this.props.data.password,
    Job: this.props.data.job,
    Status: this.props.data.status,
    StatusOld: this.props.data.status,
    Role: this.props.data.role,
    Photo: this.props.data.photo,
    PhotoUrl: this.props.data.photoUrl,
    PhotoUpload: null,
    Profile: this.props.data.profile,
    Credentials: this.props.data.credentials,
    UserIMAP: this.props.data.userIMAP,
    OfficeID: this.props.data.officeID,
    NationalIDNumber: this.props.data.nationalIDNumber,
    Contact: JSON.parse(this.props.data.contact),
    IsReceivesFlexInquiries: this.props.data.isReceivesFlexInquiries,
    IsReceivesListingExpiry: this.props.data.isReceivesListingExpiry,
    CanSendEmail: this.props.data.canSendEmail,
    CanSendSMS: this.props.data.canSendSMS,
    UserPortals: this.props.data.userPortals,
    TransferAgentID: "",
    Credit: this.props.data.credit,
    mdDeleteAgentAction: 1,
    mdIsDisabled: false,
    mdTotalListings: "",
    mdActiveListings: "",
    mdSyncActiveListings: "",
    mdListingCount: false,
    mdTransferAgentID: "",
    progressbar_class: "progress-bar-danger",
    progressbar_width: "0%",
    progressbar_text: "",
    password_isstrong: false,
    ButtonStatus: {
      disabled: false,
      processing_save: false,
      processing_delete: false,
    },
    manageListingsDD: this.props.data.manageListingsDD,
    manageListingsValue: this.props.data.manageListings,
    restrictedPortals: this.props.data.restrictedPortals,
    ConfirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
    TwoFASettings: JSON.parse(this.props.data.twoFASettingsJson),
    // TwoFAQRCodeValues: {},
    // verificationCode: "",
    isPublishToPortals: this.props.data.isPublishToPortals,
  };

  constructor(props) {
    super(props);
    this.purchaseCreditRef = React.createRef();

    var tabContent = [
      { link: "#account", title: "Account" },
      { link: "#personal", title: "Personal" },
      { link: "#profile", title: "Profile" },
      { link: "#email", title: "Integrations" },
    ];

    //console.log("inside constructor, this.props.data.actions: ", this.props.data.actions);

    if (this.props.data.actions.indexOf("select_portal") != -1) {
      tabContent.push({ link: "#portalaccess", title: "Portal & User Access" });
    }

    this.state = {
      ...this.state, // this preserves the existing state variables
      TabContent: tabContent,
    };
    //console.log("inside constructor, this.props.data.twoFASettingsJson: ", this.props.data.twoFASettingsJson);
  }

  isCreate =
    this.props.data.userID == "" || this.props.data.userID == null
      ? true
      : false;

  getFormData = () => {
    var data = new FormData();
    data.append("userID", this.state.UserID);
    data.append("title", this.state.Title);
    data.append("firstName", this.state.FirstName);
    data.append("lastName", this.state.LastName);
    //data.append("publishDate", this.state.PublishDate.toISOString());
    data.append(
      "birthDate",
      this.state.BirthDate ? this.state.BirthDate.toISOString() : ""
    );
    data.append("gender", this.state.Gender);
    data.append("emailAddress", this.state.EmailAddress);
    data.append("password", this.state.Password);
    data.append("job", this.state.Job);
    data.append("status", this.state.Status);
    data.append("role", this.state.Role);
    data.append("photo", this.state.Photo);
    data.append("photoUrl", this.state.PhotoUrl);
    data.append("photoUpload", this.state.PhotoUpload);
    data.append("profile", this.state.Profile);
    data.append("credentials", this.state.Credentials);
    data.append("officeID", this.state.OfficeID);
    data.append("nationalIDNumber", this.state.NationalIDNumber);
    data.append(
      "strIsReceivesFlexInquiries",
      this.state.IsReceivesFlexInquiries
    );
    data.append(
      "strIsReceivesListingExpiry",
      this.state.IsReceivesListingExpiry
    );
    data.append("strCanSendSMS", this.state.CanSendSMS);
    data.append("strCanSendEmail", this.state.CanSendEmail);
    data.append("manageListings", this.state.manageListingsValue);
    data.append("restrictedPortals", this.state.restrictedPortals);
    data.append("contact", JSON.stringify(this.state.Contact));
    data.append("twoFASettingsJSON", JSON.stringify(this.state.TwoFASettings));
    data.append("isPublishToPortals", this.state.isPublishToPortals);

    if (this.integrationRef !== undefined && this.integrationRef !== null) {
      let userImapdata = this.integrationRef.getStateData();
      if (userImapdata !== undefined && userImapdata != null) {
        //console.log("userImapdata: ", userImapdata);
        data.append("userIMAPJson", JSON.stringify(userImapdata));
      }
    }

    //Rohit: Uncomment later

    // if (this.integrationRef !== undefined && this.integrationRef !== null) {
    //   var imapData = this.integrationRef.getStateData();
    //   if (imapData !== undefined && imapData != null) {
    //     data.append("UserIMAP.UserID", imapData.UserID);

    //     if (imapData.EmailIntegrationType == 2) {
    //       data.append("UserIMAP.Username", imapData.Username);
    //       data.append("UserIMAP.Password", imapData.Password);
    //       data.append("UserIMAP.Server", imapData.Server);
    //       data.append("UserIMAP.Port", imapData.Port);
    //     } else {
    //       data.append("UserIMAP.Username", "");
    //       data.append("UserIMAP.Password", "");
    //       data.append("UserIMAP.Server", "");
    //       data.append("UserIMAP.Port", "");
    //     }
    //     data.append("UserIMAP.isEntryExists", imapData.isEntryExists);
    //     data.append("UserIMAP.EmailIntegrationType", imapData.EmailIntegrationType);
    //   }
    // }

    // for (var i = 0; i < this.state.UserPortals.length; i++) {
    //   var mUserPortals = this.state.UserPortals[i];
    //   data.append("UserPortals[" + i + "].PortalID", mUserPortals.PortalID);
    //   data.append("UserPortals[" + i + "].PortalName", mUserPortals.PortalName);
    //   data.append("UserPortals[" + i + "].IsEnabled", mUserPortals.IsEnabled);
    // }

    return data;
  };

  getFormattedBirthDate() {
    // if (this.props.data.UserID == "") {
    //   return new Date();
    // } else {
    const date = this.props.data.birthDate;
    //let format = "DD-MM-yyyy";
    let format = "DD-MM-yyyy";
    if (date) {
      let isoDateString = moment(date, format).toISOString();
      return new Date(isoDateString);
    } else return null;
    //}
  }

  componentDidMount() {
    //   window.$('#BirthDate').datetimepicker({
    //     format: 'DD-MM-YYYY',
    //     icons: {
    //       time: "fa fa-clock-o",
    //       date: "fa fa-calendar",
    //       up: "fa fa-arrow-up",
    //       down: "fa fa-arrow-down",
    //       previous: "fa fa-arrow-left",
    //       next: "fa fa-arrow-right",
    //       today: 'fa fa-screenshot',
    //       clear: 'fa fa-trash',
    //       close: 'fa fa-remove'
    //     }
    //   });

    //   $('#BirthDate').on('dp.change', function (e) {
    //     var dateString = window.$('#BirthDate').val();
    //     this.setState({ BirthDate: dateString });
    //   }.bind(this));

    this.CheckPasswordStrength(null);
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.ConfirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ ConfirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ ConfirmationModalProps: varConfirmationModalProps });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleBirthDateChange = (e) => {
    this.setState({
      BirthDate: e.target.value,
    });
  };

  handleStatusChanged = (e) => {
    var status = 1;
    if (this.state.Status == 1) status = 2;
    this.setState({
      Status: status,
    });
  };

  handleGenderChange = (e) => {
    var gender = 1;
    if (e.target.value == "on" && e.target.id == "rbtnMale") {
      gender = 2;
    }

    this.setState({
      Gender: gender,
    });
  };

  handleOfficeIDChange = (e) => {
    this.setState({
      OfficeID: e.newValue,
    });
  };

  handleRoleChange = (e) => {
    this.setState({
      Role: e.newValue,
    });
  };

  handleTwoFactorChange = (name, value) => {
    var TwoFASettings =
      typeof this.state.TwoFASettings === "string"
        ? JSON.parse(this.state.TwoFASettings)
        : this.state.TwoFASettings || {};
    TwoFASettings[name] = value;
    this.setState(
      {
        TwoFASettings: TwoFASettings,
      },
      () => {
        //console.log("TwoFASettings after change", this.state.TwoFASettings);
      }
    );
  };

  handleContactChange = (name, value) => {
    var Contact = this.state.Contact;
    Contact[name] = value;
    this.setState({
      Contact: Contact,
    });
  };

  handleDialingCodeChange = (e) => {
    this.handleContactChange("phone_dialing_code", e.newValue);
  };

  handlePhoneChange = (name, value) => {
    this.handleContactChange("phone", value);
  };

  handleResidentialAddressChange = (e) => {
    this.handleContactChange(e.target.name, e.target.value);
  };

  OnCropSubmit = (d) => {
    this.setState({ PhotoUpload: d });
  };

  onCancelClick = (e) => {
    this.props.history.push("/Settings/Users");
  };

  onHistoryClick = (e) => {
    var location = `/settings/auditTrail?ModuleID=1&ObjectID=${this.props.userId}`;
    this.props.history.push(location);
  };

  onLogInAsClick = (e) => {
    const { userStore } = this.context;
    //console.log("onLogInAsClick, this.state.UserID: " + this.state.UserID);
    userStore
      .loginAs(this.state.UserID)
      .catch((error) => toast.error(error.response.data));
  };

  onDeleteClick = (e) => {
    this.setState({
      mdDeleteAgentAction: 1,
      mdIsDisabled: false,
      mdTotalListings: "",
      mdActiveListings: "",
      mdSyncActiveListings: "",
      mdListingCount: false,
      mdTransferAgentID: "",
    });
    window.$("#deleteUserModal").modal("show");
    this.loadUsersListingCount();
  };

  onInactiveSyncDeleteClick = (e) => {
    this.setState({
      mdDeleteAgentAction: 1,
      mdIsDisabled: true,
      mdTotalListings: "",
      mdActiveListings: "",
      mdSyncActiveListings: "",
      mdListingCount: false,
      mdTransferAgentID: "",
    });
    window.$("#deleteUserModal").modal("show");
    this.loadUsersListingCount();
  };

  handleDeleteAgentActionChange = (e) => {
    var mdDeleteAgentAction = 1;
    if (e.target.value == "on" && e.target.id == "rbtnDeleteListings") {
      mdDeleteAgentAction = 2;
    }

    this.setState({
      mdDeleteAgentAction: mdDeleteAgentAction,
    });
  };

  onTransferListingClick = (e) => {
    this.setState({ TransferAgentID: "" });
    window.$("#transferModal").modal("show");
  };

  doValidate = () => {
    var RegEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    var failed = false;

    if (!this.state.FirstName.trim()) {
      //window.$.toaster("Please enter first name");
      toast.info("Please enter first name");
      //isValidated = false;
      failed = true;
    }

    if (!this.state.LastName.trim()) {
      toast.info("Please enter last name");
      failed = true;
    }

    if (
      !this.state.EmailAddress.trim() ||
      RegEmail.test(this.state.EmailAddress) == false
    ) {
      toast.info("Please enter valid email address");
      failed = true;
    }

    if (!this.state.Password.trim()) {
      toast.info("Please enter password");
      failed = true;
    }

    if (!this.state.password_isstrong) {
      toast.info(
        "Please increase password strength. Use a combination of lowercase, uppercase, numerical and special characters"
      );
      failed = true;
    }

    /* if (!this.state.BirthDate) {
      console.log("Please enter birthdate");
      //toast.info("Please enter birthdate");
      failed = true;
    } */

    return !failed;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.doValidate()) return;

    if (
      this.state.UserID != "" &&
      this.state.Status == 2 &&
      this.state.StatusOld != 2
    ) {
      this.setState({
        mdDeleteAgentAction: 1,
        mdIsDisabled: true,
        mdTotalListings: "",
        mdActiveListings: "",
        mdSyncActiveListings: "",
        mdListingCount: false,
        mdTransferAgentID: "",
      });
      window.$("#deleteUserModal").modal("show");
      this.loadUsersListingCount();
      return;
    }

    var data = this.getFormData();
    let formData = Object.fromEntries(data.entries());
    formData.manageListings = this.state.manageListingsValue;
    formData.restrictedPortals = this.state.restrictedPortals;
    //console.log("before apiAgent submit, formData=", formData);

    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: true,
        processing_delete: false,
      },
    });

    apiAgent.Setting.saveUserAjax(formData)
      .then((response) => {
        toast.success(response);

        this.props.history.push("/Settings/Users");
      })

      .catch((error) => {
        toast.error(error.response.data);
        //toast.info("Unsuccessful")
      })
      .finally(() => {
        this.setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
      });
  };

  handleProcessClick = (e) => {
    var AgentID = this.state.TransferAgentID;
    if (AgentID != "") {
      // bootbox.confirm({
      //   title: "Transfer agent?",
      //   message: "Do you want to transfer listings now? This cannot be undone.",
      //   buttons: {
      //     cancel: {
      //       label: '<i class="fa fa-times"></i> Cancel',
      //     },
      //     confirm: {
      //       label: '<i class="fa fa-check"></i> Confirm',
      //     },
      //   },
      //   callback: function (result) {
      //     if (result) {
      //       window.$("#transferModal").modal("hide");
      //       this.executeProcessClick();
      //     }
      //   }.bind(this),
      // });

      let title = "Transfer agent?";
      let body = "Do you want to transfer listings now? This cannot be undone.";
      let callback = this.executeProcessClick;
      this.showPopupAndDeleteOnConfirmation(title, body, callback);
    }
  };

  executeProcessClick = () => {
    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: false,
        processing_delete: true,
      },
    });

    //console.log("before apiAgent transferUserAjax, this.state.UserID=", this.state.UserID, ", this.state.TransferAgentID=", this.state.TransferAgentID);

    //var data = new FormData();
    // data.append("FromAgentID", this.state.UserID);
    // data.append("ToAgentID", this.state.TransferAgentID);

    apiAgent.Setting.transferUserAjax(
      this.state.UserID,
      this.state.TransferAgentID
    )
      .then((response) => {
        toast.success(response);
      })
      .catch((error) => toast.error(error.response.data))
      .finally(() => {
        this.setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
        // setButtonStatus({
        //   disabled: false,
        //   processing_save: false,
        //   processing_delete: false,
      });

    // var xhr = new XMLHttpRequest();
    // xhr.open("post", "/Settings/TransferUserAjax", true);
    // xhr.onload = function () {
    //   console.log(xhr.responseText);
    //   this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
    //   var data = JSON.parse(xhr.responseText);
    //   if (data.status == "success") {
    //     toast.success(data.message, "Success", "success");
    //   } else {
    //     toast.error(data.message, "Fail", "danger");
    //   }
    // }.bind(this);
    // xhr.send(data);
  };

  handleDDChange = (e) => {
    this.setState({ [e.id]: e.newValue });
  };

  CheckPasswordStrength = (e) => {
    var password = document.getElementById("Password").value;
    var passed = -1;
    if (password) {
      var regex = new Array();
      regex.push("[A-Z]"); //Uppercase Alphabet.
      regex.push("[a-z]"); //Lowercase Alphabet.
      regex.push("[0-9]"); //Digit.
      regex.push("[$@$!%*#?&]"); //Special Character.

      passed = 0;
      //Validate for each Regular Expression.
      for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
          passed++;
        }
      }

      //Validate for length of Password.
      if (passed > 2 && password.length > 6) {
        passed++;
      }

      if (password.length < 6) {
        if (passed > 2) passed--;
        else passed = 0;
      }
    }

    var progressbar_class = "";
    var progressbar_width = "";
    var progressbar_text = "";
    var password_isstrong = false;
    switch (passed) {
      case -1:
        progressbar_class = "progress-bar-danger";
        progressbar_width = "0%";
        progressbar_text = "";
        break;
      case 0:
      case 1:
        progressbar_class = "progress-bar-danger";
        progressbar_width = "25%";
        progressbar_text = "Weak";
        break;
      case 2:
        progressbar_class = "progress-bar-warning";
        progressbar_width = "50%";
        progressbar_text = "Medium";
        break;
      case 3:
      case 4:
        progressbar_class = "progress-bar-info";
        progressbar_width = "75%";
        progressbar_text = "Strong";
        password_isstrong = true;
        break;
      case 5:
        progressbar_class = "progress-bar-success";
        progressbar_width = "100%";
        progressbar_text = "Very Strong";
        password_isstrong = true;
        break;
    }
    this.setState({
      password_isstrong: password_isstrong,
      progressbar_class: progressbar_class,
      progressbar_width: progressbar_width,
      progressbar_text: progressbar_text,
    });
  };

  handleCheckboxChangedP = (e) => {
    var name = e.target.name;
    if (name == "IsReceivesFlexInquiries")
      this.setState({
        IsReceivesFlexInquiries: !this.state.IsReceivesFlexInquiries,
      });
    else if (name == "IsReceivesListingExpiry")
      this.setState({
        IsReceivesListingExpiry: !this.state.IsReceivesListingExpiry,
      });
    else if (name == "CanSendSMS")
      this.setState({ CanSendSMS: !this.state.CanSendSMS });
    else if (name == "CanSendEmail")
      this.setState({ CanSendEmail: !this.state.CanSendEmail });
  };

  onPurchaseClick = (e) => {
    this.purchaseCreditRef.current.onOpen(this.state.UserID);
  };

  handleCheckboxChangedPortalAccess = async (event, idx) => {
    let updatedPortals = this.state.UserPortals;
    let updatedRestrictedPortals = this.state.restrictedPortals;
    let element = {
      portalID: updatedPortals[idx].portalID,
      portalName: updatedPortals[idx].portalName,
      isEnabled: event.target.checked,
    };
    await updatedPortals.splice(idx, 1);
    await updatedPortals.splice(idx, 0, element);
    if (updatedPortals[idx].isEnabled) {
      if (updatedRestrictedPortals.indexOf(updatedPortals[idx].portalID) >= 0) {
        updatedRestrictedPortals.splice(
          updatedRestrictedPortals.indexOf(updatedPortals[idx].portalID),
          1
        );
      } else {
      }
    } else if (!updatedPortals[idx].isEnabled) {
      if (updatedRestrictedPortals.indexOf(updatedPortals[idx].portalID) >= 0) {
        //console.log(updatedRestrictedPortals.indexOf(updatedPortals[idx].portalID));
        //console.log("first");
      } else {
        updatedRestrictedPortals.push(updatedPortals[idx].portalID);
      }
    }
    //console.log(updatedPortals);
    //console.log(updatedRestrictedPortals);
    this.setState({
      UserPortals: updatedPortals,
      restrictedPortals: updatedRestrictedPortals,
    });
  };

  handleDeleteAgentProcessClick = (e) => {
    if (
      this.state.mdDeleteAgentAction == 1 &&
      this.state.mdTransferAgentID == ""
    ) {
      toast.info("Please select agent to transfer listings");
      return;
    }

    /* bootbox.confirm({
      title: "Delete/Disable agent?",
      message: "Do you want to delete/disable agent now? This cannot be undone.",
      buttons: {
        cancel: {
          label: '<i class="fa fa-times"></i> Cancel',
        },
        confirm: {
          label: '<i class="fa fa-check"></i> Confirm',
        },
      },
      callback: function (result) {
        if (result) {
          window.$("#deleteUserModal").modal("hide");
          this.executeDeleteAgentProcessClick();
        }
      }.bind(this),
    }); */
    let title = "Delete/Disable agent?";
    let body =
      "Do you want to delete/disable agent now? This cannot be undone.";
    let callback = this.executeDeleteAgentProcessClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  executeDeleteAgentProcessClick = () => {
    window.$("#deleteUserModal").modal("hide");
    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: false,
        processing_delete: true,
      },
    });

    apiAgent.Setting.deleteUserAjax(
      this.state.UserID,
      this.state.mdIsDisabled,
      this.state.mdDeleteAgentAction,
      this.state.mdTransferAgentID
    )
      .then((response) => {
        toast.success(response);
        this.props.history.push("/Settings/Users");
      })
      .catch((error) => toast.error(error.response.data))
      .finally(() => {
        this.setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
      });
  };

  loadUsersListingCount = () => {
    /* var xhr = new XMLHttpRequest();
    xhr.open("get", "/Settings/GetUsersListingsCount?UserID=" + this.state.UserID, true);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      this.setState({ mdTotalListings: data.all, mdActiveListings: data.active, mdSyncActiveListings: data.syncActive, mdListingCount: true });
    }.bind(this);
    xhr.send(); */
    apiAgent.Setting.getUsersListingsCount(this.state.UserID)
      .then((response) => {
        this.setState({
          mdTotalListings: response.all,
          mdActiveListings: response.active,
          mdSyncActiveListings: response.syncActive,
          mdListingCount: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setActiveTabID = (tabID) => {
    this.setState({ ActiveTabID: tabID });
  };

  handleRoleDD = (selected) => {
    this.setState({ manageListingsValue: selected });
  };
  handlePublishToPortalChanged = (e) => {
    if (this.state.isPublishToPortals) {
      this.setState({
        isPublishToPortals: false,
      });
    } else {
      this.setState({
        isPublishToPortals: true,
      });
    }
  };

  render() {
    //console.log(this.props.data.UserPortals);
    //   var tabContent = [
    //     { link: '#account', title: 'Account' },
    //     { link: '#personal', title: 'Personal' },
    //     { link: '#profile', title: 'Profile' },
    //     { link: '#email', title: 'Integrations' }
    //   ];

    //   if (this.props.data.actions.indexOf('select_portal') != -1) {
    //     tabContent.push({ link: '#portalaccess', title: 'Portal Access' });
    //   }

    return (
      <div>
        <ConfirmationModalPopup {...this.state.ConfirmationModalProps} />
        <form className="SaveUserForm" onSubmit={this.handleSubmit}>
          <div className="col-sm-12">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    <OMSFavourite favourite={this.props.data.favourite} />{" "}
                    &nbsp;
                    <label>
                      {this.isCreate
                        ? "CREATE"
                        : this.state.Title +
                          ". " +
                          this.state.FirstName +
                          " " +
                          this.state.LastName}
                    </label>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="col-md-12">
                  <OMSTabBox
                    id="userTabs"
                    value={this.state.TabContent}
                    setActiveTabId={this.setActiveTabID}
                  ></OMSTabBox>
                  <div className="tab-content">
                    <div
                      id="account"
                      className={
                        this.state.ActiveTabID === "account"
                          ? "tab-pane fade in active"
                          : "tab-pane fade"
                      }
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="EmailAddress">Email address</label>
                            <input
                              type="email"
                              className="form-control required-control"
                              id="EmailAddress"
                              name="EmailAddress"
                              value={this.state.EmailAddress}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="Password">Password</label>
                            <input
                              type="password"
                              className="form-control required-control"
                              id="Password"
                              name="Password"
                              value={this.state.Password}
                              onChange={this.handleChange}
                              onKeyUp={this.CheckPasswordStrength}
                            />
                            <div className="progress" style={{ marginTop: 5 }}>
                              <div
                                className={
                                  "progress-bar " + this.state.progressbar_class
                                }
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: this.state.progressbar_width }}
                              >
                                {this.state.progressbar_text}
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="Role">Role</label>
                            {this.props.data.actions.indexOf("role-read") <
                            0 ? (
                              <OMSDropdown
                                id="Role"
                                options={this.props.data.roleDD}
                                valueField="value"
                                labelField="text"
                                value={this.state.Role}
                                onChange={this.handleRoleChange}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                value={this.props.data.roleDD[0].text}
                                readOnly="readOnly"
                              />
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="OfficeID">Office</label>
                            {this.props.data.actions.indexOf("office-read") <
                            0 ? (
                              <OMSDropdown
                                id="OfficeID"
                                options={this.props.data.officeDD}
                                valueField="value"
                                labelField="text"
                                value={this.state.OfficeID}
                                onChange={this.handleOfficeIDChange}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                value={this.props.data.officeDD[0].text}
                                readOnly="readOnly"
                              />
                            )}
                          </div>

                          <TwoFAComponent
                            data={this.props.data}
                            handleTwoFactorChange={this.handleTwoFactorChange}
                            TwoFASettings={this.state.TwoFASettings}
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <OMSImageUpload
                              src={this.state.PhotoUrl}
                              width={160}
                              height={200}
                              compressor={true}
                              onCropSubmit={this.OnCropSubmit}
                            />
                          </div>
                          <div className="form-group">
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="Status"
                                name="Status"
                                checked={this.state.Status === 1}
                                onChange={this.handleStatusChanged}
                              />
                              <div className="slider round" />
                              <span
                                style={{
                                  marginLeft: 70,
                                  display: "block",
                                  width: 120,
                                  marginTop: 7,
                                }}
                              >
                                Status:{" "}
                                {this.state.Status === 1
                                  ? "Active"
                                  : "Inactive"}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="personal"
                      className={
                        this.state.ActiveTabID === "personal"
                          ? "tab-pane fade in active"
                          : "tab-pane fade"
                      }
                    >
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <select
                              id="Title"
                              name="Title"
                              className="form-control"
                              style={{ maxWidth: "80px" }}
                              value={this.state.Title}
                              onChange={this.handleChange}
                            >
                              <option value="Mr">Mr.</option>
                              <option value="Ms">Ms.</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="FirstName">First name</label>
                            <input
                              id="FirstName"
                              name="FirstName"
                              type="text"
                              className="form-control required-control"
                              value={this.state.FirstName}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="LastName">Last name</label>
                            <input
                              id="LastName"
                              name="LastName"
                              type="text"
                              className="form-control required-control"
                              value={this.state.LastName}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="NationalIDNumber">
                              National Identification Number
                            </label>
                            <input
                              id="NationalIDNumber"
                              name="NationalIDNumber"
                              type="text"
                              className="form-control"
                              value={this.state.NationalIDNumber}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="BirthDate">Date of birth</label>

                            <div className="form-inline">
                              <DatePicker
                                selected={this.state.BirthDate}
                                onChange={(date) =>
                                  this.setState({ BirthDate: date })
                                }
                                dateFormat="dd-MM-yyyy"
                                className="form-control datepickerx"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                isClearable={true}
                              />
                            </div>
                          </div>
                          <div
                            id="picker-container"
                            style={{ position: "relative" }}
                          ></div>
                          <div className="form-group">
                            <label htmlFor="Gender">Gender</label>
                            <div
                              className="form-inline"
                              style={{ marginBottom: 10 }}
                            >
                              <label className="radio-inline">
                                <input
                                  type="radio"
                                  id="rbtnMale"
                                  name="rbtnGender"
                                  checked={this.state.Gender === 2}
                                  onChange={this.handleGenderChange}
                                />
                                Male
                              </label>
                              <label className="radio-inline">
                                <input
                                  type="radio"
                                  id="rbtnFemale"
                                  name="rbtnGender"
                                  checked={this.state.Gender === 1}
                                  onChange={this.handleGenderChange}
                                />
                                Female
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="ResidentialAddress">
                              Residential address
                            </label>
                            <textarea
                              rows={2}
                              className="form-control"
                              id="residential_address"
                              name="residential_address"
                              value={this.state.Contact.residential_address}
                              onChange={this.handleResidentialAddressChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="profile"
                      className={
                        this.state.ActiveTabID === "profile"
                          ? "tab-pane fade in active"
                          : "tab-pane fade"
                      }
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="Profile">Public profile</label>
                            <textarea
                              rows={3}
                              className="form-control"
                              id="Profile"
                              name="Profile"
                              value={this.state.Profile}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="Credentials">Credentials</label>
                            <textarea
                              rows={2}
                              className="form-control"
                              id="Credentials"
                              name="Credentials"
                              value={this.state.Credentials}
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="Job">Job title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="Job"
                              name="Job"
                              value={this.state.Job}
                              onChange={this.handleChange}
                            />
                          </div>

                          <div className="form-group cellphones">
                            <label htmlFor="phone">Mobile number</label>
                            <br />
                            <div className="dialingcode">
                              <OMSDropdown
                                id="DialingCode"
                                options={this.props.data.dialingCodeDD}
                                valueField="value"
                                labelField="text"
                                value={this.state.Contact.phone_dialing_code}
                                onChange={this.handleDialingCodeChange}
                              />
                            </div>
                            <div className="phonenumber">
                              <OMSNumericBox
                                id="Phone"
                                name="Phone"
                                maxLength={10}
                                value={this.state.Contact.phone}
                                onChange={this.handlePhoneChange}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="social">Social media handles</label>
                            <div className="form-inline">
                              <OMSSocialMediaBox
                                id="socialBoxItem"
                                name="socialBoxItem"
                                value={this.state.Contact}
                                onChange={this.handleContactChange}
                              ></OMSSocialMediaBox>
                              {/*
                                <OMSSocialBox id="facebook" name="facebook" ui_icon="fa-facebook-square" ui_description="Facebook handle" value={this.state.Contact.facebook} onChange={this.handleContactChange} ></OMSSocialBox>
                                <OMSSocialBox id="twitter" name="twitter" ui_icon="fa-twitter-square" ui_description="Twitter handle" value={this.state.Contact.twitter} onChange={this.handleContactChange}></OMSSocialBox>
                                <OMSSocialBox id="linkedin" name="linkedin" ui_icon="fa-linkedin-square" ui_description="Linkedin handle" value={this.state.Contact.linkedin} onChange={this.handleContactChange}></OMSSocialBox>
                                <OMSSocialBox id="instagram" name="instagram" ui_icon="fa-instagram" ui_description="Instagram handle" value={this.state.Contact.instagram} onChange={this.handleContactChange}></OMSSocialBox>
                              */}
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="isPublishToPortal"
                                name="isPublishToPortal"
                                checked={this.state.isPublishToPortals}
                                onChange={this.handlePublishToPortalChanged}
                              />
                              <div className="slider round" />
                              <span
                                style={{
                                  marginLeft: 70,
                                  display: "block",
                                  width: 120,
                                  marginTop: 7,
                                }}
                              >
                                Publish to portal
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="email"
                      className={
                        this.state.ActiveTabID === "email"
                          ? "tab-pane fade in active"
                          : "tab-pane fade"
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            {this.props.data.admin_access ? (
                              <span>
                                <input
                                  type="checkbox"
                                  id="IsReceivesFlexInquiries"
                                  name="IsReceivesFlexInquiries"
                                  checked={this.state.IsReceivesFlexInquiries}
                                  onChange={this.handleCheckboxChangedP}
                                />{" "}
                                Receives flex website general enquiries{" "}
                                <a
                                  href="https://help.entegral.net/en/articles/5147614-can-general-flex-website-enquiries-go-to-several-users-at-once"
                                  target="_blank"
                                >
                                  <i
                                    className={
                                      "fa fa-regular fa fa-question-circle-o"
                                    }
                                    style={{ color: "black" }}
                                  ></i>
                                </a>
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group">
                            {this.props.data.admin_access ? (
                              <span>
                                <input
                                  type="checkbox"
                                  id="IsReceivesListingExpiry"
                                  name="IsReceivesListingExpiry"
                                  checked={this.state.IsReceivesListingExpiry}
                                  onChange={this.handleCheckboxChangedP}
                                />{" "}
                                Receives all listing expiry notifications{" "}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <span>
                              <input
                                type="checkbox"
                                id="CanSendSMS"
                                name="CanSendSMS"
                                disabled={
                                  this.props.data.admin_access ? "" : "disabled"
                                }
                                checked={this.state.CanSendSMS}
                                onChange={this.handleCheckboxChangedP}
                              />{" "}
                              Can use SMS send &amp; receive{" "}
                            </span>
                          </div>
                          <div className="form-group">
                            <span>
                              <input
                                type="checkbox"
                                id="CanSendEmail"
                                name="CanSendEmail"
                                disabled={
                                  this.props.data.admin_access ? "" : "disabled"
                                }
                                checked={this.state.CanSendEmail}
                                onChange={this.handleCheckboxChangedP}
                              />{" "}
                              Can use Email send &amp; receive{" "}
                            </span>
                          </div>
                          <div className="form-group">
                            <div style={{ marginLeft: 10 }}>
                              <span id="credit_message">
                                Credits available: <b>{this.state.Credit}</b>
                              </span>
                              <a
                                href="javascript:void(0);"
                                style={{ fontSize: 16, marginLeft: 10 }}
                                onClick={this.onPurchaseClick}
                              >
                                purchase
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <UserIntegrationBox
                        ref={(el) => {
                          this.integrationRef = el;
                        }}
                        super_access={this.props.data.super_access}
                        UserIMAP={this.state.UserIMAP}
                      />
                    </div>
                    <div
                      id="portalaccess"
                      className={
                        this.state.ActiveTabID === "portalaccess"
                          ? "tab-pane fade in active"
                          : "tab-pane fade"
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group manageListings">
                            <br />
                            <span>Portal settings</span>
                            <br />
                          </div>
                        </div>
                      </div>
                      {this.state.UserPortals.map((item, index) => {
                        return (
                          <div className="row" key={item.PortalID}>
                            <div className="col-md-12">
                              <div className="form-group">
                                {this.props.data.admin_access ? (
                                  <span>
                                    <input
                                      type="checkbox"
                                      id={"chk_" + item.portalID}
                                      name={"chk_" + item.portalID}
                                      checked={item.isEnabled}
                                      onChange={(e) =>
                                        this.handleCheckboxChangedPortalAccess(
                                          e,
                                          index
                                        )
                                      }
                                    />{" "}
                                    {item.portalName}
                                  </span>
                                ) : (
                                  <span>
                                    <input
                                      type="checkbox"
                                      readOnly={true}
                                      id={"chk_" + item.portalID}
                                      name={"chk_" + item.portalID}
                                      checked={item.isEnabled}
                                    />{" "}
                                    {item.portalName}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="row">
                        <div className="col-md-6 manageListings">
                          <label htmlFor="">
                            Manage listings, contacts and deals for
                          </label>
                          <br />
                          <OMSMultiselectDropdown
                            id="manageListings"
                            name="Choose some options..."
                            options={this.state.manageListingsDD}
                            valueField="value"
                            labelField="text"
                            value={this.state.manageListingsValue}
                            onChange={(e) => this.handleRoleDD(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    {this.isCreate == false ? (
                      <div className="pull-left">
                        <div className="dropup">
                          <button
                            className="btn btn-outline dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                          >
                            More..
                            <span className="caret" />
                          </button>
                          <ul className="dropdown-menu">
                            {this.props.data.actions.indexOf("loginas") <
                            0 ? null : (
                              <li>
                                <OMSButtonStyleL
                                  style={{ marginRight: 10 }}
                                  type="button"
                                  ui_icon="fa-user-o"
                                  ui_type="cancel"
                                  ui_text="Log in as"
                                  ui_processing_text=""
                                  processing={false}
                                  disabled={this.state.ButtonStatus.disabled}
                                  onClick={this.onLogInAsClick}
                                />
                              </li>
                            )}
                            {this.props.data.actions.indexOf("history") <
                            0 ? null : (
                              <li>
                                <OMSButtonStyleL
                                  style={{ marginRight: 10 }}
                                  type="button"
                                  ui_icon="fa-history"
                                  ui_type="cancel"
                                  ui_text="History"
                                  ui_processing_text=""
                                  processing={false}
                                  disabled={this.state.ButtonStatus.disabled}
                                  onClick={this.onHistoryClick}
                                />
                              </li>
                            )}
                            {this.props.data.actions.indexOf("delete") <
                            0 ? null : (
                              <li>
                                <OMSButtonStyleL
                                  style={{ marginRight: 10 }}
                                  type="button"
                                  ui_icon="fa-trash-o"
                                  ui_type="cancel"
                                  ui_text="Delete user"
                                  ui_processing_text="Deleting.."
                                  processing={
                                    this.state.ButtonStatus.processing_delete
                                  }
                                  disabled={this.state.ButtonStatus.disabled}
                                  onClick={this.onDeleteClick}
                                />
                              </li>
                            )}
                            {this.props.data.actions.indexOf("loginas") <
                            0 ? null : this.state.StatusOld == 2 ? (
                              <li>
                                <OMSButtonStyleL
                                  style={{ marginRight: 10 }}
                                  type="button"
                                  ui_icon="fa-trash-o"
                                  ui_type="cancel"
                                  ui_text="Inactive user(Sync listings)"
                                  ui_processing_text="Inactive user.."
                                  processing={
                                    this.state.ButtonStatus.processing_delete
                                  }
                                  disabled={this.state.ButtonStatus.disabled}
                                  onClick={this.onInactiveSyncDeleteClick}
                                />
                              </li>
                            ) : null}
                            {this.props.data.actions.indexOf("transfer") <
                            0 ? null : (
                              <li>
                                <OMSButtonStyleL
                                  style={{ marginRight: 10 }}
                                  type="button"
                                  ui_icon="fa-refresh"
                                  ui_type="cancel"
                                  ui_text="Transfer listings"
                                  ui_processing_text=""
                                  processing={false}
                                  disabled={this.state.ButtonStatus.disabled}
                                  onClick={this.onTransferListingClick}
                                />
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                    <div className="pull-right">
                      <OMSButtonStyleL
                        style={{ marginRight: 10 }}
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={this.state.ButtonStatus.disabled}
                        onClick={this.onCancelClick}
                      />
                      {this.props.data.actions.indexOf("save") < 0 ? null : (
                        <OMSButtonStyleL
                          type="submit"
                          ui_icon="fa-check"
                          ui_type="save"
                          ui_text="Save"
                          ui_processing_text="Saving.."
                          processing={this.state.ButtonStatus.processing_save}
                          disabled={this.state.ButtonStatus.disabled}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <OMSPurchaseCredit ref={this.purchaseCreditRef} ID="SaveUser" />
        <div
          className="modal fade"
          id="transferModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="transferModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <h4 className="modal-title text-center" id="transferModalLabel">
                  Transfer Listings
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group col-md-12">
                    <span>
                      Note: Transfer listing to another agent, It can't be
                      redone
                    </span>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="TransferAgentID">
                      Transfer Listing To:
                    </label>
                    <OMSDropdown
                      id="TransferAgentID"
                      options={this.props.data.transferAgentList}
                      valueField="value"
                      labelField="text"
                      value={this.state.TransferAgentID}
                      onChange={this.handleDDChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={this.handleProcessClick}
                    className="btn btn-primary"
                  >
                    Process
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteUserModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="deleteUserModal"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <h4 className="modal-title text-center" id="deleteUserModal">
                  Delete or Disable Agent
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group col-md-12">
                    <span>
                      Agent to delete/disable:-{" "}
                      <b>{this.state.FirstName + " " + this.state.LastName}</b>
                    </span>
                    <span
                      style={{
                        display: "block",
                        marginTop: 8,
                        marginBottom: 2,
                      }}
                    >
                      Total listings:-{" "}
                      {this.state.mdListingCount == false ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        <b>{this.state.mdTotalListings}</b>
                      )}
                    </span>
                    <span
                      style={{
                        display: "block",
                        marginTop: 8,
                        marginBottom: 2,
                      }}
                    >
                      Active listings:-{" "}
                      {this.state.mdListingCount == false ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        <b>{this.state.mdActiveListings}</b>
                      )}
                    </span>
                    <span
                      style={{
                        display: "block",
                        marginTop: 8,
                        marginBottom: 5,
                      }}
                    >
                      Active listings(Sync):-{" "}
                      {this.state.mdListingCount == false ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        <b>{this.state.mdSyncActiveListings}</b>
                      )}
                    </span>
                    <span>
                      Above listings for agent will be removed/transfered
                    </span>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="Action">Action</label>
                    <div className="form-inline" style={{ marginBottom: 10 }}>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          id="rbtnTransferListings"
                          name="DeleteAgentAction"
                          checked={this.state.mdDeleteAgentAction === 1}
                          onChange={this.handleDeleteAgentActionChange}
                        />
                        Transfer listings (Recommended)
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          id="rbtnDeleteListings"
                          name="DeleteAgentAction"
                          checked={this.state.mdDeleteAgentAction === 2}
                          onChange={this.handleDeleteAgentActionChange}
                        />
                        Remove/Delete listings
                      </label>
                    </div>
                    {this.state.mdDeleteAgentAction != 2 ? (
                      <div className="form-group col-md-12">
                        <label htmlFor="mdTransferAgentID">
                          Transfer Listings To:
                        </label>
                        <OMSDropdown
                          id="mdTransferAgentID"
                          options={this.props.data.transferAgentList}
                          valueField="value"
                          labelField="text"
                          value={this.state.mdTransferAgentID}
                          onChange={this.handleDDChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      onClick={this.handleDeleteAgentProcessClick}
                      className="btn btn-primary"
                    >
                      Process
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
UserBox.contextType = StoreContext;
//export default UserBox;
export default withRouter(UserBox);
