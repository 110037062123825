import React from "react";
import { OMSFavourite } from "../../app/common/common-components/OMSFavourite";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";
import { AuditTrailBox } from "../contact/AuditTrailBox";
import { OverviewStepView } from "./SavePropertyTabs/OverviewStepView";
import { DiaryStepView } from "./SavePropertyTabs/DiaryStepView";
import { LocationStepView } from "./SavePropertyTabs/LocationStepView";
import { FeaturesStepView } from "./SavePropertyTabs/FeaturesStepView";
import { DescriptionStepView } from "./SavePropertyTabs/DescriptionStepView";
import { MediaStepView } from "./SavePropertyTabs/MediaStepView";
import { MandateStepView } from "./SavePropertyTabs/MandateStepView";
import { ContactsStepView } from "./SavePropertyTabs/ContactsStepView";
import { MarketingStepView } from "./SavePropertyTabs/MarketingStepView";
import ReportStepView from "./SavePropertyTabs/ReportStepView";
import ConfirmationModalPopup from "../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import $ from "jquery";
import bootbox from "bootbox";
import { withRouter } from "react-router-dom";

class ListingBox extends React.Component {


  handleIsExternalListingChange = (newValue, callback) => {
    this.setState({ IsExternalListing: newValue }, () => {
      callback();
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      PropertyID: props.data?.propertyID ?? "",
      UserID: props.data?.userID ?? "",
      OfficeID: props.data?.officeID ?? "",
      OfficeSyncID: props.data?.officeSyncID ?? "",
      WebRef: props.data?.webRef ?? "",
      IsUpdate: props.data?.isUpdate ?? false,
      Step: props.data?.step ?? 1,
      CompletedStep: props.data?.step ?? 1,
      PropertyType: props.data?.features?.type ?? "",
      SaleType: props.data?.mandate?.saleType ?? "",
      Status: props.data?.mandate?.status ?? "",
      Mandate_StartDate: props.data?.mandate?.startDate ?? null,
      Mandate_ExpiryDate: props.data?.mandate?.expiryDate ?? null,
      data: props.data ?? {},
      IsShowAuditTrail: props.bool ?? false,
      update: 1,
      IsExternalListing: props.data?.location?.isExternalListing ?? false,
      ButtonStatus: {
        disabled: false,
        processing_save: false,
        processing_delete: false,
      },
      confirmationModalProps: {
        showModal: false,
        isConfirmed: () => { },
        title: "",
        body: "",
      },
    };

    this.overviewStepRef = React.createRef();
    this.diaryStepViewRef = React.createRef();
    this.locationStepRef = React.createRef();
    this.featuresStepRef = React.createRef();
    this.descriptionStepRef = React.createRef();
    this.mediaStepRef = React.createRef();
    this.mandateStepRef = React.createRef();
    this.contactsStepRef = React.createRef();
    this.marketingStepRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.update < this.state.update) {
      //console.log('Its update! ' + this.state.update);
      var data = this.state.data;
      //console.log(data);
      //console.log("ListingBox componentDidUpdate, data=", data);

      this.overviewStepRef.current.doSetState(data);
      this.locationStepRef.current.doSetState(data.location);
      this.featuresStepRef.current.doSetState(data.features);
      this.descriptionStepRef.current.doSetState(data.description);
      //this.mediaStepRef.current.doSetState(data.media);
      this.mandateStepRef.current.doSetState(data.mandate);
      this.contactsStepRef.current.doSetState(data.contacts);
      this.marketingStepRef.current.doSetState(data.marketing);
    }
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };
  openCloseRestoreModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  mandateStatusChanged = (status) => {
    if (this.props.data.propertyID === "") {
      let contactType = "";
      let data = this.state.data;
      if (status >= 5 && status <= 7) {
        contactType = "selling agent";
      } else {
        contactType = "listing agent";
      }

      if (data.contacts.contacts[0] && data.contacts.contacts.length === 1) {
        data.contacts.contacts[0].contactType = contactType;
      }
      this.setState({ data });
    }
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };
  showPopupAndRestoreOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseRestoreModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  onCancelClick = (e) => {
    this.props.history.push("/Property");
  };

  executeDeleteClick = (e) => {
    this.setState({ ButtonStatus: { disabled: true, processing_save: true, processing_delete: true } });
    apiAgent.Property.deletePropertyAjax(this.state.PropertyID, this.props.data.officeSyncID)
      .then((response) => {
        this.props.history.push("/Property");
      })
      .catch((error) => {
        toast.info("Fail");
        this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
      });
  };
  executeRestoreClick = (e) => {
    apiAgent.Property.RestorePropertyAjax(this.state.PropertyID)
      .then((response) => {
        this.props.history.push("/Property");
      })
      .catch((error) => {
        toast.info("Fail");
        this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
      });
  };

  onDeleteClick = (e) => {
    let title = "Delete property?";
    let body = "Do you want to delete property now? This cannot be undone.";
    let callback = this.executeDeleteClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  onRestoreClick = (e) => {
    let title = "Restore property?";
    let body = "Do you want to Restore property now? This cannot be undone.";
    let callback = this.executeRestoreClick;
    this.showPopupAndRestoreOnConfirmation(title, body, callback);
  };

  getFormData = async (e) => {
    var data = new FormData();

    var Step = this.state.Step;

    if (Step < this.state.CompletedStep) Step = this.state.CompletedStep;

    if (this.state.IsUpdate) Step = 8;

    data.append("PropertyID", this.state.PropertyID);
    data.append("UserID", this.state.UserID);
    data.append("WebRef", this.state.WebRef);
    data.append("OfficeID", this.state.OfficeID);
    data.append("OfficeSyncID", this.state.OfficeSyncID);
    data.append("Step", Step);

    this.locationStepRef.current.doSetFormData(data);
    this.featuresStepRef.current.doSetFormData(data);
    this.descriptionStepRef.current.doSetFormData(data);
    await this.mediaStepRef.current.doSetFormData(data);
    await this.mandateStepRef.current.doSetFormData(data);
    this.contactsStepRef.current.doSetFormData(data);
    this.marketingStepRef.current.doSetFormData(data);
    //console.log("ListingBox getFormData, data=", data);
    return data;
  };

  doSave = async (IsDraft) => {
    //console.log("ListingBox doSave, IsDraft=", IsDraft);
    var validate = this.doValidateStep(this.state.Step);
    if (validate.status == "success") {
      this.setState({ ButtonStatus: { disabled: true, processing_save: true, processing_delete: false } });
      var data = await this.getFormData();
      data.append("StrIsDraft", IsDraft);
      // var xhr = new XMLHttpRequest();
      // xhr.open('post', '/Property/SavePropertyAsync2', true);
      // xhr.onload = function () {
      //     var result = JSON.parse(xhr.responseText);
      //     if (result.status == "success") {
      //         if (IsDraft) {
      //             toast.info('Property data saved successfully');
      //             var update = this.state.update + 1;
      //             this.setState({ update: update, data: result.result, ButtonStatus: { disabled: false, processing_save: false, processing_delete: false }, PropertyID: result.result.PropertyID, UserID: result.result.UserID, WebRef: result.result.WebRef });
      //         }
      //         else
      //             this.onCancelClick();
      //     }
      //     else {
      //         toast.info('Fail');
      //         this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
      //     }
      // }.bind(this);
      // xhr.send(data);

      const formDataObj = Object.fromEntries(data.entries());
      //const jsonData = JSON.stringify(formDataObj);
      //console.log("doSave,data: ", formDataObj);
      apiAgent.Property.savePropertyAsync2(data)
        .then((response) => {
          if (IsDraft) {
            //console.log("after save in isDraft: ", response);
            toast.info("Property data saved successfully");
            var update = this.state.update + 1;
            this.setState({
              update: update,
              data: response.result,
              ButtonStatus: { disabled: false, processing_save: false, processing_delete: false },
              PropertyID: response.result.propertyID,
              UserID: response.result.userID,
              WebRef: response.result.webRef,
            });
          } else this.onCancelClick();
        })
        .catch((error) => {
          toast.info("Fail");
          this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
        });
    } else toast.info(validate.message);
  };

  doChangeStepClick = (Step) => {
    var CurrentStep = this.state.Step;
    var validate = this.doValidateStep(CurrentStep);
    if (validate.status == "success") this.doChangeStepClick2(Step);
    else toast.info(validate.message);
  };

  doChangeStepClick2 = (Step) => {
    var CurrentStep = this.state.Step;
    var CompletedStep = this.state.CompletedStep;
    if (CurrentStep > CompletedStep) CompletedStep = CurrentStep;
    this.setState({ Step: Step, CompletedStep: CompletedStep });
  };

  doValidateStep = (Step) => {
    if (Step == 1) {
      return this.locationStepRef.current.doCheckFormData();
    } else if (Step == 2) {
      return this.featuresStepRef.current.doCheckFormData();
    } else if (Step == 3) {
      return this.descriptionStepRef.current.doCheckFormData();
    } else if (Step == 4) {
      return this.mediaStepRef.current.doCheckFormData();
    } else if (Step == 5) {
      return this.mandateStepRef.current.doCheckFormData();
    } else if (Step == 6) {
      return this.contactsStepRef.current.doCheckFormData();
    } else if (Step == 7) {
      return this.marketingStepRef.current.doCheckFormData();
    } else {
      return { status: "success", message: "" };
    }
  };

  onDependantPropertyChange = (name, value) => {
    //console.log("inside onDependantPropertyChange, name: ", name, ", value: ", value);
    this.setState({ [name]: value });
  };

  handleAuditTrailClose = () => {
    this.setState({ IsShowAuditTrail: false });
  };

  handleAuditTrailShow = () => {
    this.setState({ IsShowAuditTrail: true });
  };

  togglePinNote = (noteItem) => {
    if (this.overviewStepRef.current !== undefined && this.overviewStepRef.current != null) this.overviewStepRef.current.togglePinNote(noteItem);
  };

  togglePinNoteFromOverview = () => {
    if (this.diaryStepViewRef.current !== undefined && this.diaryStepViewRef.current != null) this.diaryStepViewRef.current.onDiaryItemSubmitCompleteFunc();
  };

  renderStepName = () => {
    if (this.state.IsUpdate) return this.props.data.displayName;
    else {
      if (this.state.Step == 1) return "Listing capture: Location";
      else if (this.state.Step == 2) return "Listing capture: Features";
      else if (this.state.Step == 3) return "Listing capture: Description";
      else if (this.state.Step == 4) return "Listing capture: Media";
      else if (this.state.Step == 5) return "Listing capture: Mandate";
      else if (this.state.Step == 6) return "Listing capture: Contacts";
      else if (this.state.Step == 7) return "Listing capture: Marketing";
      else return "Listing capture";
    }
  };

  render() {
    var super_access = this.props.IsSuperAdmin;
    return (
      <form className="SavePropertyForm" onSubmit={this.handleSubmit}>
        <div>
          <ConfirmationModalPopup {...this.state.confirmationModalProps} />
          <div className="col-sm-12">
            <div className={this.state.IsUpdate ? "panel panel-default oms-box color2 color2-border-right" : "panel panel-default oms-box"}>
              <div className="wrap">
                <div className="left">
                  <div className="panel-heading">
                    <div className="row">
                      <div className="col-md-12">
                        <OMSFavourite favourite={this.props.favourite} /> &nbsp; {this.renderStepName()} &nbsp;{" "}
                        {this.props.data.isDeleted && <span className="property_deleted_tag">Deleted</span>}
                      </div>
                    </div>
                  </div>
                  <div className="panel-body" style={{ backgroundColor: "white", minHeight: 680 }}>
                    <div className="col-md-12">
                      <div id="overview" style={{ display: this.state.Step == 0 ? "" : "none" }}>
                        {this.state.data.propertyID && (
                          <OverviewStepView
                            ref={this.overviewStepRef}
                            togglePinNoteFromOverview={this.togglePinNoteFromOverview}
                            CurrencyDisplay={this.props.CurrencyDisplay}
                            data={this.state.data}
                            super_access={super_access}
                          />
                        )}
                      </div>
                      <div id="diary" style={{ display: this.state.Step == 8 ? "" : "none" }}>
                        {this.state.data.propertyID && (
                          <DiaryStepView
                            ref={this.diaryStepViewRef}
                            togglePinNote={this.togglePinNote}
                            super_access={super_access}
                            actions={this.props.actions}
                            PropertyID={this.state.data.propertyID}
                            UserID={this.state.data.userID}
                            DiaryActionTags={this.props.DiaryActionTags}
                            OnShowStart={this.props.OnShowStart}
                            OnShowEnd={this.props.OnShowEnd}
                          />
                        )}
                      </div>
                      <div id="location" className={this.state.Step == 1 ? "tab1" : "tab1_hidden"}>
                        <LocationStepView
                          ref={this.locationStepRef}
                          id="locationStep"
                          data={this.state.data.location}
                          loadMap={this.state.Step == 1}
                          CountryDD={this.props.CountryDD}
                          IsExternalListing={this.state.IsExternalListing}
                          handleIsExternalListingChange={this.handleIsExternalListingChange}
                        />
                      </div>
                      <div id="features" style={{ display: this.state.Step == 2 ? "" : "none" }}>
                        <FeaturesStepView
                          onDependantPropertyChange={this.onDependantPropertyChange}
                          super_access={super_access}
                          ref={this.featuresStepRef}
                          id="featuresStep"
                          data={this.props.data.features}
                          OwnershipTypes={this.props.OwnershipTypes}
                          PropertyTypes={this.props.PropertyTypes}
                        />
                      </div>
                      <div id="description" style={{ display: this.state.Step == 3 ? "" : "none" }}>
                        <DescriptionStepView
                          ref={this.descriptionStepRef}
                          id="descriptionStep"
                          super_access={super_access}
                          data={this.state.data.description}
                          ExtraDescriptionTagFields={this.props.ExtraDescriptionTagFields}
                          ExtraDescriptionFieldsDD={this.props.ExtraDescriptionFieldsDD}
                          GeneralListingTags={this.props.GeneralListingTags}
                          IsExternalListing={this.state.IsExternalListing}
                        />
                      </div>
                      <div id="media" style={{ display: this.state.Step == 4 ? "" : "none" }}>
                        <MediaStepView
                          ref={this.mediaStepRef}
                          id="mediaStep"
                          super_access={super_access}
                          data={this.state.data.media}
                          officeSyncID={this.state.data.officeSyncID}
                        />
                      </div>
                      <div id="mandate" style={{ display: this.state.Step == 5 ? "" : "none" }}>
                        <MandateStepView
                          onDependantPropertyChange={this.onDependantPropertyChange}
                          super_access={super_access}
                          ref={this.mandateStepRef}
                          id="mandateStep"
                          data={this.state.data.mandate}
                          save_access={this.props.actions.indexOf("save") >= 0}
                          MandateTypes={this.props.MandateTypes}
                          officeSyncID={this.state.data.officeSyncID}
                          officeID={this.props.data.officeID}
                          CurrencyDisplay={this.props.CurrencyDisplay}
                          userSession={this.props.userSession}
                          mandateStatusChanged={this.mandateStatusChanged}
                        />
                      </div>
                      <div id="contacts" style={{ display: this.state.Step == 6 ? "" : "none" }}>
                        <ContactsStepView
                          ref={this.contactsStepRef}
                          id="contactsStep"
                          super_access={super_access}
                          officeid={this.state.data.officeID}
                          data={this.state.data.contacts}
                          UserID={this.state.data.userID}
                          OfficeContactTags={this.props.OfficeContactTags}
                          UserContactTags={this.props.UserContactTags}
                          AgentTypes={this.props.AgentTypes}
                          ContactSourceTypes={this.props.ContactSourceTypes}
                          DialingCode={this.props.DialingCode}
                          DialingCodeDD={this.props.DialingCodeDD}
                          ListingSellerRequired={this.props.ListingSellerRequired}
                          IsExternalListing={this.state.IsExternalListing}
                        />
                      </div>
                      <div id="marketing" style={{ display: this.state.Step == 7 ? "" : "none" }}>
                        <MarketingStepView
                          ref={this.marketingStepRef}
                          id="marketingStep"
                          data={this.state.data.marketing}
                          PropertyType={this.state.PropertyType}
                          SaleType={this.state.SaleType}
                          Status={this.state.Status}
                          isDeleted={this.props.data.isDeleted}
                        />
                      </div>
                      <div id="reports" style={{ display: this.state.Step == 9 ? "" : "none" }}>
                        {this.state.data.propertyID && (
                          <ReportStepView
                            ref={this.reportStepRef}
                            id="reportStep"
                            ShowChart={this.props.ShowChart}
                            WebRef={this.state.WebRef}
                            super_access={super_access}
                            SelectedAgentID={this.props.SelectedAgentID}
                            AgentDD={this.props.AgentDD}
                            PropertyID={this.state.data.propertyID}
                            UserID={this.state.data.userID}
                            Brochures={this.props.Brochures}
                            Mandate_ExpiryDate={this.state.Mandate_ExpiryDate}
                            Mandate_StartDate={this.state.Mandate_StartDate}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="panel-footer">
                    <div className="row">
                      {this.state.IsUpdate ? (
                        <div className="col-md-12">
                          <div className="pull-left">
                            {this.props.actions.indexOf("save") < 0 ? null : (
                              <div className="dropup">
                                <button className="btn btn-outline dropdown-toggle" type="button" data-toggle="dropdown">
                                  More..
                                  <span className="caret" />
                                </button>
                                <ul className="dropdown-menu">
                                  {this.props.data.isDeleted ? (
                                    <li key="Restore">
                                      <OMSButtonStyleL
                                        style={{ marginRight: 10 }}
                                        type="button"
                                        ui_icon="fa-trash-o"
                                        ui_type="cancel"
                                        ui_text="Restore property"
                                        ui_processing_text="Restore.."
                                        processing={this.state.ButtonStatus.processing_delete}
                                        disabled={this.state.ButtonStatus.disabled}
                                        onClick={this.onRestoreClick}
                                      />
                                    </li>
                                  ) : (
                                    <li key="delete">
                                      <OMSButtonStyleL
                                        style={{ marginRight: 10 }}
                                        type="button"
                                        ui_icon="fa-trash-o"
                                        ui_type="cancel"
                                        ui_text="Delete property"
                                        ui_processing_text="Deleting.."
                                        processing={this.state.ButtonStatus.processing_delete}
                                        disabled={this.state.ButtonStatus.disabled}
                                        onClick={this.onDeleteClick}
                                      />
                                    </li>
                                  )}
                                  <li key="history">
                                    <OMSButtonStyleL
                                      style={{ marginRight: 10 }}
                                      type="button"
                                      ui_icon="fa-history"
                                      ui_type="cancel"
                                      ui_text="History"
                                      ui_processing_text=""
                                      processing={false}
                                      disabled={this.state.ButtonStatus.disabled}
                                      onClick={this.handleAuditTrailShow}
                                    />
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="pull-right visible-xs">
                            <OMSButtonStyleL
                              style={{ marginRight: 10 }}
                              type="button"
                              ui_icon="fa-times"
                              ui_type="cancel"
                              ui_text="Cancel"
                              ui_processing_text=""
                              processing={false}
                              disabled={this.state.ButtonStatus.disabled}
                              onClick={this.onCancelClick}
                            />
                          </div>
                          {this.props.actions.indexOf("save") < 0 ? null : (
                            <div className="pull-right">
                              <OMSButtonStyleL
                                style={{ marginRight: 10 }}
                                type="button"
                                ui_icon="fa-check"
                                ui_type="save"
                                ui_text="Save"
                                ui_processing_text="Saving.."
                                processing={this.state.ButtonStatus.processing_save}
                                disabled={this.state.ButtonStatus.disabled}
                                onClick={() => this.doSave(true)}
                              />
                              <OMSButtonStyleL
                                type="button"
                                ui_icon="fa-check"
                                ui_type="save"
                                ui_text="Save & Close"
                                ui_processing_text="Saving.."
                                processing={this.state.ButtonStatus.processing_save}
                                disabled={this.state.ButtonStatus.disabled}
                                onClick={() => this.doSave(false)}
                              />
                            </div>
                          )}
                          <div className="pull-right hidden-xs">
                            <OMSButtonStyleL
                              style={{ marginRight: 10 }}
                              type="button"
                              ui_icon="fa-times"
                              ui_type="cancel"
                              ui_text="Cancel"
                              ui_processing_text=""
                              processing={false}
                              disabled={this.state.ButtonStatus.disabled}
                              onClick={this.onCancelClick}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-12">
                          <div className="col-md-4">
                            {this.state.Step == 7 ? (
                              this.state.ButtonStatus.disabled ? (
                                <div style={{ padding: "6px 12px" }}>
                                  <span style={{ color: "#337ab7", cursor: "pointer" }}>Cancel</span>
                                </div>
                              ) : (
                                <div style={{ padding: "6px 12px" }}>
                                  <a onClick={this.onCancelClick} style={{ color: "#337ab7", cursor: "pointer" }}>
                                    Cancel
                                  </a>
                                </div>
                              )
                            ) : this.state.ButtonStatus.disabled ? (
                              <div style={{ padding: "6px 12px" }}>
                                <span style={{ color: "#337ab7", cursor: "pointer" }}>Save draft</span> &nbsp; | &nbsp;
                                <span style={{ color: "#337ab7", cursor: "pointer" }}>Cancel</span>
                              </div>
                            ) : (
                              <div style={{ padding: "6px 12px" }}>
                                <a onClick={() => this.doSave(true)} style={{ color: "#337ab7", cursor: "pointer" }}>
                                  Save draft
                                </a>{" "}
                                &nbsp; | &nbsp;
                                <a onClick={this.onCancelClick} style={{ color: "#337ab7", cursor: "pointer" }}>
                                  Cancel
                                </a>
                              </div>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label style={{ padding: "6px 12px" }}>Step {this.state.Step} of {this.state.IsExternalListing ? 6 : 7}  </label>
                          </div>
                          <div className="col-md-4">
                            <div className="pull-right">
                              {this.state.Step === 7 || (this.state.IsExternalListing && this.state.Step === 6) ? (
                                <OMSButtonStyleL
                                  type="button"
                                  onClick={() => this.doSave(false)}
                                  ui_icon="fa-check"
                                  ui_type="save"
                                  ui_text={"Save & Activate"}
                                  ui_processing_text="Saving.."
                                  processing={this.state.ButtonStatus.processing_save}
                                  disabled={this.state.ButtonStatus.disabled}
                                />
                              ) : (
                                <div>
                                  {this.state.Step <= 1 ? null : (
                                    <OMSButtonStyleL
                                      type="button"
                                      onClick={() => this.doChangeStepClick2(this.state.Step - 1)}
                                      ui_icon="fa-arrow-left"
                                      ui_type="save"
                                      ui_text="Previous"
                                      ui_processing_text="Saving.."
                                      style={{ marginRight: 5 }}
                                      processing={this.state.ButtonStatus.processing_save}
                                      disabled={this.state.ButtonStatus.disabled}
                                    />
                                  )}
                                  <OMSButtonStyleL
                                    type="button"
                                    onClick={() => this.doChangeStepClick(this.state.Step + 1)}
                                    ui_icon="fa-arrow-right"
                                    ui_type="save"
                                    ui_text="Next"
                                    ui_processing_text="Saving.."
                                    processing={this.state.ButtonStatus.processing_save}
                                    disabled={this.state.ButtonStatus.disabled}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.IsUpdate == false ? null : (
                  <div className="right color2 hidden-xs hidden-sm">
                    <div className="row">
                      <div className="col-md-12">
                        <a
                          className={this.state.Step == 0 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(0)}
                        >
                          <span>
                            <i className="fa fa-tachometer fa-2x"></i>
                            <br />
                            Overview
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 8 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(8)}
                        >
                          <span>
                            <i className="fa fa-clock-o fa-2x"></i>
                            <br />
                            Diary
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 5 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(5)}
                        >
                          <span>
                            <i className="fa fa-key fa-2x"></i>
                            <br />
                            Mandate
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 6 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(6)}
                        >
                          <span>
                            <i className="fa fa-address-book fa-2x"></i>
                            <br />
                            Contact
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 1 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(1)}
                        >
                          <span>
                            <i className="fa fa-map-marker fa-2x"></i>
                            <br />
                            Location
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 2 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(2)}
                        >
                          <span>
                            <i className="fa fa-bed fa-2x"></i>
                            <br />
                            Features
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 3 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(3)}
                        >
                          <span>
                            <i className="fa fa-file-text-o fa-2x"></i>
                            <br />
                            Desc
                          </span>
                        </a>
                        <a
                          className={this.state.Step == 4 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(4)}
                        >
                          <span>
                            <i className="fa fa-camera fa-2x"></i>
                            <br />
                            Media
                          </span>
                        </a>
                        {!this.state.IsExternalListing && (
                          <a
                            className={this.state.Step == 7 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                            onClick={() => this.doChangeStepClick(7)}
                          >
                            <span>
                              <i className="fa fa-share-alt fa-2x"></i>
                              <br />
                              Feeds
                            </span>
                          </a>
                        )}
                        <a
                          className={this.state.Step == 9 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(9)}
                        >
                          <span>
                            <i className="fa fa-print fa-2x"></i>
                            <br />
                            Reports
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.IsUpdate == false ? null : (
                  <div className="rightS color2 hidden-md hidden-lg">
                    <div className="row">
                      <div className="col-md-12">
                        {/*<a className="btn text-center user2">
                                                        <span className="vertical-text">more..</span>
                                                    </a>*/}
                        <a
                          className={this.state.Step == 0 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(0)}
                        >
                          <i className="fa fa-tachometer fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 8 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(8)}
                        >
                          <i className="fa fa-clock-o fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 5 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(5)}
                        >
                          <i className="fa fa-key fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 6 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(6)}
                        >
                          <i className="fa fa-address-book fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 1 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(1)}
                        >
                          <i className="fa fa-map-marker fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 2 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(2)}
                        >
                          <i className="fa fa-bed fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 3 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(3)}
                        >
                          <i className="fa fa-file-text-o fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 4 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(4)}
                        >
                          <i className="fa fa-camera fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 7 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(7)}
                        >
                          <i className="fa fa-share-alt fa-2x"></i>
                        </a>
                        <a
                          className={this.state.Step == 9 ? "btn text-center property-menu-selected" : "btn text-center property-menu"}
                          onClick={() => this.doChangeStepClick(9)}
                        >
                          <i className="fa fa-print fa-2x"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.IsShowAuditTrail === true && (
            <AuditTrailBox
              id="auditProperty"
              objectID={this.state.PropertyID}
              officeID={this.state.OfficeID}
              onClose={this.handleAuditTrailClose}
            ></AuditTrailBox>
          )}
        </div>
      </form>
    );
  }
}

export default withRouter(ListingBox);
