import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";

export class OMSTagDropdown extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    array1: PropTypes.array,
    array2: PropTypes.array,
    labelField: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
  };

  static defaultProps = {
    array1: [],
    array2: [],
    onChange: null,
  };

  state = {};

  onSelectionClick = (name) => {
    if (this.state.selected == name) {
      this.setState({ selected: undefined });
      name = undefined;
    } else this.setState({ selected: name });

    if (this.props.onChange !== undefined) this.props.onChange(name);
  };

  componentDidMount() {
    window
      .$(document)
      .on("click", ".multiselect-color .dropdown-menu", function (e) {
        e.stopPropagation();
      });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: nextProps.value,
    });
  }

  render() {
    var self = this;
    var selectedName = "-- select --";
    if (self.state.selected && self.state.selected != "") {
      selectedName = self.state.selected;
    }
    return (
      <>
        <div className="dropdown multiselect-color">
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            {selectedName} &nbsp;
            <span className="caret" />
          </button>
          <ul className="dropdown-menu multiselect-color-menu">
            {this.props.array1.map(function (item) {
              return (
                <li key={item.name}>
                  <a onClick={() => self.onSelectionClick(item.name)}>
                    <span
                      className="color-preview"
                      style={{ backgroundColor: item.color }}
                    >
                      {self.state.selected == item.name ? (
                        <i className="fa fa-check" aria-hidden="true"></i>
                      ) : null}
                    </span>
                    <span className="color-label">{item.name}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }
}

export class OMSMultiselectTagDropdown2 extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.array,
    array1: PropTypes.array,
    array2: PropTypes.array,
    array3: PropTypes.array,
    labelField: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: [],
    array1: [],
    array2: [],
    array3: [],
    onChange: null,
  };

  state = {
    selected: this.props.value,
    sources: this.props.svalue,
  };

  onSelectionClick = (name, issource) => {
    var selected = this.state.selected;
    if (issource) selected = this.state.sources;

    var index = selected.indexOf(name);
    if (index < 0) selected.push(name);
    else selected.splice(index, 1);

    if (this.delayTagTimer !== undefined) clearTimeout(this.delayTagTimer);

    this.delayTagTimer = setTimeout(
      function () {
        if (this.props.onChange != null) {
          this.props.onChange(this.state.selected, this.state.sources);
        }
      }.bind(this),
      1000
    );

    if (issource) this.setState({ sources: selected });
    else this.setState({ selected: selected });
  };

  componentDidMount() {
    window
      .$(document)
      .on("click", ".multiselect-color .dropdown-menu", function (e) {
        e.stopPropagation();
      });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: this.props.value,
    });
  }

  render() {
    var self = this;
    var selectedName = "None selected";
    var length = self.state.selected.length + self.state.sources.length;
    if (length > 0) selectedName = length + " selected";
    /*if (self.state.selected.length > 0) {
            if (self.state.selected.length == 1)
                selectedName = self.state.selected[0];
            else if (self.state.selected.length == 2)
                selectedName = self.state.selected[0] + ', ' + self.state.selected[1];
            else
                selectedName = self.state.selected.length + ' selected';
        }*/

    var array1Html = self.props.array1?.map(function (item) {
      return (
        <li key={"arr1" + item.name}>
          <a onClick={() => self.onSelectionClick(item.name, false)}>
            <span
              className="color-preview"
              style={{ backgroundColor: item.color }}
            >
              {self.state.selected.indexOf(item.name) > -1 ? (
                <i className="fa fa-check" aria-hidden="true"></i>
              ) : null}
            </span>
            <span className="color-label">{item.name}</span>
          </a>
        </li>
      );
    });
    var array2Html = self.props.array2.map(function (item) {
      return (
        <li key={"arr2" + item.name}>
          <a onClick={() => self.onSelectionClick(item.name, false)}>
            <span
              className="color-preview"
              style={{ backgroundColor: item.color }}
            >
              {self.state.selected.indexOf(item.name) > -1 ? (
                <i className="fa fa-check" aria-hidden="true"></i>
              ) : null}
            </span>
            <span className="color-label">{item.name}</span>
          </a>
        </li>
      );
    });
    var array3Html = self.props.array3.map(function (item) {
      return (
        <li key={"arr3" + item.name}>
          <a onClick={() => self.onSelectionClick(item.name, true)}>
            <span
              className="color-preview"
              style={{ backgroundColor: item.color }}
            >
              {self.state.sources.indexOf(item.name) > -1 ? (
                <i className="fa fa-check" aria-hidden="true"></i>
              ) : null}
            </span>
            <span className="color-label">{item.name}</span>
          </a>
        </li>
      );
    });
    return (
      <div>
        <div className="dropdown multiselect-color">
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            {selectedName} &nbsp;
            <span className="caret" />
          </button>
          <ul className="dropdown-menu multiselect-color-menu">
            <li className="dropdown-header">&nbsp;type</li>
            {array1Html}
            <li className="divider"></li>
            <li className="dropdown-header">&nbsp;my tags</li>
            {array2Html}
            <li className="divider"></li>
            <li className="dropdown-header">&nbsp;source</li>
            {array3Html}
          </ul>
        </div>
      </div>
    );
  }
}

export class OMSMultiselectDD extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.array,
    data: PropTypes.array,
    onChange: PropTypes.func,
    valueField: PropTypes.string,
    labelField: PropTypes.string,
  };

  static defaultProps = {
    value: [],
    data: [],
    selected: [],
    onChange: null,
    valueField: "value",
    labelField: "label",
  };

  state = {
    selected: this.props.value,
  };

  onSelectionClick = (name) => {
    var selected = this.state.selected;
    var index = selected.indexOf(name);
    if (index < 0) selected.push(name);
    else selected.splice(index, 1);

    this.setState({ selected: selected }, function () {
      if (this.props.onChange != null) {
        if (this.multipleTimer !== undefined) clearTimeout(this.multipleTimer);

        this.multipleTimer = setTimeout(
          function () {
            this.props.onChange(this.state.selected);
          }.bind(this),
          1000
        );
      }
    });
  };

  componentDidMount() {
    window
      .$(document)
      .on("click", ".multiselect-color .dropdown-menu", function (e) {
        e.stopPropagation();
      });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: nextProps.value,
    });
  }

  render() {
    var self = this;
    var selectedName = "None selected";
    if (self.state.selected.length > 0) {
      selectedName = self.state.selected.length + " selected";
    }
    return (
      <div>
        <div
          className="dropdown multiselect-color"
          style={this.props.style ? this.props.style : {}}
        >
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            {selectedName} &nbsp;
            <span className="caret" />
          </button>
          <ul className="dropdown-menu multiselect-color-menu">
            {this.props.data?.map(function (item, index) {
              return (
                <div key={index + "_" + item.lable} data-id={item.label}>
                  <li key={"li" + item.label} className="dropdown-header">
                    &nbsp;{item.label}
                  </li>
                  {item.values?.map(function (subItem, index2) {
                    return (
                      <li
                        key={index2 + "_" + subItem[self.props.valueField]}
                        data-id={subItem[self.props.valueField]}
                      >
                        <a
                          onClick={() =>
                            self.onSelectionClick(
                              subItem[self.props.valueField]
                            )
                          }
                        >
                          <span className="color-preview">
                            {self.state.selected.indexOf(
                              subItem[self.props.valueField]
                            ) > -1 ? (
                              <i
                                className="fa fa-check"
                                style={{ color: "black" }}
                                aria-hidden="true"
                              ></i>
                            ) : null}
                          </span>
                          <span className="color-label">
                            {subItem[self.props.labelField]}
                          </span>
                        </a>
                      </li>
                    );
                  })}
                  <li key={"lid" + item.lable} className="divider"></li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export class OMSMultiselectPTDropdown extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.array,
    labelField: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    onChange: null,
  };

  state = {
    selected: this.props.selected,
  };

  onSelectionClick = (name) => {
    var selected = this.state.selected;
    var index = selected.indexOf(name);
    if (index < 0) selected.push(name);
    else selected.splice(index, 1);

    this.setState({ selected: selected });

    if (this.delayTagTimer !== undefined) clearTimeout(this.delayTagTimer);

    this.delayTagTimer = setTimeout(
      function () {
        if (this.props.onChange != null) {
          this.props.onChange(this.state.selected);
        }
      }.bind(this),
      1000
    );
  };

  componentDidMount() {
    window
      .$(document)
      .on("click", ".multiselect-color .dropdown-menu", function (e) {
        e.stopPropagation();
      });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: nextProps.selected,
    });
  }

  render() {
    var self = this;
    return (
      <div>
        <div className="dropdown multiselect-color">
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            {self.state.selected.length > 0 ? (
              <>
                {self.state.selected.length}{" "}
                selected&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </>
            ) : (
              this.props.labelField
            )}
            &nbsp;
            <span className="caret" />
          </button>
          <ul className="dropdown-menu multiselect-color-menu">
            {this.props.data.map(function (item, index) {
              return (
                <>
                  <li className="dropdown-header">
                    &nbsp;{item.Classification}
                  </li>
                  {item.Types.map(function (IT) {
                    var ID = IT;
                    return (
                      <li key={item.classification + "-" + IT}>
                        <a onClick={() => self.onSelectionClick(ID)}>
                          <span className="color-preview">
                            {self.state.selected.indexOf(ID) > -1 ? (
                              <i className="fa fa-check" aria-hidden="true"></i>
                            ) : null}
                          </span>
                          <span className="color-label">{IT}</span>
                        </a>
                      </li>
                    );
                  })}
                  {index != self.props.data.length - 1 && (
                    <li className="divider"></li>
                  )}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
