import React from "react";
// import { Col, Container, Row, Spinner } from "react-bootstrap";

interface Props {
  inverted?: boolean;
  content?: string;
}

export default function LoadingComponent({ inverted = true, content = "Loading..." }: Props) {
  return (
    <>
      {/* <Spinner animation="border" variant="secondary" ></Spinner> */}
      <span>Loading...</span>
    </>
  );
}
