import React from 'react'
import { BeatLoader } from 'react-spinners'

function AjaxLoader() {
  return (
    <BeatLoader
        cssOverride={{
          position: "fixed",
          left: "0",
          top: "0",
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
//          background: "#fff",
        }}
      />
  )
}

export default AjaxLoader