import React from "react";
import PropTypes from "prop-types";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import apiAgent from "../../../app/api/apiAgent";
import TelerikReportViewerComponent from "../../../app/common/common-components/TelerikReportViewerComponent";
import { DataTableComp, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import { OMSTwitterAutocomplete2 } from "../../../app/common/common-components/OMSAutocomplete";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FromDateStringToJavascriptDate, FromJavascriptDateToDateString } from "../../../app/common/common-functions/functions";

export default class DealReports extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
  }
  static propTypes = {
    ID: PropTypes.string.isRequired,
    Category: PropTypes.string.isRequired,
    MandateTypesRptDD: PropTypes.array,
    PropertyTypesRptDD: PropTypes.array,
    AgentsRptDD: PropTypes.array,
  };
  static defaultProps = {
    MandateTypesRptDD: [],
    PropertyTypesRptDD: [],
    AgentsRptDD: [],
  };

  state = {
    data: [],
    showingReport: false,
    mRptPropertyType: "Property Type",
    mRptAgent: "",
    mRptAgentName: "",
    mRptPipeline: "",
    mRptPipelineId: "",
    mRegistrationStartFrom: "",
    mRegistrationEndTo: "",
    mRegistrationStartFromDT: null,
    mRegistrationEndToDT: null,
    PropertyType: "Property Type",
    Agent: "",
    AgentName: "",
    FilterText: "",
    Pipeline: "",
    PipelineId: "",
    RegistrationStartFrom: "",
    RegistrationEndTo: "",
    hasTableShown: false,
    reportValues: {},
  };

  getFilterText = () => {
    var filterText = "";

    if (this.state.PropertyType != "" && this.state.PropertyType != "Property Type") filterText = filterText + this.state.PropertyType + " | ";

    if (this.state.Agent) filterText = filterText + this.state.AgentName + " | ";

    if (this.state.PipelineId) filterText = filterText + this.state.Pipeline + " | ";

    if (this.state.RegistrationStartFrom && this.state.RegistrationEndTo) {
      filterText = filterText + "Registration start between " + this.state.RegistrationStartFrom + " to " + this.state.RegistrationEndTo + " | ";
    } else if (this.state.RegistrationStartFrom) {
      filterText = filterText + "Registration start from " + this.state.RegistrationStartFrom + " | ";
    } else if (this.state.RegistrationEndTo) {
      filterText = filterText + "Registration end to " + this.state.RegistrationEndTo + " | ";
    }

    if (filterText != "") filterText = filterText.slice(0, filterText.length - 3);

    return filterText;
  };

  loadReportsFromServer = () => {
    this.setState({ loading: true, data: [] });
    apiAgent.ReportViewer.reportsForControlAjax(this.props.Category)
      .then((response) => {
        this.setState({ data: response, loading: false });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.loadReportsFromServer();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.isReportGrid !== this.props.isReportGrid && this.props.isReportGrid == true) {
      this.setState({
        showingReport: false,
        reportID: "",
        reportName: "",
      });
    }
  }

  handleRowClick = (data) => {
    this.props.isReportGridFun(false);
    this.onOpenReportViewer(data.reportID, data.reportName);
  };

  onOpenReportViewer = (ID, ReportName) => {

    this.setState({
      showingReport: true,
      reportID: ID,
      reportName: ReportName,
    });

    var PropertyType = "";
    if (this.state.PropertyType != "Property Type") PropertyType = this.state.PropertyType;

    apiAgent.ReportViewer.viewReport(
      ID,
      "",
      "",
      "",
      PropertyType,
      this.state.Agent,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      this.state.PipelineId,
      this.state.RegistrationStartFrom,
      this.state.RegistrationEndTo
    )
      .then((response) => {
        this.setState({ reportValues: response });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ showingReport: true });
      });

  };

  onOpenReports = (e) => {
    this.setState({
      showingReport: false,
      reportName: "",
      hasTableShown: true,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

 
  handleDropdownChange = (e) => {
    if (e.name == "mRptAgent") {
      var mRptAgentName = window.$("#mRptAgent option:selected").text();
      this.setState({ [e.name]: e.newValue, mRptAgentName: mRptAgentName });
    } else if (e.name == "mRptPipeline") {
      this.setState({ mRptPipeline: e.newText, mRptPipelineId: e.newValue });
    } else {
      this.setState({ [e.name]: e.newValue });
    }
  };
  onOpenFitlerClick = (e) => {
    this.setState({
      mRptPropertyType: this.state.PropertyType,
      mRptAgent: this.state.Agent,
      mRptAgentName: this.state.AgentName,
      mRptRegistrationStartFrom : this.state.RegistrationStartFrom,
      mRptRegistrationEndTo: this.state.RegistrationEndTo,
      mRegistrationStartFromDT: FromDateStringToJavascriptDate(this.state.RegistrationStartFrom),
      mRegistrationEndToDT: FromDateStringToJavascriptDate(this.state.RegistrationEndTo),
      mRptPipelineId: this.state.PipelineId,
      mRptPipeline: this.state.Pipeline,
    });
    window.$("#reportFilterModel").modal("show");
  };
  onApplyFitlerClick = (e) => {
    window.$("#reportFilterModel").modal("hide");
    this.setState(
      {       
        PropertyType: this.state.mRptPropertyType,
        Agent: this.state.mRptAgent,
        AgentName: this.state.mRptAgentName,
        Pipeline: this.state.mRptPipeline,
        PipelineId: this.state.mRptPipelineId,
        RegistrationStartFrom: this.state.mRptRegistrationStartFrom,
        RegistrationEndTo: this.state.mRptRegistrationEndTo
      },
      function () {
        this.setState({ FilterText: this.getFilterText() });
        if (this.state.showingReport) this.onOpenReportViewer(this.state.reportID, this.state.reportName);
      }
    );
  };
  onResetFitlerClick = (e) => {
    window.$("#reportFilterModel").modal("hide");
    this.setState(
      {
        FilterText: "",
        PropertyType: "Property Type",
        Agent: "",
        AgentName: "",
        Pipeline: "",
        PipelineId: "",
        RegistrationStartFrom: "",
        RegistrationEndTo: ""
      },
      function () {
        if (this.state.showingReport) this.onOpenReportViewer(this.state.reportID, this.state.reportName);
      }
    );
  };

  render() {
    var columns = [
      { data: "reportName", title: "Report Name" },
      { data: "reportDescription", title: "Description" },
    ];
    return (
      <div className="mt-2">
        <div className="col-md-12 col-sm-12">
          <div
            style={{ display: this.state.showingReport ? "none" : "block" }}
            className="panel panel-default panel-table oms-box"
            data-intercom-target="grid-panel"
          >
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="form-inline form-group">
                    {this.props.Category == "deals" ? (
                      <div className="element">
                        <div className="truncate">
                          <label>
                            <i className="fa fa-filter" aria-hidden="true"></i>
                            &nbsp;
                          </label>
                          <a href="javascript:void(0);" className="filter-text" onClick={this.onOpenFitlerClick}>
                            {this.state.FilterText == "" ? (
                              "Select report filter"
                            ) : (
                              <span>
                                <b>Report filter: </b>
                                {this.state.FilterText}
                              </span>
                            )}
                          </a>
                        </div>
                      </div>
                    ) : (
                      <h4>Reports</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-body table-responsive">
              <DataTableComp
                id="reportTable"
                hasTableShown={this.state.hasTableShown}
                columns={columns}
                columnDefs={[]}
                data={this.state.data}
                order={[[1, "asc"]]}
                onRowClick={this.handleRowClick}
              />
              <DataTableLoading loading={this.state.loading} />
            </div>
          </div>
          <div style={{ display: this.state.showingReport ? "block" : "none" }}>
            {this.props.Category == "deals" ? (
              <div id="reportViewerMenu" style={{ marginTop: -30, marginLeft: 15 }}>
                <h4>{this.state.reportName}</h4>
                <a href="javascript:void(0);" className="filter-text" onClick={this.onOpenFitlerClick}>
                  <i className="fa fa-filter" aria-hidden="true" style={{ fontSize: 15 }}></i>
                  &nbsp;
                  {this.state.FilterText == "" ? (
                    "Select report filter"
                  ) : (
                    <span>
                      <b>Report filter: </b>
                      {this.state.FilterText}
                    </span>
                  )}
                </a>
                <br></br>
                <br></br>
              </div>
            ) : null}
            <div id="reportViewer" className="listing-reports" style={{ marginTop: -50 }}>
              <TelerikReportViewerComponent reportValues={this.state.reportValues} />
            </div>
          </div>
        </div>
        <div className="modal fade" id="reportFilterModel" tabIndex={-1} aria-labelledby="filterModelLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id="reportFilterModelLabel">
                  Report filter
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <OMSDropdown
                          id="mRptPropertyType"
                          name="mRptPropertyType"
                          style={{ marginRight: 10 }}
                          options={this.props.PropertyTypesRptDD}
                          valueField="Value"
                          labelField="Text"
                          value={this.state.mRptPropertyType}
                          onChange={this.handleDropdownChange}
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <OMSDropdown
                          id="mRptAgent"
                          name="mRptAgent"
                          style={{ marginRight: 10 }}
                          options={this.props.AgentsRptDD}
                          valueField="Value"
                          labelField="Text"
                          value={this.state.mRptAgent}
                          onChange={this.handleDropdownChange}
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <OMSDropdown
                          id="mRptPipeline"
                          name="mRptPipeline"
                          options={this.props.pipelineDD}
                          style={{ marginRight: 10 }}
                          valueField="Value"
                          labelField="Text"
                          value={this.state.mRptPipelineId}
                          onChange={this.handleDropdownChange}
                        />
                      </div>
                    </div>
                    <div className="hidden-xs hidden-sm">
                      <br />
                      <br />
                    </div>
                    <div className="form-group">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <label>Expected registration between</label>
                          </div>
                          <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                             <DatePicker
                                selected={this.state.mRegistrationStartFromDT}
                                onChange={(date) => {
                                  this.setState({ mRegistrationStartFromDT: date, mRptRegistrationStartFrom: FromJavascriptDateToDateString(date) });
                                }}
                                dateFormat="dd-MM-yyyy"
                                value={this.state.mRegistrationStartFromDT}
                                placeholderText="Registration from"
                                name="mRegistrationStartFrom"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: 150 }}
                                className="form-control MandateDatePicker2"
                              />
                          </div>
                          <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                              <DatePicker
                                selected={this.state.mRegistrationEndToDT}
                                onChange={(date) => {
                                  this.setState({ mRegistrationEndToDT: date, mRptRegistrationEndTo: FromJavascriptDateToDateString(date) });
                                }}
                                dateFormat="dd-MM-yyyy"
                                value={this.state.mRegistrationEndToDT}
                                placeholderText="Registration to"
                                name="mRegistrationEndTo"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: 150 }}
                                className="form-control MandateDatePicker2"
                              />
                          </div>
                        </div>
                        <div className="hidden-xs hidden-sm">
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="hidden-xs hidden-sm">
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <a href="javascript:void(0);" style={{ marginRight: 5 }} onClick={this.onResetFitlerClick}>
                  Reset
                </a>
                <button type="button" className="btn btn-primary" onClick={this.onApplyFitlerClick}>
                  Apply
                </button>
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
