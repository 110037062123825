import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";

export class OMSColorSelector extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array,
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    options: [
      /*{ value: '1', color: '#4b4b4d', name: '' },
            { value: '2', color: '#92ab1c', name: '' },
            { value: '3', color: '#ecf4f6', name: '' },
            { value: '4', color: '#b4b4b4', name: '' },
            { value: '5', color: '#ffffff', name: '' },
            { value: '6', color: '#333000', name: '' },
            { value: '7', color: '#A0522D', name: '' },
            { value: '8', color: '#CD5C5C', name: '' },
            { value: '9', color: '#FF4500', name: '' },
            { value: '10', color: '#DC143C', name: '' },
            { value: '11', color: '#FF8C00', name: '' },
            { value: '12', color: '#C71585', name: '' },
            { value: '13', color: '#ffffff', name: '' },
            { value: '14', color: '#000000', name: '' },
            { value: '15', color: '#ff0f0f', name: '' },
            { value: '16', color: '#ff6600', name: '' },
            { value: '17', color: '#008000', name: '' }*/
      { value: "1", color: "#cb1e0a", name: "" },
      { value: "2", color: "#ec1562", name: "" },
      { value: "3", color: "#9d1db2", name: "" },
      { value: "4", color: "#6834bc", name: "" },
      { value: "5", color: "#3f4db8", name: "" },
      { value: "6", color: "#1294f6", name: "" },
      { value: "7", color: "#009788", name: "" },
      { value: "8", color: "#48b14c", name: "" },
      { value: "9", color: "#89c541", name: "" },
      { value: "10", color: "#feb900", name: "" },
      { value: "11", color: "#fe9900", name: "" },
      { value: "12", color: "#ff5608", name: "" },
      { value: "13", color: "#7b5548", name: "" },
      { value: "14", color: "#9e9e9e", name: "" },
      { value: "15", color: "#607d8d", name: "" },
      { value: "16", color: "#434343", name: "" },
    ],
    value: "#cb1e0a",
    onChange: null,
  };

  state = {};

  componentDidMount() {
    window.$("#" + this.props.id).colorselector({
      callback: function (value, color, title) {
        if (this.props.onChange !== null) this.props.onChange(this.props.id, color);
      }.bind(this),
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value != this.props.value) {
      window.$("#" + this.props.id).colorselector("setColor", nextProps.value);
    }
  }

  render() {
    var self = this;
    var options = self.props.options.map(function (option) {
      return option.color == self.props.value ? (
        <option key={option.value} value={option.value} data-color={option.color} selected="selected">
          {option.name}
        </option>
      ) : (
        <option key={option.value} value={option.value} data-color={option.color}>
          {option.name}
        </option>
      );
    });
    return (
      <select id={this.props.id} name={this.props.id} className="selectColor">
        {options}
      </select>
    );
  }
}
