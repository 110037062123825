import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { PropertyResponseModel } from "../../app/models/PropertyResponseModel";
import { useStore } from "../../app/stores/store";
import HeaderWrapper from "../header/HeaderWrapper";
import PropertyGrid from "./PropertyGrid";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";
//import "../../assets/styles/components/_property.scss";
import Breadcrumb from "../../app/common/common-components/Breadcrumb";
import { useLocation } from 'react-router-dom';

export default observer(function PropertyPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [property, setProperty] = useState<PropertyResponseModel>();  
  const [dashboardSectionClicked, setDashboardSectionClicked] = useState('');

  const location = useLocation();

  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    //console.log("inside useEffect of Property Page, userSession=", userSession);
    setIsLoading(true);
    apiAgent.Property.getProperty()
      .then((response: PropertyResponseModel) => {
        let oProperty: PropertyResponseModel = response;
        setProperty(oProperty);
        //console.log("inside useEffect of Property Page oProperty", oProperty);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionParam = params.get('dashboardSectionClicked');
    if (sectionParam) {
      setDashboardSectionClicked(sectionParam);
    }
  }, [location.search]);



  document.title = "Base - Listings";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && property) {
    return (
      <>
        {/* <Header /> */}
        <HeaderWrapper />
        <div className="property-page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Listings" },
            ]}
          />

          <div>
            <div id="content" className="col-sm-12 col-md-12">
              <PropertyGrid
                property={property}
                actions={property.actions}
                OwnershipTypes={property.OwnershipTypes}
                PropertyTypes={property.PropertyTypes}
                MandateTypes={property.MandateTypes}
                Agents={property.Agents}
                FilterCache={property.FilterCache}
                OfficeDD={property.OfficeDD}
                ShowOfficeDD={property.ShowOfficeDD}
                super_access={property.super_access}
                CurrencyDisplay={property.CurrencyDisplay}
                AgentsRpt={property.AgentsRpt}
                super_admin={property.super_access}
                Headers={property.Headers}
                userSession={userSession}
                dashboardSectionClicked={dashboardSectionClicked}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
