import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import { OMSButtonStyleL } from "./OMSButton";
import apiAgent from "../../api/apiAgent";


export class OMSDLUpload extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    parentid: PropTypes.string,
    librarytype: PropTypes.number,
    onUploadComplete: PropTypes.func,
    hidden: PropTypes.bool,
  };

  state = {
    title: "",
    filename: "",
    description: "",
    fileicon: "",
    processing: false,
    error_message: "",
  };

  componentDidMount(e) {}

  handleTitleChange = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  handleDescriptionChange = (e) => {
    this.setState({
      description: e.target.value,
    });
  };

  onUploadClick = (e) => {
    var htmlID = "uploadfile_" + this.props.id;
    window.$("#" + htmlID).val("");
    document.getElementById(htmlID).click();
  };

  onUploadFileChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (files[0] !== undefined) {
      var mSize = files[0].size;
      if (!isNaN(mSize) && mSize > 200000000) {
        toast.info("File is too big to upload. Max filesize allowed: 200MB");
        return;
      }

      if (files[0].name.match(/.(exe|apk|bat|csh|ipa|msi|reg|vb|cs|js|jsx|jar)$/i)) {
        toast.info("Executable files are not allowed!");
        return;
      }

      var fileicon = "";
      if (files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
        var reader = new FileReader();
        reader.onload = this.OnImageUploadFileReaderLoad;
        reader.readAsDataURL(files[0]);
      } else {
        // fa fa-file-o, fa fa-file-pdf-o,fa fa-file-photo-o, fa fa-file-text-o, fa fa-file-word-o,fa fa-file-zip-o,fa fa-file-excel-o, fa fa-file-powerpoint-o
        if (files[0].name.match(/\.(pdf)$/)) fileicon = "fa-file-pdf-o";
        else if (files[0].name.match(/\.(txt)$/)) fileicon = "fa-file-text-o";
        else if (files[0].name.match(/\.(zip|rar)$/)) fileicon = "fa-file-zip-o";
        else if (files[0].name.match(/\.(csv|xls|xlsx)$/)) fileicon = "fa-file-excel-o";
        else if (files[0].name.match(/\.(ppt|pptx)$/)) fileicon = "fa-file-powerpoint-o";
        else if (files[0].name.match(/\.(doc|docx)$/)) fileicon = "fa-file-word-o";
        else fileicon = "fa-file-o";
      }
      this.setState({
        fileicon: fileicon,
        title: files[0].name,
        filename: files[0].name,
        description: "",
        processing: false,
        error_message: "",
      });

      var htmlID = "uploadmodal_" + this.props.id;

      window.$("#" + htmlID).modal("show");
      window
        .$("#" + htmlID)
        .off("shown.bs.modal")
        .on("shown.bs.modal", function () {
          //document.getElementById("title_" + this.props.id).select();
          window.$("input[id^='title_']").select();
        });
    }
  };

  OnImageUploadFileReaderLoad = (e) => {
    document.getElementById("imgpreview_" + this.props.id).src = e.target.result;
  };

  onSaveUploadClick = (e) => {
    var fileUpload = window.$("#uploadfile_" + this.props.id).get(0);
    var files = fileUpload.files;
    //console.log("inside onSaveUploadClick,files=", files);
    var parentid = this.props.parentid;
    if (parentid == undefined) parentid = "";

    var data = new FormData();
    data.append("title", this.state.title);
    data.append("fileName", this.state.filename);
    data.append("description", this.state.description);
    data.append("file", files[0]);
    data.append("parentID", parentid);
    data.append("type", this.props.librarytype);

    this.setState({ processing: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Library/FSUploadFileAjax', true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                $('#uploadmodal_' + this.props.id).modal('hide');
                this.setState({ fileicon: '', title: '', filename: '', description: '', processing: false, error_message: '' });
                if (this.props.onUploadComplete !== undefined) {
                    this.props.onUploadComplete(data.data);
                }
            }
            else {
                this.setState({ error_message: data.message, processing: false});
            }
        }.bind(this);
        xhr.send(data); */
    apiAgent.Library.fsUploadFileAjax(data)
      .then((response) => {
        window.$("#uploadmodal_" + this.props.id).modal("hide");
        this.setState({
          fileicon: "",
          title: "",
          filename: "",
          description: "",
          processing: false,
          error_message: "",
        });
        if (this.props.onUploadComplete !== undefined) {
          this.props.onUploadComplete(response.data);
        }
      })
      .catch((error) => this.setState({ error_message: error.response.message }));
  };

  render() {
    return (
      <div>
        {this.props.hidden ? null : (
          <button id={"btnupload_" + this.props.id} type="button" className="btn btn-primary" name={"btnupload_" + this.props.id} onClick={this.onUploadClick}>
            Upload
          </button>
        )}
        <input id={"uploadfile_" + this.props.id} type="file" style={{ display: "none" }} onChange={this.onUploadFileChange} />
        <div id={"uploadmodal_" + this.props.id} className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title">Upload a file?</h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  {this.state.error_message == "" ? null : (
                    <div className="form-group col-md-12">
                      <label style={{ color: "red" }}>{this.state.error_message}</label>
                    </div>
                  )}
                  <div className="form-group col-md-12 text-center">
                    <img
                      id={"imgpreview_" + this.props.id}
                      style={{
                        width: 100,
                        height: 100,
                        border: "1px solid",
                        display: this.state.fileicon == "" ? "" : "none",
                      }}
                    />
                    <span
                      id={"iconpreview_" + this.props.id}
                      style={{
                        width: 100,
                        height: 100,
                        display: this.state.fileicon == "" ? "none" : "",
                      }}
                    >
                      <i className={"fa " + this.state.fileicon} aria-hidden="true" style={{ fontSize: 60 }}></i>
                    </span>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      id={"title_" + this.props.id}
                      name={"title_" + this.props.id}
                      className="form-control"
                      value={this.state.title}
                      onChange={this.handleTitleChange}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="description">Description</label>
                    <textarea
                      rows={2}
                      className="form-control"
                      id={"description_" + this.props.id}
                      name={"description_" + this.props.id}
                      value={this.state.description}
                      onChange={this.handleDescriptionChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <OMSButtonStyleL
                  type="button"
                  style={{ marginRight: 10 }}
                  ui_icon="fa-upload"
                  ui_type="save"
                  ui_text="Upload"
                  ui_processing_text="Uploading.."
                  processing={this.state.processing}
                  disabled={this.state.processing}
                  onClick={this.onSaveUploadClick}
                />
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
