import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import { OMSButtonStyleL } from "./OMSButton";
import apiAgent from "../../api/apiAgent";

export class OMSPurchaseCredit extends React.Component {
  static propTypes = {
    ID: PropTypes.string.isRequired,
  };

  state = {
    sData: [],
    sPackageID: 250,
    sPurchaseVia: 2,
    sSending: false,
    sUserID: "",
    sStep: 0,
    isLoading: false,
  };

  componentDidMount() {}

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handlePurchaseViaChange = (e) => {
    var sPurchaseVia = 1;
    if (e.target.value == "on" && e.target.id == "rbtnCreditCard") {
      sPurchaseVia = 2;
    }

    this.setState({
      sPurchaseVia: sPurchaseVia,
    });
  };

  onOpen = (userID) => {
    this.setState({
      sPackageID: 250,
      sUserID: userID,
      sData: [],
      sStep: 0,
      sSending: false,
    });
    window.$("#purchaseModel-" + this.props.ID).modal("show");
    this.getRateData();
  };

  getRateData = () => {
    /*         var xhr = new XMLHttpRequest();
                xhr.open('get', '/Settings/CountriesRateAjax', true);
                xhr.onload = function () {
                    var result = JSON.parse(xhr.responseText);
                    this.setState({ sData: result });
                }.bind(this);
                xhr.send(); */
    this.setState({
      isLoading: true,
    });
    apiAgent.Setting.countriesRateAjax()
      .then((response) => {
        this.setState({ sData: response });
      })
      .catch((error) => {
        toast.error(error.response.data);
        console.log(error);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  handleProcessClick = (e) => {
    if (this.state.sStep == 0) {
      this.setState({ sStep: 1 });
      return;
    }

    if (this.state.sPurchaseVia == 1) {
      this.executePlaceOrderEFT();
    } else {
      this.executePlaceOrderCC();
    }
  };

  executePlaceOrderEFT = (e) => {
    /*    var data = new FormData();
           data.append('userID', this.state.sUserID);
           data.append('PackageID', this.state.sPackageID);
           this.setState({ sSending: true });
           var xhr = new XMLHttpRequest();
           xhr.open('post', '/Contacts/PlaceCreditOrderEFTAjax', true);
           xhr.onload = function () {
               this.setState({ sSending: false });
               var data = JSON.parse(xhr.responseText);
               if (data.status == "success") {
                   $('#purchaseModel-' + this.props.ID).modal('hide');
                   $.toaster('Your EFT credit purchase order placed successfully', 'Success', 'success');
               }
               else {
                   $.toaster(data.message, 'Fail', 'danger');
               }
           }.bind(this);
           xhr.send(data); */
    this.setState({ sSending: true, isLoading: true });
    apiAgent.Contact.placeCreditOrderEFTAjax(this.state.sUserID, this.state.sPackageID)
      .then((response) => {
        window.$("#purchaseModel-" + this.props.ID).modal("hide");
        toast.info("Your EFT credit purchase order placed successfully");
      })
      .catch((error) => {
        toast.error(error.response.data);
      })
      .finally(() => {
        this.setState({ sSending: false, isLoading: false });
      });
  };

  executePlaceOrderCC = (e) => {
    /* var data = new FormData();
        data.append('userID', this.state.sUserID);
        data.append('PackageID', this.state.sPackageID);
        this.setState({ sSending: true });
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/Contacts/PlaceCreditOrderCreditCardAjax', true);
        xhr.onload = function () {
            this.setState({ sSending: false });
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                this.submitForm(data);
            }
            else {
                $.toaster(data.message, 'Fail', 'danger');
            }
        }.bind(this);
        xhr.send(data); */
    this.setState({ sSending: true, isLoading: true });
    apiAgent.Contact.placeCreditOrderCreditCardAjax(this.state.sUserID, this.state.sPackageID)
      .then((response) => {
        //console.log("placeCreditOrderCreditCardAjax", response);
        this.submitForm(response);
      })
      .catch((error) => {
        // toast.error(error.response.data);
        toast.error(error.response.data);
      })
      .finally(() => {
        this.setState({ sSending: false, isLoading: false });
      });
  };

  submitForm = (data) => {
    var form = document.createElement("form");
    window.$(form).attr("action", "https://paynow.sagepay.co.za/site/paynow.aspx").attr("id", "paymentForm").attr("name", "paymentForm").attr("method", "post");
    window.$(form).append('<input id="name" type="hidden" name="m9" value="' + data.result.Entity.name + '" />');
    window.$(form).append('<input id="emailaddress" type="hidden" name="m11" value="' + data.result.Entity.email + '" />');
    window.$(form).append('<input id="orderid" type="hidden" name="p2" value="' + data.result.Entity.id + '" />');
    //$(form).append('<input id="gatewayKey" type="hidden" name="m1" value="399e3c94-c611-45e6-a7a0-2876d764f5a4" />');
    window.$(form).append('<input id="gatewayKey" type="hidden" name="m1" value="5f243a66-747f-4b38-98b7-a698622fcee6" />');
    window.$(form).append('<input id="productTitle" type="hidden" name="p3" value="' + data.result.ProductTitle + '" />');
    window.$(form).append('<input id="productPrice" type="hidden" name="p4" value="' + data.result.ProductPrice + '" />');
    window.$(form).append('<input id="productid" type="hidden" name="m4" value="' + data.result.Entity.productId + '" />');
    window.$(form).append('<input id="budget" type="hidden" name="Budget" value="N" />');
    document.body.appendChild(form);
    window.$(form).submit();
    document.body.removeChild(form);
  };

  onCancelClick = (e) => {
    window.$("#purchaseModel-" + this.props.ID).modal("hide");
  };

  render() {
    var self = this;
    return (
      <div>
        <div className="modal fade" id={"purchaseModel-" + this.props.ID} tabIndex={-1} aria-labelledby="purchaseModelLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id="purchaseModelLabel">
                  Purchase Credit {this.state.sStep == 1 ? (this.state.sPurchaseVia == 1 ? " : EFT" : " : Credit Card") : null}
                </h4>
              </div>
              <div className="modal-body">
                {this.state.sStep == 0 ? (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="sPurchaseVia">Purchase</label>
                        <div className="form-inline">
                          <label className="radio-inline">
                            <input
                              type="radio"
                              id="rbtnEFT"
                              name="rbtnPurchase"
                              checked={this.state.sPurchaseVia === 1}
                              onChange={this.handlePurchaseViaChange}
                            />
                            via EFT
                          </label>
                          <label className="radio-inline">
                            <input
                              type="radio"
                              id="rbtnCreditCard"
                              name="rbtnPurchase"
                              checked={this.state.sPurchaseVia === 2}
                              onChange={this.handlePurchaseViaChange}
                            />
                            via Credit Card
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="sPackageID">Number of credit to purchase (VAT included)</label>
                        <select
                          id="sPackageID"
                          name="sPackageID"
                          className="form-control"
                          style={{ maxWidth: "200px" }}
                          value={this.state.sPackageID}
                          onChange={this.handleChange}
                        >
                          <option value="250">250 credits (R250)</option>
                          <option value="500">500 credits (R500)</option>
                          <option value="1000">1000 credits (R1000)</option>
                          <option value="5000">5000 credits (R5000)</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group" style={{ overflowY: "auto", height: 150 }}>
                        {this.state.sData.map(function (item) {
                          return (
                            <div style={{ marginTop: 5, fontSize: 13 }} key={item.name}>
                              <label>Current costs:- {item.name}</label>
                              <p>
                                <span>SMS Send cost: {item.smsSend}</span>
                                <br></br>
                                <span>SMS Receive cost: {item.smsReceive == -1 ? "Not supported for " + item.name : item.smsReceive}</span>
                              </p>
                              <p>
                                <span>Email Send cost: {item.emailSend}</span>
                                <br></br>
                                <span>Email Receive cost: {item.emailReceive}</span>
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.sStep == 1 ? (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <span>
                          Credit ordered:{" "}
                          <b>
                            {this.state.sPackageID} (R{this.state.sPackageID})
                          </b>
                        </span>
                        {this.state.sPurchaseVia == 1 ? (
                          <p style={{ fontSize: 13, marginTop: 20 }}>
                            An invoice will allocated to your Entegral account and sent within one business day.
                            <br />
                            Once we receive proof of payment the credits will be applied to your profile within 1 business day.
                          </p>
                        ) : (
                          <p style={{ fontSize: 13, marginTop: 20 }}>
                            You will be redirect to secure credit card payment page from Entegral.
                            <br />
                            Once payment is processed, credits will automatically be applied to your account
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="text-center" style={{ marginTop: 30 }}>
                          <OMSButtonStyleL
                            type="button"
                            ui_icon="fa-check"
                            ui_type="save"
                            ui_text="Place Order"
                            ui_processing_text="Processing Order.."
                            onClick={this.handleProcessClick}
                            processing={this.state.sSending}
                            disabled={this.state.sSending}
                          />
                          <OMSButtonStyleL
                            style={{ marginLeft: 10 }}
                            type="button"
                            ui_icon="fa-times"
                            ui_type="cancel"
                            ui_text="Cancel"
                            ui_processing_text=""
                            processing={false}
                            disabled={false}
                            onClick={this.onCancelClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {this.state.sStep == 0 ? (
                <div className="modal-footer">
                  <div className="col-md-12">
                    <button type="button" className="btn btn-default" data-dismiss="modal">
                      Cancel
                    </button>
                    <button type="button" onClick={this.handleProcessClick} className="btn btn-success-oms">
                      Order
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
