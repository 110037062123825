import React from "react";
import { toast } from "react-toastify";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import apiAgent from "../../../app/api/apiAgent";


export class DocumentFileComponent extends React.Component {
    state = {
        dcFileID: '',
        dcDescription: '',
        dcFile: null,
        dcFileicon: '',
        dcFilename: '',
        dcIsRestrictAccess: true,
        Documents: this.props.data,
        confirmationModalProps: {
            showModal: false,
            isConfirmed: (result) => {},
            title: "",
            body: "",
          },
    };

    componentDidMount() {
        //console.log("inside DocumentFileComponent,data=", this.props.data);
    }

    doSetState = (data) => {
        this.setState({ Documents: data });
    };

    onDownloadFile = (fileName) => {

        apiAgent.Property.DownloadMandateFile(fileName,this.props.officeSyncID)
          .then((response) => {
            //console.log("after apiAgent.Property.DownloadMandateFile, response.headers=", response.headers);
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const downloadUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
    
            a.href = downloadUrl;
            // const disposition = response.headers['content-disposition'];
            // const parts = disposition.split(';');
            // const filenamePart = parts.find(part => part.trim().startsWith('filename='));
            // const filenameWithExtension = filenamePart.split('=')[1].trim();
            // const extensionIndex = filenameWithExtension.lastIndexOf(".");
            // a.download = filenameWithExtension.substring(0, extensionIndex);
            a.download = fileName;
    
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch((error) => {
            console.error("Failed to download file:", error);
          });
      };

    openCloseDeleteModal = (isOpen) => {
        let varConfirmationModalProps = this.state.confirmationModalProps;
        varConfirmationModalProps.showModal = isOpen;
        this.setState({ confirmationModalProps: varConfirmationModalProps });
    };
    
    showPopupAndDeleteOnConfirmation = (title, body, callback) => {
        let varConfirmationModalProps = {
          title,
          body,
          showModal: true,
          isConfirmed: (isConfirmed) => {
            if (isConfirmed) {
              //console.log("confirmed");
              callback();
            }
            this.openCloseDeleteModal(false);
          },
        };
        //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
        this.setState({ confirmationModalProps: varConfirmationModalProps });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleRestrictAccessChange = (e) => {
        if (e.target.checked) {
            this.setState({ dcIsRestrictAccess: true });
        }
        else {
            this.setState({ dcIsRestrictAccess: false })
        }
    };

    handleAddDocumentClick = () => {
        window.$('#dcUploadPhoto').click();
    };

    handleUploadDocumentChange = (e) => {
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        if (files === undefined || files.length == 0)
            return;

        var uploadedFile = files[0];
        var fileicon = this.getFileIcon(uploadedFile.name);
        if (fileicon != "") {
            this.setState({
                dcFileID: '',
                dcDescription: uploadedFile.name,
                dcFile: uploadedFile,
                dcFileicon: fileicon,
                dcFilename: uploadedFile.name,
                dcIsRestrictAccess: true
            });

            window.$('#dcIsRestrictAccess').prop('checked', true);
            window.$('#DCModal').modal('show');
        }
        else {
            toast.info('Only jpg/jpeg, png, pdf, txt, xls/xlsx, doc/docx and ppt/pptx files are allowed!');
        }
        window.$('#dcUploadPhoto').val('');
    };

    getFileIcon = (fileName) => {
        var fileicon = "";
        if (fileName.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/))
            fileicon = 'fa-file-photo-o';
        else if (fileName.match(/\.(pdf|PDF)$/))
            fileicon = 'fa-file-pdf-o';
        else if (fileName.match(/\.(txt|TXT)$/))
            fileicon = 'fa-file-text-o';
        else if (fileName.match(/\.(csv|xls|xlsx|CSV|XLS|XLSX)$/))
            fileicon = 'fa-file-excel-o';
        else if (fileName.match(/\.(ppt|pptx|PPT|PPTX)$/))
            fileicon = 'fa-file-powerpoint-o';
        else if (fileName.match(/\.(doc|docx|DOC|DOCX)$/))
            fileicon = 'fa-file-word-o';
        else
            fileicon = '';

        return fileicon;
    };

    formatBytes = (bytes, decimals) => {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    handleUpdateDocumentLinkClick = (FileID) => {
        var index = -1;
        var files = this.state.Documents;
        for (var i = 0; i < files.length; i++) {
            if (files[i].fileID == FileID) {
                index = i;
                break;
            }
        }

        this.setState({
            dcFileID: files[index].fileID,
            dcDescription: files[index].description,
            dcFile: null,
            dcFileicon: files[index].fileicon,
            dcFilename: files[index].fileName,
            dcIsRestrictAccess: files[index].isRestrictAccess
        });

        if (files[index].isRestrictAccess)
        window.$('#dcIsRestrictAccess').prop('checked', true);
        else
        window.$('#dcIsRestrictAccess').prop('checked', false);

        window.$('#DCModal').modal('show');
    };

    onSaveDocumentClick = () => {
        var files = this.state.Documents.slice(0);
        if (this.state.dcFileID == "") {

            var fileItem =
            {
                fileID: 'NAP' + new Date().getTime(),
                description: this.state.dcDescription,
                size: this.formatBytes(this.state.dcFile.size),
                fileName: this.state.dcFilename,
                file: this.state.dcFile,
                fileicon: this.state.dcFileicon,
                order: 1,
                isRestrictAccess: this.state.dcIsRestrictAccess,
                uploadedTime: "-"
            }

            files.push(fileItem);
        }
        else {
            for (var i = 0; i < files.length; i++) {
                if (files[i].fileID == this.state.dcFileID) {
                    var iFile = files[i].file;
                    files[i].file = null;
                    var fileItem = JSON.parse(JSON.stringify(files[i]));
                    fileItem.file = iFile;
                    //fileItem.FileName = this.state.ftFilename;
                    fileItem.description = this.state.dcDescription;
                    fileItem.isRestrictAccess = this.state.dcIsRestrictAccess;
                    files[i] = fileItem;
                    break;
                }
            }
        }

        this.setState({
            Documents: files,
            dcFileID: '',
            dcDescription: '',
            dcFile: null,
            dcFileicon: '',
            dcFilename: '',
            dcIsRestrictAccess: true,
            expanded: true
        });

        window.$('#DCModal').modal('hide');

        if (this.props.onChange !== undefined)
            this.props.onChange(files);
    };

    executeDeleteDocumentClick = () => {
        var index = -1;
        var files = this.state.Documents;

        for (var i = 0; i < files.length; i++) {
            if (files[i].fileID == this.state.dcFileID) {
                index = i;
                apiAgent.S3Helper.deleteListingMandateFilesFromS3(files[i].fileName,this.props.propertyID, this.props.officeSyncID);                
            }
        }

        if (index != -1) {
            files.splice(index, 1);
            this.setState({ Documents: files });
            window.$('#DCModal').modal('hide');

            if (this.props.onChange !== undefined)
                this.props.onChange(files);
        }
    };

    onDeleteDocumentClick = () => {
        // bootbox.confirm({
        //     title: "Delete document?",
        //     message: "Do you want to delete document now?",
        //     buttons: {
        //         cancel: {
        //             label: '<i class="fa fa-times"></i> Cancel'
        //         },
        //         confirm: {
        //             label: '<i class="fa fa-check"></i> Confirm'
        //         }
        //     },
        //     callback: function (result) {
        //         if (result) {
        //             this.executeDeleteDocumentClick();
        //         }
        //     }.bind(this)
        // });
        let title = "Delete document?";
        let body = "Do you want to delete document now?";
        let callback = this.executeDeleteDocumentClick;
        this.showPopupAndDeleteOnConfirmation(title, body, callback);
    };

    // doSetState = (data) => {
    //     this.setState({ Documents: data });
    // };

    render() {
        var self = this;
        return (
            <div style={{ marginLeft: 5, marginRight: 5 }}>
            <ConfirmationModalPopup {...this.state.confirmationModalProps} />
                <div className="col-md-12 col-xs-12">
                    <div className="pull-right">
                        <input type="file" accept=".jpg,.jpeg,.png,.txt,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx" id="dcUploadPhoto" name="dcUploadPhoto" style={{ display: 'none' }} onChange={this.handleUploadDocumentChange} multiple={false} />
                        {this.props.save_access ? <button style={{ marginTop: 5, marginBottom: 5 }} className="btn btn-success-oms" onClick={this.handleAddDocumentClick}>Add Document</button> : null}
                    </div>
                </div>
                <div className="col-md-12 col-xs-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ width: '60%' }}>File</th>
                                <th style={{ width: '30%' }}>Uploaded On</th>
                                <th style={{ textAlign: 'right', width: '10%', minWidth: '25px' }}>Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.Documents.map((item) => {
                                return (
                                    [
                                        <tr>
                                            <td>
                                                <div className="library_grid_title">
                                                {item.uploadedTime != "-" && item.url ?
                                                        <a style={{ cursor: 'pointer', marginRight: 15 }} onClick={() => this.onDownloadFile(item.fileName)} target="_blank" title="open/download file"><i className="fa fa-download" aria-hidden="true"></i></a>
                                                        :
                                                        <span style={{ marginRight: 15, color: 'grey' }}><i className="fa fa-download" aria-hidden="true"></i></span>
                                                    }
                                                    <a style={{ cursor: 'pointer' }} title="update description" onClick={() => self.handleUpdateDocumentLinkClick(item.fileID)}><i className={'fa ' + item.fileicon} aria-hidden="true"></i>&nbsp;{item.description ? item.description : 'No description added.'}
                                                    </a>
                                                </div>
                                            </td>
                                            <td><span>{item.uploadedTime}</span></td>
                                            <td><span className="label label-default">{item.size}</span></td>
                                        </tr>
                                    ]
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
                <div id="DCModal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="DCModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title" id="DCModalLabel">{this.state.dcFileID ? "Save document" : "Add document"}</h4>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="form-group col-md-12 text-center">
                                        <span style={{ width: 100, height: 100 }} >
                                            <i className={'fa ' + this.state.dcFileicon} aria-hidden="true" style={{ fontSize: 60 }}></i>
                                        </span>
                                    </div>
                                    <div className="form-group col-md-12" style={{ display: 'none' }}>
                                        <label htmlFor="dcFilename">FileName</label>
                                        <input type="text" className="form-control" id="dcFilename" name="dcFilename" value={this.state.dcFilename} onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="dcDescription">Document Name</label>
                                        <input type="text" className="form-control" id="dcDescription" name="dcDescription" value={this.state.dcDescription} onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <div className="checkbox">
                                            <label style={{ fontWeight: "bold" }}><input id="dcIsRestrictAccess" name="dcIsRestrictAccess" type="checkbox" value="" defaultChecked={this.state.dcIsRestrictAccess} onChange={this.handleRestrictAccessChange} />Only accessibly to listing agents on this mandate and administrators</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {this.state.dcFileID != "" && this.props.save_access ?
                                    <div className="pull-left">
                                        <a style={{ cursor: 'pointer', display: 'inline-block', paddingTop: 5 }} onClick={this.onDeleteDocumentClick}>Delete</a>
                                    </div> : null}
                                <a data-dismiss="modal" style={{ cursor: 'pointer' }}>Cancel</a>
                                {this.props.save_access ? <button type="button" style={{ marginLeft: 10 }} className="btn btn-success-oms" onClick={this.onSaveDocumentClick}>Save</button> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}